import { Record } from "immutable";
import { AnyAction } from "redux";

import {
  AVAILABLE_COUNTRIES,
  AVAILABLE_CURRENCIES,
  AVAILABLE_LANGS,
} from "@typings/globals";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import { SET_COUNTRY, SET_LANGUAGE } from "./localisation.actions";
import { LocalisationReducer, LocalisationState } from "./localisation.typings";

const initialState = Record<LocalisationState>({
  language: AVAILABLE_LANGS.EN,
  country: AVAILABLE_COUNTRIES.CH,
});

const localisationReducer = (
  store: LocalisationReducer = initialState(),
  action: AnyAction,
): LocalisationReducer => {
  const { payload, type } = action;

  switch (type) {
    case `${SET_LANGUAGE}${ACTION_SUCCESS}`: {
      return store.set("language", payload);
    }

    case `${SET_COUNTRY}${ACTION_SUCCESS}`: {
      return store.set("country", payload);
    }

    default: {
      return store;
    }
  }
};

export { localisationReducer };
