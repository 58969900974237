/**
 * @author tomekbuszewski
 * @since 2019-3-6
 */

import styled, { css } from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";

/**
 * Container
 */
type widthVariant = "small" | "full" | "fullMobile" | "default";

interface ContainerProps {
  theme: ITheme;
  children?: any;
  widthVariant?: widthVariant;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  margin: auto;
  padding-left: ${props => props.theme.margins.base_x2};
  padding-right: ${props => props.theme.margins.base_x2};

  ${props => props.theme.breakpoints.tablet} {
    max-width: ${props => props.theme.container.tablet};
  }

  ${props => props.theme.breakpoints.desktop} {
    max-width: ${props => props.theme.container.desktop};
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    max-width: ${props => props.theme.container.desktopLarge};
  }

  ${props =>
    props.widthVariant === "full" &&
    css`
      padding-left: 0;
      padding-right: 0;

      ${props => props.theme.breakpoints.tablet} {
        max-width: 100%;
      }

      ${props => props.theme.breakpoints.desktop} {
        max-width: 100%;
      }

      ${props => props.theme.breakpoints.desktopLarge} {
        max-width: 100%;
      }
    `}

  ${props =>
    props.widthVariant === "fullMobile" &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${props =>
    props.widthVariant === "small" &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        max-width: ${props => props.theme.container.desktopSmall};
      }

      ${props => props.theme.breakpoints.desktopLarge} {
        max-width: ${props => props.theme.container.desktopSmall};
      }
    `}
`;

/**
 * Row
 */
interface RowProps {
  children?: any;
  theme: ITheme;
  mb?: "base_x2" | "base_x4";
}

const Row = styled.div<RowProps>`
  display: flex;
  flex-flow: row wrap;
  margin-left: -${props => props.theme.margins.base_x2};
  margin-right: -${props => props.theme.margins.base_x2};

  ${props =>
    props.mb &&
    `
    margin-bottom: ${props.theme.margins[props.mb]};
  `}
`;

/**
 * Column
 */
interface ColumnProps {
  children?: any;
  theme: ITheme;
  xs?: number;
  pushXs?: number;
  orderXs?: number;
  sm?: number;
  pushSm?: number;
  orderSm?: number;
  md?: number;
  pushMd?: number;
  orderMd?: number;
  lg?: number;
  pushLg?: number;
  orderLg?: number;
  xl?: number;
  pushXl?: number;
  orderXl?: number;
  centered?: boolean;
}

function getPercent(size: number, columns: number): string {
  const result: number = (size / columns) * 100;
  return `${result}%`;
}

const Column = styled.div<ColumnProps>`
  padding-left: ${props => props.theme.margins.base_x2};
  padding-right: ${props => props.theme.margins.base_x2};
  width: ${props => getPercent(props.xs || 12, props.theme.grid.columns)};
  margin-left: ${props =>
    props.pushXs && getPercent(props.pushXs, props.theme.grid.columns)};
  order: ${props => props.orderXs};

  ${props =>
    props.centered &&
    css`
      margin: 0 auto;
    `};

  img {
    max-width: 100%;
  }

  ${props => props.theme.breakpoints.mobile} {
    width: ${props =>
      props.sm && getPercent(props.sm, props.theme.grid.columns)};
    margin-left: ${props =>
      props.pushSm && getPercent(props.pushSm, props.theme.grid.columns)};
    order: ${props => props.orderSm};

    ${props =>
      props.centered &&
      css`
        margin: 0 auto;
      `};
  }

  ${props => props.theme.breakpoints.tablet} {
    width: ${props =>
      props.md && getPercent(props.md, props.theme.grid.columns)};
    margin-left: ${props =>
      props.pushMd && getPercent(props.pushMd, props.theme.grid.columns)};
    order: ${props => props.orderMd};

    ${props =>
      props.centered &&
      css`
        margin: 0 auto;
      `};
  }

  ${props => props.theme.breakpoints.desktop} {
    width: ${props =>
      props.lg && getPercent(props.lg, props.theme.grid.columns)};
    margin-left: ${props =>
      props.pushLg && getPercent(props.pushLg, props.theme.grid.columns)};
    order: ${props => props.orderLg};

    ${props =>
      props.centered &&
      css`
        margin: 0 auto;
      `};
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    width: ${props =>
      props.xl && getPercent(props.xl, props.theme.grid.columns)};
    margin-left: ${props =>
      props.pushXl && getPercent(props.pushXl, props.theme.grid.columns)};
    order: ${props => props.orderXl};

    ${props =>
      props.centered &&
      css`
        margin: 0 auto;
      `};
  }
`;

export { Container, Column, Row };
