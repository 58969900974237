import React from "react";
import { Set, Map } from "immutable";

import {
  UseOverlayManagerReturn,
  IsOverlayActive,
  ShowOverlay,
  HideOverlay,
  GetOverlayOption,
  GetActiveOverlays,
} from "./useOverlayManager.types";

/**
 * Hook to manage basic overlay functionality
 * @returns `[isOverlayActive, showOverlay, hideOverlay, getOverlayOption, getActiveOverlays]`
 */
export const useOverlayManager = (): UseOverlayManagerReturn => {
  const [active, setActive] = React.useState(Set<string>());
  const [options, setOptions] = React.useState(Map<string, any>());

  const isOverlayActive: IsOverlayActive = id => active.has(id);

  const showOverlay: ShowOverlay = (id, data) => {
    if (data && !isOverlayActive(id)) {
      setOptions(prev => prev.set(id, data));
    }

    setActive(prev => prev.add(id));
  };

  const hideOverlay: HideOverlay = id => {
    if (isOverlayActive(id)) {
      setOptions(prev => prev.delete(id));
    }

    setActive(prev => prev.delete(id));
  };

  const getOverlayOption: GetOverlayOption = id => options.get(id);

  const getActiveOverlays: GetActiveOverlays = () => active.toArray();

  return [
    isOverlayActive,
    showOverlay,
    hideOverlay,
    getOverlayOption,
    getActiveOverlays,
  ];
};
