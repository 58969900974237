import React from "react";

import { IS_MODAL_OPEN_CLASS_NAME } from "@config/consts";

export const useBackdropLockScroll = (lock?: boolean) => {
  React.useEffect(() => {
    // If someone in the future will change something here, please remember about iOS bug:
    // https://bugs.webkit.org/show_bug.cgi?id=220908

    const $body = document.body;

    const offsetY = window.scrollY;
    if (lock && !$body.classList.contains(IS_MODAL_OPEN_CLASS_NAME)) {
      $body.classList.add(IS_MODAL_OPEN_CLASS_NAME);
      $body.style.top = `-${offsetY}px`;
      $body.scrollTo(0, offsetY);
    }

    return () => {
      if ($body.classList.contains(IS_MODAL_OPEN_CLASS_NAME)) {
        const offsetY = Math.abs(parseInt($body.style.top || "0", 10)) || 0;

        $body.style.top = "";
        $body.classList.remove(IS_MODAL_OPEN_CLASS_NAME);
        window.scrollTo(0, offsetY);
      }
    };
  }, [lock]);
};
