import { AVAILABLE_LANGS, PRISMIC_AVAILABLE_LANGS } from "@typings/globals";

export const langInPrismicFormat = (
  lang: AVAILABLE_LANGS,
): PRISMIC_AVAILABLE_LANGS => {
  switch (lang) {
    case AVAILABLE_LANGS.FR:
      return PRISMIC_AVAILABLE_LANGS.FR;
    case AVAILABLE_LANGS.IT:
      return PRISMIC_AVAILABLE_LANGS.IT;
    case AVAILABLE_LANGS.DE:
      return PRISMIC_AVAILABLE_LANGS.DE;
    case AVAILABLE_LANGS.PL:
      return PRISMIC_AVAILABLE_LANGS.PL;
    case AVAILABLE_LANGS.NL:
      return PRISMIC_AVAILABLE_LANGS.NL;
    default:
      return PRISMIC_AVAILABLE_LANGS.EN;
  }
};
