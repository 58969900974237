import { TextStyles } from "@batmaid/design-tokens";
import { TextStyleObject } from "@batmaid/design-tokens/lib/typings";
import {
  css,
  DefaultTheme,
  ThemedStyledProps,
  CSSObject,
} from "styled-components";

type TextStylesKey = keyof TextStyles;

/**
 * Helper function to apply text styles
 * @param style selected text style from theme
 * @param lineHeight override line height from different text style or delete it with "none"
 * @returns `CSSObject`
 */
const text =
  (style: TextStylesKey, lineHeight?: TextStylesKey | "none") =>
  <P>(args: ThemedStyledProps<P, DefaultTheme>) => {
    const cssObject = { ...args.theme.texts[style] } as TextStyleObject;

    if (lineHeight && lineHeight === "none") {
      const { lineHeight: _, ...rest } = cssObject as CSSObject;

      return css(rest);
    }

    if (lineHeight) {
      cssObject.lineHeight = args.theme.texts[lineHeight].lineHeight;
    }

    return css(cssObject);
  };

export default text;
