import * as React from "react";
import styled, { css } from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";

const resolveName = (input: string, theme: ITheme): string =>
  input === "auto" || input === "0" ? input : theme.margins[input];

const extractMargins = (input: string | string[], theme: ITheme) => {
  if (typeof input === "string") {
    return css`
      margin-top: ${resolveName(input, theme)};
      margin-bottom: ${resolveName(input, theme)};
      margin-left: ${resolveName(input, theme)};
      margin-right: ${resolveName(input, theme)};
    `;
  }

  return css`
    margin-top: ${input[0] && resolveName(input[0], theme)};
    margin-bottom: ${input[1] && resolveName(input[1], theme)};
    margin-left: ${input[2] && resolveName(input[2], theme)};
    margin-right: ${input[3] && resolveName(input[3], theme)};
  `;
};

interface MarginsBreakpoints {
  xs?: string | (string | null)[];
  sm?: string | (string | null)[];
  md?: string | (string | null)[];
  lg?: string | (string | null)[];
  xl?: string | (string | null)[];
}
interface Props extends MarginsBreakpoints {
  children: React.ReactElement;
}
const Margins = styled(({ children, className }) =>
  React.cloneElement(React.Children.only(children), {
    className: `${
      children.props.className ? children.props.className : ""
    } ${className}`.trim(),
  }),
)<Props>`
  ${props => props.xs && extractMargins(props.xs, props.theme)};

  ${props => props.sm && props.theme.breakpoints.mobile} {
    ${props => props.sm && extractMargins(props.sm, props.theme)};
  }

  ${props => props.md && props.theme.breakpoints.tablet} {
    ${props => props.md && extractMargins(props.md, props.theme)};
  }

  ${props => props.lg && props.theme.breakpoints.desktop} {
    ${props => props.lg && extractMargins(props.lg, props.theme)};
  }

  ${props => props.xl && props.theme.breakpoints.desktopLarge} {
    ${props => props.xl && extractMargins(props.xl, props.theme)};
  }
`;

export { MarginsBreakpoints, Margins };
