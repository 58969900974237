import { createSelector } from "reselect";

import { AuthState } from "@hooks/useAuth/redux";

const getOauthProviderFunction = (store: AuthState) =>
  store.oauth.get("provider");

export const getOauthProvider = createSelector(
  getOauthProviderFunction,
  f => f,
);

const getOauthUserFunction = (store: AuthState) => {
  return {
    firstName: store.oauth.get("firstName"),
    lastName: store.oauth.get("lastName"),
    userType: store.oauth.get("userType"),
    accessToken: store.oauth.get("accessToken"),
    email: store.oauth.get("email"),
    provider: store.oauth.get("provider"),
  };
};

export const getOauthUser = createSelector(getOauthUserFunction, f => f);
