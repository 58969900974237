export enum ORIGIN {
  BATMAID = "batmaid",
  BATWORK = "batwork",
  BATSOFT = "batsoft",
}

export enum TITLE_OPTS {
  MR = "MR",
  MS = "MS",
}

export enum GENDER_OPTS {
  MALE = "male",
  FEMALE = "female",
  UNKNOWN = "unknown",
}

// *************
// ** Payment **
// *************
export enum CHECKOUT_RESULT_NEXT_STEP {
  PAYMENT = "PAYMENT",
  DONE = "DONE",
  DEFAULT = "",
}

export enum CHECKOUT_RESULT_STATUS {
  SHOW_LIGHTBOX = "SHOW_LIGHTBOX",
  REDIRECT = "REDIRECT",
  DEFAULT = "",
}

export interface DataTransPayload {
  [k: string]: any;
}

export enum SERVICE_TYPE {
  HOME_CLEANING = "home-cleaning",
  END_OF_TENANCY = "end-of-tenancy",
  B2B = "b2b-cleaning",
}

// **************************
// ** Internationalization **
// **************************
export enum AVAILABLE_COUNTRIES {
  LU = "lu",
  CH = "ch",
  FR = "fr",
  PL = "pl",
  IT = "it",
  DE = "de",
  AT = "at",
  BE = "be",
  NL = "nl",
  UK = "uk",
  US = "us",
}

export enum AVAILABLE_CURRENCIES {
  CHF = "CHF",
  EUR = "EUR",
  PLN = "PLN",
  GBP = "GBP",
  USD = "USD",
}

export enum AVAILABLE_LANGS {
  EN = "en",
  FR = "fr",
  DE = "de",
  IT = "it",
  PL = "pl",
  NL = "nl",
}

export enum AVAILABLE_DIALING_CODES {
  CH = "+41",
  LU = "+352",
  FR = "+33",
  PL = "+48",
  DE = "+49",
  IT = "+39",
  AT = "+43",
  BE = "+32",
  NL = "+31",
  UK = "+44",
  US = "+1",
}

export enum TRUSTPILOT_AVAILABLE_LANGS {
  EN = "en-US",
  FR = "fr-FR",
  DE = "de-DE",
  IT = "it-IT",
  PL = "pl-PL",
  NL = "nl-NL",
}

export enum PRISMIC_AVAILABLE_LANGS {
  EN = "en-gb",
  FR = "fr-fr",
  DE = "de-de",
  IT = "it-it",
  PL = "pl",
  NL = "nl-nl",
}

export enum WEEKDAYS {
  MON = "MONDAY",
  TUE = "TUESDAY",
  WED = "WEDNESDAY",
  THU = "THURSDAY",
  FRI = "FRIDAY",
  SAT = "SATURDAY",
  SUN = "SUNDAY",
}

// provided in npm scripts via webpack Define plugin
export enum APP_NAMES {
  BATMAID = "app",
  BATWORK = "work",
  BATSOFT = "soft",
}

// *******************
// ** Notifications **
// *******************
export enum NOTIFICATION_TYPES {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
}

export type SimpleObject = { [k: string]: any };

export enum PROMO_CODE_NOTIFICATION {
  NONE,
  SUCCESS,
  FAILURE,
}

export enum BOOKING_TYPES {
  BOOKING = "booking",
  EOT = "eot",
  B2B = "b2b",
}

export enum TOP_QUESTIONS_TYPES {
  BOOKING = "booking",
  BOOKING_STEP_2 = "booking_step_2",
  EOT = "eot",
  B2B = "b2b",
}

export enum HERO_TYPES {
  HOME_CLEANING = "HOME_CLEANING",
  END_OF_TENANCY = "END_OF_TENANCY",
  OFFICE_CLEANING = "OFFICE_CLEANING",
  CLEANING_PRODUCTS = "CLEANING_PRODUCTS",
}

export enum WHATS_INCLUDED_TYPE {
  BOOKING = "body",
  EOT_WARRANTY = "body1",
  B2B = "body2",
  EOT_CLEANING = "body3",
  EMPLOYER_DUTIES = "body4",
}

export enum AUTH_REDIRECTS {
  UNAUTHENTICATED,
}

export enum PETS {
  DOG = "DOG",
  CAT = "CAT",
  OTHER = "OTHER",
}

export type State = {
  id: number;
  name: string;
  shortName: string;
};

export interface Territory {
  inseeCode: string;
  name: string;
}

export interface FrenchCommune {
  communeName: string;
  communeCode: string;
  departmentName: string;
  departmentCode: string;
  inseeCode: string;
}

export interface FrenchPostalCode {
  postalCode: string;
  communeName: string;
  communeCode: string;
  departmentCode: string;
  inseeCode: string;
}

export enum PAYMENT_METHODS {
  CLIENT_PAY_FIRST = "CLIENT_PAY_FIRST",
  CLIENT_PAYS_LATER = "CLIENT_PAYS_LATER",
  NEW_CREDIT_CARD = "NEW_CREDIT_CARD",
  EXISTING_CREDIT_CARD = "EXISTING_CREDIT_CARD",
  PAYPAL = "PAYPAL",
  POST_FINANCE = "POST_FINANCE",
  SOFORT = "SOFORT",
  APPLE_PAY = "APPLE_PAY",
  TWINT = "TWINT",
  GOOGLE_PAY = "GOOGLE_PAY",
  LYDIA = "LYDIA",
  URSSAF = "URSSAF",
  MONTHLY_INVOICE = "MONTHLY_INVOICE",
  PAYMENT_TYPE_SEPARATOR = "PAYMENT_TYPE_SEPARATOR", // legacy: can be removed when Batmaid & Batsoft are fully migrated to new cancellation flow
  DEFAULT = "",
}

export enum VAT_INFO {
  INCLUDED = "INCLUDED",
  EXCLUDED = "EXCLUDED",
  NO_INFO = "NO_INFO",
}

export enum PAYMENT_METHOD_STATUS {
  OK = "OK",
  SOON_TO_EXPIRE = "SOON_TO_EXPIRE",
  EXPIRED = "EXPIRED",
}
