import beFeatureToggles from "@assets/feature-toggles/feature-toggles.be.json";
import chFeatureToggles from "@assets/feature-toggles/feature-toggles.ch.json";
import deFeatureToggles from "@assets/feature-toggles/feature-toggles.de.json";
import frFeatureToggles from "@assets/feature-toggles/feature-toggles.fr.json";
import itFeatureToggles from "@assets/feature-toggles/feature-toggles.it.json";
import luFeatureToggles from "@assets/feature-toggles/feature-toggles.lu.json";
import defaultFeatureToggles from "@assets/feature-toggles/feature-toggles.app.json";

import { FeatureTogglesSaved } from "@config/featureToggles";

export const getFeatureToggles = (): FeatureTogglesSaved | undefined => {
  if (process.env.ORIGIN !== "batmaid") return;

  const country = process.env.COUNTRY;

  if (!country) return defaultFeatureToggles;

  const featureToggles =
    {
      ch: chFeatureToggles,
      lu: luFeatureToggles,
      fr: frFeatureToggles,
      de: deFeatureToggles,
      it: itFeatureToggles,
      be: beFeatureToggles,
    }[country] || defaultFeatureToggles;

  return featureToggles;
};
