/**
 * @author tomekbuszewski
 * @since 2019-3-12
 */

import * as React from "react";
import styled from "styled-components";
import { Element as ScrollElement, scroller } from "react-scroll";

import { NOTIFICATION_TYPES } from "@typings/globals";

import { fadeIn, fadeOut } from "@ui/animations";
import { Container, TestWrapper } from "@ui/Atoms";
import { transition, rem } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";
import { Close } from "@ui/Assets/Symbolicons/Close";

import { NOTIFICATION } from "@config/testIds/notification";
import {
  DESTROY_NOTIFICATIONS_AFTER_LONG,
  IS_BROWSER,
  SCROLL_ELEMENT_OFFSET,
} from "@config/consts";

interface Props {
  children?: any;
  className?: string;
  onClose: (e?: React.SyntheticEvent) => void;
  hidden?: boolean;
  id?: string;
  theme: ITheme;
  variant: NOTIFICATION_TYPES;
  wide?: boolean;
  testId?: string;
  autoHide?: boolean;
  isWithoutCloseBtn?: boolean;
  withAutoScroll?: boolean;
}

const getColor = (color: string, theme: ITheme): string => `
  background: ${theme.notifications[`${color}Background`]};
  color: ${theme.notifications[`${color}Text`]};
  border: ${rem(1)} solid ${theme.notifications[`${color}Border`]};
  
  & > p { color: ${theme.notifications[`${color}Text`]}; }
`;

const CloseButton = styled.button`
  background: none;
  border: 0;
  opacity: 0.5;

  position: relative;
  top: 0;
  margin-left: auto;
  margin-right: -${rem(12)};

  display: flex;
  align-items: center;
  align-self: baseline;

  font-size: ${rem(20)};
`;

const StyledContainer = styled(Container)`
  position: relative;
  text-align: center;
  display: flex;
`;

const NotificationBody = (props: {
  children: string;
  onClose: (e: React.MouseEvent) => void;
  isWithoutCloseBtn?: boolean | undefined;
}) => (
  <React.Fragment>
    {props.children}
    {!props.isWithoutCloseBtn && (
      <TestWrapper testId={NOTIFICATION.CLOSE_BTN}>
        <CloseButton onClick={props.onClose}>
          <Close variant="solid" size={24} color="black" />
        </CloseButton>
      </TestWrapper>
    )}
  </React.Fragment>
);

const NotificationWrapper = styled.div<{ isHidden: boolean | undefined }>`
  transition: ${transition("max-height")};
  max-height: ${({ isHidden }) => (!isHidden ? "500px" : 0)};
  overflow: hidden;

  & + & {
    margin-bottom: ${props => props.theme.margins.base_x4};
  }
`;

const NotificationComponent = (props: Props) => {
  React.useEffect(() => {
    if (IS_BROWSER && props.autoHide) {
      setTimeout(() => {
        props.onClose();
      }, DESTROY_NOTIFICATIONS_AFTER_LONG);
    }
  });

  React.useEffect(() => {
    if (props.withAutoScroll) {
      scroller.scrollTo(props.id ? props.id : "notificationScrollElement", {
        smooth: true,
        offset: SCROLL_ELEMENT_OFFSET,
      });
    }
  }, []);

  if (props.wide) {
    return (
      <ScrollElement name={props.id ? props.id : "notificationScrollElement"}>
        <TestWrapper testId={props.testId}>
          <div className={props.className}>
            <StyledContainer>
              <NotificationBody
                onClose={props.onClose}
                isWithoutCloseBtn={props.isWithoutCloseBtn}
              >
                {props.children}
              </NotificationBody>
            </StyledContainer>
          </div>
        </TestWrapper>
      </ScrollElement>
    );
  }

  return (
    <ScrollElement name={props.id ? props.id : "notificationScrollElement"}>
      <TestWrapper testId={props.testId}>
        <NotificationWrapper isHidden={props.hidden}>
          <div className={props.className}>
            <NotificationBody
              onClose={props.onClose}
              isWithoutCloseBtn={props.isWithoutCloseBtn}
            >
              {props.children}
            </NotificationBody>
          </div>
        </NotificationWrapper>
      </TestWrapper>
    </ScrollElement>
  );
};

const Notification = styled(NotificationComponent)<Props>`
  animation: ${props => (props.hidden ? fadeOut : fadeIn)}
    ${props => props.theme.animations.durationLong}
    ${props => props.theme.animations.easing} forwards;

  position: ${props => (props.wide ? "sticky" : "relative")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  padding: ${props => props.theme.margins.base_x2};
  display: flex;
  height: ${props => props.hidden && 0};
  overflow: hidden;

  font-size: ${props => props.theme.fonts.sizes.body2};

  line-height: ${props => props.theme.fonts.lineHeights.normal};

  border-radius: ${props => props.theme.buttons.borderRadius};

  ${props =>
    props.variant === NOTIFICATION_TYPES.INFO && getColor("blue", props.theme)};
  ${props =>
    props.variant === NOTIFICATION_TYPES.SUCCESS &&
    getColor("green", props.theme)};
  ${props =>
    props.variant === NOTIFICATION_TYPES.ERROR && getColor("red", props.theme)};
  ${props =>
    props.variant === NOTIFICATION_TYPES.WARNING &&
    getColor("yellow", props.theme)};
`;

export { Notification };

Notification.defaultProps = {
  autoHide: true,
};
