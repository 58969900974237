import React from "react";

import { IS_DEV } from "@config/consts";
import { FeatureToggleState } from "@config/featureToggles";

import { BatmaidTelemetry } from "@services/Telemetry";
import { generatePageId } from "@services/GeneratePageId";

import {
  ElementClick,
  PageView,
  SendEvent,
  Context,
  SuspenseEvent,
  Props,
} from "./useTelemetry.typings";

const TelemetryContext = React.createContext<Context>({
  isEnabled: false,
  setIsEnabled: () => undefined,
  setSuspensedEvents: () => undefined,
});

const TelemetryProvider = (props: Props): React.ReactElement => {
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [suspensedEvents, setSuspensedEvents] = React.useState<SuspenseEvent[]>(
    [],
  );

  React.useEffect(() => {
    if (!isEnabled) return;
    if (suspensedEvents.length === 0) return;

    if (IS_DEV) {
      console.info("Call suspensed events", {
        events: suspensedEvents,
      });
    }

    suspensedEvents.forEach(event => {
      switch (event.type) {
        case "elementClick": {
          BatmaidTelemetry.elementClick(...event.args);
          break;
        }
        case "pageView": {
          BatmaidTelemetry.pageView(...event.args);
          break;
        }
        case "sendEvent": {
          BatmaidTelemetry.sendEvent(...event.args);
          break;
        }
        default:
          break;
      }
    });
    setSuspensedEvents([]);
  }, [isEnabled, suspensedEvents.length]);

  return (
    <TelemetryContext.Provider
      value={{ isEnabled, setIsEnabled, setSuspensedEvents }}
    >
      {props.children}
    </TelemetryContext.Provider>
  );
};

const useTelemetry = () => {
  const context = React.useContext(TelemetryContext);
  const isTelemetryEnabled = context.isEnabled;

  const enable = () => {
    context.setIsEnabled(true);
  };

  const setFeatureFlags = (featureFlags: FeatureToggleState) => {
    BatmaidTelemetry.setFeatureFlags(featureFlags);
  };

  const elementClick: ElementClick = (buttonLabel, props = {}) => {
    const args: Parameters<ElementClick> = [
      buttonLabel,
      {
        ...props,
        ...(!props.withAutoPageId ? { pageId: generatePageId() } : {}),
      },
    ];

    if (isTelemetryEnabled) {
      return BatmaidTelemetry.elementClick(...args);
    }

    context.setSuspensedEvents(current => [
      ...current,
      { type: "elementClick", args },
    ]);
    return;
  };

  const pageView: PageView = props => {
    if (isTelemetryEnabled) {
      return BatmaidTelemetry.pageView(props);
    }

    context.setSuspensedEvents(current => [
      ...current,
      { type: "pageView", args: [props] },
    ]);
    return;
  };

  const sendEvent: SendEvent = (eventName, props) => {
    if (isTelemetryEnabled) {
      return BatmaidTelemetry.sendEvent(eventName, props);
    }

    context.setSuspensedEvents(current => [
      ...current,
      { type: "sendEvent", args: [eventName, props] },
    ]);
    return;
  };

  return React.useMemo(() => {
    return {
      setFeatureFlags,
      elementClick,
      pageView,
      sendEvent,
      enable,
      isEnabled: isTelemetryEnabled,
    };
  }, [isTelemetryEnabled]);
};

export { useTelemetry, TelemetryProvider };
