import * as React from "react";

export interface ExtendedInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  errors?: string | React.ReactNode;
  label?: string | React.ReactElement;
  optionalLabel?: string | React.ReactElement;
  autoFocus?: boolean;
  textCenter?: boolean;
  refElement?: React.RefObject<any>;
  isHidden?: boolean;
  icon?: React.ReactElement;
  isLabelFloating?: boolean;
  hotjarAllow?: boolean;
  hasSmallFloatLabel?: boolean;
  inputWrapperStyles?: React.CSSProperties;
}

export const INPUT_ERROR_CLASS = "input-with-error";

export type FormTypes =
  | "text"
  | "password"
  | "number"
  | "select"
  | "textarea"
  | "radio"
  | "checkbox";

export type InputEvent =
  | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  | React.ChangeEvent<HTMLInputElement>;

export interface RadioEvent {
  name: string;
  value: string;

  [k: string]: any;
}

export interface IFormElement {
  additionalInfo?: string;
  allowNegative?: boolean;
  defaultValue?: string | boolean;
  disabled?: boolean;
  form: string;
  id?: string;
  items?: any;
  label?: string;
  labelInline?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (e: InputEvent | RadioEvent) => void;
  onFocus?: () => void;
  placeholder?: string;
  type?: FormTypes;
  validators: string[];
  value?: string | boolean;
}
