/**
 * @author jakubbujakowski
 * @since 2021-2-25
 */

import React from "react";
import styled from "styled-components";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";

import { LOGIN } from "@config/testIds/login";

import theme from "@ui/themes/default";
import { rem } from "@ui/helpers";
import { Google as GoogleLogo, GoogleIcon } from "@ui/Assets/SocialIcons";
import { Button, TestWrapper } from "@ui/Atoms";

const googleColor = theme.socialPalette.google;

/**
 * When using connect-account variant, please remember to pass connectAccountText translation
 */
type Variant = "login" | "connect-account" | "button";

interface Props {
  onSuccess: (
    resp: Omit<TokenResponse, "error" | "error_description" | "error_uri">,
  ) => void;
  onError: () => void;
  render?: () => JSX.Element;
  variant?: Variant;
  translations?: {
    connectAccountText?: string;
    buttonText?: string;
  };
}

const GoogleLogin = (props: Props): React.ReactElement => {
  const login = useGoogleLogin({
    onSuccess: props.onSuccess,
    onError: error => {
      if (error.error === "invalid_request") {
        console.error(error);
        return;
      }
      props.onError();
    },
  });

  const render = (): React.ReactElement => {
    switch (props.variant) {
      case "login":
        return (
          <TestWrapper testId={LOGIN.GOOGLE_ICON}>
            <IconInCircle onClick={() => login()} color={googleColor}>
              <GoogleLogo color={googleColor} />
            </IconInCircle>
          </TestWrapper>
        );

      case "connect-account":
        return (
          <React.Fragment>
            <IconSection>
              <IconWrapper socialColor={theme.socialPalette.google}>
                <GoogleLogo color="white" size={18} />
              </IconWrapper>
              <span>Google</span>
            </IconSection>
            <a href="#" onClick={() => login()}>
              {props.translations?.connectAccountText}
            </a>
          </React.Fragment>
        );

      case "button":
        return (
          <React.Fragment>
            <StyledButton
              type={"button"}
              variant={"borderBlack"}
              size={"fill"}
              onClick={() => login()}
            >
              <ButtonContentWrapper>
                <GoogleIcon size={32} />
                {props.translations?.buttonText}
              </ButtonContentWrapper>
            </StyledButton>
          </React.Fragment>
        );

      default:
        return <React.Fragment />;
    }
  };

  return props.render?.() ?? render();
};

export { GoogleLogin };

const IconInCircle = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${props => props.theme.margins.base_x4};
  max-width: ${props => props.theme.margins.base_x4};
  min-height: ${props => props.theme.margins.base_x4};
  max-height: ${props => props.theme.margins.base_x4};
  border: 1px solid ${props => props.color};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.color};

    svg {
      path {
        ${props => `fill: ${props.theme.palette.white}`};
      }
    }
  }
`;

const IconSection = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span<{ socialColor: string }>`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.socialColor};
  padding: ${props => props.theme.margins.half};
  width: ${rem(30)};
  height: ${rem(30)};
  margin-right: ${props => props.theme.margins.base_x2};
`;

const StyledButton = styled(Button)`
  text-align: left;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(22)};

  svg {
    margin-right: ${props => props.theme.margins.base};
  }
`;
