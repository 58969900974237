import React from "react";

import {
  AGENT_CLEANINGS_PROPOSALS_COUNT_ENDPOINT,
  AGENT_CHANGE_REQUEST_COUNT_ENDPOINT,
} from "@config/endpoints";

import { CLEANINGS_TIME_PERIODS } from "@typings/agentJobs";
import {
  CountState,
  CountOfNotificationsState,
} from "@typings/agentCountOfNotifications";

import { getApiUrl } from "@services/ApiUrl/ApiUrl";
import { fetchFacade } from "@services/FetchFacade";

import { useAuth } from "@hooks/useAuth";

const defaultCountState: CountState = {
  totalCount: null,
  timePeriodCounts: {
    [CLEANINGS_TIME_PERIODS.THIS_WEEK]: null,
    [CLEANINGS_TIME_PERIODS.NEXT_WEEK]: null,
    [CLEANINGS_TIME_PERIODS.FUTURE]: null,
  },
};

const fetchMyCleaningsCount = (): Promise<CountState> => {
  return new Promise((resolve, reject) => {
    const url = `${getApiUrl()}${AGENT_CHANGE_REQUEST_COUNT_ENDPOINT}`;

    return fetchFacade<CountState>(url, {
      method: "GET",
    }).then(res => {
      if (res.ok) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

const fetchNewCleaningsCount = (): Promise<CountState> => {
  return new Promise((resolve, reject) => {
    const url = `${getApiUrl()}${AGENT_CLEANINGS_PROPOSALS_COUNT_ENDPOINT}`;

    return fetchFacade<CountState>(url, {
      method: "GET",
    }).then(res => {
      if (res.ok) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

const CountOfNotificationsContext =
  React.createContext<CountOfNotificationsState | null>(null);

interface CountOfNotificationsProviderProps {
  children: React.ReactNode;
}

const CountOfNotificationsProvider = (
  props: CountOfNotificationsProviderProps,
): React.ReactElement => {
  const { isUserAgent } = useAuth();
  const [newCleanings, setNewCleanings] =
    React.useState<CountState>(defaultCountState);
  const [myCleanings, setMyCleanings] =
    React.useState<CountState>(defaultCountState);

  const fetchCounters = (): void => {
    fetchMyCleaningsCount()
      .then(data => setMyCleanings(data))
      .catch(() => undefined); // silent error
    fetchNewCleaningsCount()
      .then(data => setNewCleanings(data))
      .catch(() => undefined); // silent error
  };

  React.useEffect(() => {
    if (isUserAgent) {
      fetchCounters();
    }
  }, [isUserAgent]);

  return (
    <CountOfNotificationsContext.Provider
      value={{
        myCleanings,
        newCleanings,
        fetchCounters,
      }}
    >
      {props.children}
    </CountOfNotificationsContext.Provider>
  );
};

const useCountOfNotifications = (): CountOfNotificationsState => {
  const context = React.useContext(CountOfNotificationsContext);

  if (context === null) {
    throw new Error(
      "useCountOfNotifications must be used within CountOfNotificationsProvider",
    );
  }

  return context;
};

export { CountOfNotificationsProvider, useCountOfNotifications };
