import * as Yup from "yup";

export enum FIELDS {
  USERNAME = "username",
  PASSWORD = "password",
  REMEMBER_ME = "rememberMe",
  EMAIL = "email",
}

export const getLoginSchema = (
  emailWrongFormatText: string,
): Yup.ObjectSchema =>
  Yup.object().shape({
    email: Yup.string().email(emailWrongFormatText),
  });

export const sharedInputProps = {
  autoComplete: "none",
  autoCapitalize: "none",
  autoCorrect: "off",
  spellCheck: false,
};
