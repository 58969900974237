/**
 * @author tomekbuszewski
 * @since 2019-3-25
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { rem, transition } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  as?: string;
  asCheck?: boolean;
  children?: React.ReactNode;
  for?: string;
  inline?: boolean;
  isValid?: boolean;
  theme: ITheme;
  withoutLabel?: boolean;
  isDisabled?: boolean;
  active?: boolean;
  isLabelFloating?: boolean;
  isLabelOnTop?: boolean;
  forTextArea?: boolean;
  hasSmallFloatLabel?: boolean;
  fitContent?: boolean;
}

export const labelStyles = css<Props>`
  padding-bottom: ${props => props.theme.margins.half};
  margin: 0;
  display: block;

  color: ${props => props.theme.palette.grey800};
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.normal};

  &[for] {
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  }

  ${props =>
    props.as === "legend" &&
    css`
      margin-bottom: ${props.theme.margins.base};
    `};

  ${props => props.theme.breakpoints.tablet} {
    padding-right: ${props =>
      props.inline && !props.asCheck && props.theme.margins.base};
    padding-top: ${props => props.inline && !props.asCheck && rem(11)};
    padding-bottom: ${props => props.inline && !props.asCheck && 0};

    width: ${props => props.inline && !props.asCheck && "25%"};
  }

  ${props =>
    props.isLabelFloating &&
    css`
      position: absolute;
      left: ${props.theme.margins.base};
      z-index: 1;
      pointer-events: none;
      transition: all 0.1s;
      color: ${props.theme.palette.grey600};
      padding-bottom: 0;
    `};

  ${props =>
    props.isLabelFloating &&
    props.isLabelOnTop &&
    css`
      top: ${rem(4)};
      transform: translateY(0);
      font-size: ${props.hasSmallFloatLabel
        ? props.theme.fonts.sizes.overline
        : props.theme.fonts.sizes.caption};
      line-height: ${props.theme.fonts.sizes.caption};
      padding-left: ${rem(1)};
    `};

  ${props =>
    props.isLabelFloating &&
    !props.isLabelOnTop &&
    css`
      top: 50%;
      transform: translateY(-50%);
    `};

  ${props =>
    props.isLabelFloating &&
    props.forTextArea &&
    !props.isLabelOnTop &&
    css`
      top: ${rem(12)};
      transform: none;
    `};
`;

const Label = styled.label<Props>`
  ${labelStyles}
`;

const LabelWrapper = styled.div<Props>`
  transition: ${transition("background")};
  position: relative;
  border-radius: ${props => props.theme.buttons.borderRadius};

  min-height: ${props => (props.asCheck ? 0 : props.theme.buttons.height)};
  min-width: ${rem(50)};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${props =>
    props.asCheck &&
    css`
      display: flex;
      flex-flow: row nowrap;

      > label {
        flex: 1;
        padding-top: ${props.theme.margins.base};
        padding-bottom: ${props.theme.margins.base};
        padding-left: ${props.theme.margins.base_x2};
      }

      > span,
      > input[type="radio"],
      > input[type="checkbox"] {
        margin-top: calc(${props.theme.margins.base_x2} - 5px);
      }
    `}

  ${props =>
    props.inline &&
    css`
      span,
      input[type="radio"],
      input[type="checkbox"] {
        margin-top: 0;
      }
    `}
  
  ${props =>
    props.withoutLabel &&
    css`
      justify-content: flex-end;

      > label {
        display: none;
      }
    `}

  ${props =>
    props.fitContent &&
    css`
      width: fit-content;
    `}

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: 0;
  }

  ${props =>
    props.isLabelFloating &&
    css`
      position: relative;
    `};
`;

export { LabelWrapper, Label };

Label.displayName = "Label";
LabelWrapper.displayName = "LabelWrapper";
