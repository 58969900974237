/**
 * @author michalstolarski
 * @since 2024-5-6
 */

import * as React from "react";
import { useTheme } from "styled-components";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { DialogProps } from "./Dialog.types";
import { InnerContext } from "./Dialog.context";
import * as Styled from "./Dialog.styled";

/**
 * This component **SHOULD BE** used only inside the `@features/DialogManager`
 *
 * On mobile, the dialog behaves as a vertical variant
 */
const Dialog: React.FC<DialogProps> = (props): React.ReactElement => {
  const { variant, ...asset } = props.data;
  const theme = useTheme();
  const ariaId = React.useId();
  const $ariaDOM = React.useRef<HTMLButtonElement | null>(null);

  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!$ariaDOM.current) return;
    const $activeElement = document.activeElement as HTMLElement | null;

    $ariaDOM.current.focus();

    return () => $activeElement?.focus();
  }, [$ariaDOM.current]);

  return (
    <InnerContext.Provider
      value={{
        variant,
        direction: props.direction,
        size: props.size,
        ...asset,
      }}
    >
      <TestWrapper testId={props.testIds?.root}>
        <Styled.Root
          variant={variant}
          direction={props.direction}
          size={props.size}
          aria-modal="true"
        >
          <TestWrapper testId={props.testIds?.backdrop}>
            <Styled.Backdrop onClick={props.onRequestClose} />
          </TestWrapper>

          <TestWrapper testId={props.testIds?.container}>
            <Styled.Container
              variant={variant}
              direction={props.direction}
              role="dialog"
              aria-labelledby={ariaId}
            >
              <TestWrapper testId={props.testIds?.closeButton}>
                <Styled.CloseButton
                  ref={$ariaDOM}
                  id={ariaId}
                  onClick={props.onRequestClose}
                >
                  <Styled.CloseIcon width={theme.icons.sizes.base_x3} />
                </Styled.CloseButton>
              </TestWrapper>

              {props.data.variant === "image" && (
                <TestWrapper testId={props.testIds?.image}>
                  <Styled.Image
                    src={props.data.src}
                    alt={props.data.alt}
                    direction={props.direction}
                    onLoad={setIsImageLoaded.bind(null, true)}
                    isLoaded={isImageLoaded}
                  />
                </TestWrapper>
              )}

              <TestWrapper testId={props.testIds?.body}>
                <Styled.Body variant={variant} direction={props.direction}>
                  {props.children}
                </Styled.Body>
              </TestWrapper>
            </Styled.Container>
          </TestWrapper>
        </Styled.Root>
      </TestWrapper>
    </InnerContext.Provider>
  );
};

export { Dialog };
