const POINT = 16;

export default function(input: string | number, base: number = POINT): string {
  const value: number = typeof input === "number" ? input : parseFloat(input);

  if (isNaN(value)) {
    throw new Error("Please provide correct value!");
  }

  return `${value / base}rem`;
}
