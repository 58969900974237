import * as React from "react";
import { createPortal } from "react-dom";
import { domAnimation, LazyMotion, AnimatePresence } from "framer-motion";

import { IS_BROWSER } from "@config/consts";

import { PortalProps } from "./GenericPortal.types";
import { usePortalContainer } from "./GenericPortal.hooks";

export const GenericPortal = (props: PortalProps): React.ReactPortal | null => {
  const { $container } = usePortalContainer(props.type);

  if (!IS_BROWSER || !$container.current) {
    return null;
  }

  return createPortal(
    <LazyMotion features={domAnimation}>
      <AnimatePresence>{props.children}</AnimatePresence>
    </LazyMotion>,
    $container.current,
  );
};
