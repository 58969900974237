import { Record } from "immutable";
import { createSelector } from "reselect";

import {
  DEFAULT_CLEANING_TYPE,
  IUser,
  USER_LOGIN_STATE,
  USER_ROLES,
  USER_SERVICE_TYPE,
} from "@typings/user";

import { AuthState } from "@hooks/useAuth/redux";

const getUserStateFunction = (store: AuthState): USER_LOGIN_STATE =>
  store.user.get("state");
const getUserState = createSelector(getUserStateFunction, f => f);

const isUserStateKnownFunction = (store: AuthState): boolean =>
  getUserState(store) !== USER_LOGIN_STATE.UNKNOWN;
export const getIsUserStateKnown = createSelector(
  isUserStateKnownFunction,
  f => f,
);

const isUserLoggedInFunction = (store: AuthState): boolean => {
  return getUserState(store) === USER_LOGIN_STATE.LOGGED_IN;
};
export const getIsUserLoggedIn = createSelector(isUserLoggedInFunction, f => f);

const isUserDataFetchedFunction = (store: AuthState): boolean => {
  return store.user.get("isFetched");
};
export const getIsUserDataFetched = createSelector(
  isUserDataFetchedFunction,
  f => f,
);

const getUserDataFunction = (store: AuthState): Record<IUser> | null => {
  if (getIsUserLoggedIn(store)) {
    return store.user.get("data");
  }

  return null;
};
export const getUserData = createSelector(getUserDataFunction, f => f);

const isUserAdminFunction = (store: AuthState): boolean => {
  if (getIsUserLoggedIn(store)) {
    const roles = getUserData(store)!.get("roles") as USER_ROLES[];

    return (
      roles.indexOf(USER_ROLES.ROLE_ADMIN) > -1 ||
      roles.indexOf(USER_ROLES.ROLE_SUPER_ADMIN) > -1
    );
  }

  return false;
};
export const getIsUserAdmin = createSelector(isUserAdminFunction, f => f);

const getUserZipCodeFunction = (store: AuthState): string => {
  return store.user.getIn(["data", "preferredZipCode"]);
};
export const getUserZipCode = createSelector(getUserZipCodeFunction, f => f);

const isUserClientFunction = (store: AuthState): boolean => {
  if (getIsUserLoggedIn(store)) {
    const roles = getUserData(store)!.get("roles") as USER_ROLES[];

    return roles.includes(USER_ROLES.ROLE_CLIENT);
  }

  return false;
};
export const getIsUserClient = createSelector(isUserClientFunction, f => f);

const isUserAgentFunction = (store: AuthState): boolean => {
  if (getIsUserLoggedIn(store)) {
    const roles = getUserData(store)!.get("roles") as USER_ROLES[];

    return roles.includes(USER_ROLES.ROLE_AGENT);
  }

  return false;
};

export const getIsUserAgent = createSelector(isUserAgentFunction, f => f);

const isAdminImpersonatorFunction = (store: AuthState) =>
  Boolean(store.user.get("data").get("impersonator"));

export const getIsAdminImpersonator = createSelector(
  isAdminImpersonatorFunction,
  f => f,
);

const isUserB2BFunction = (store: AuthState): boolean =>
  [USER_SERVICE_TYPE.B2B, USER_SERVICE_TYPE.AMBIGUOUS].includes(
    store.user.get("data").get("serviceType"),
  );

export const getIsUserB2B = createSelector(isUserB2BFunction, f => f);

const getUserDefaultCleaningTypeFunction = (
  store: AuthState,
): DEFAULT_CLEANING_TYPE | null =>
  store.user.get("data").get("defaultCleaningType");

export const getUserDefaultCleaningType = createSelector(
  getUserDefaultCleaningTypeFunction,
  f => f,
);

const getUserUrssafRegistrationDateFn = (store: AuthState) => {
  const urssafProfile = store.user.get("data").get("urssafProfile");

  if (!urssafProfile) {
    return null;
  }

  return urssafProfile.registeredAt;
};

export const getUserUrssafRegistrationDate = createSelector(
  getUserUrssafRegistrationDateFn,
  f => f,
);

const getUrssafRegistrationStatusFn = (store: AuthState) => {
  const urssafProfile = store.user.get("data").get("urssafProfile");

  if (!urssafProfile) {
    return null;
  }

  return urssafProfile.status;
};

export const getUrssafRegistrationStatus = createSelector(
  getUrssafRegistrationStatusFn,
  f => f,
);

const getUserPaymentMethodStatusFn = (store: AuthState) =>
  store.user.get("data").get("paymentMethodStatus");
export const getUserPaymentMethodStatus = createSelector(
  getUserPaymentMethodStatusFn,
  f => f,
);

const getUserServiceTypeFn = (store: AuthState) =>
  store.user.get("data").get("serviceType");

export const getUserServiceType = createSelector(getUserServiceTypeFn, f => f);
