/**
 * This is a minimal, lightweight Redux store,
 * created only to import global @hooks that rely on Redux to work.
 * Please don't create local reducers here.
 */

import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
} from "redux";
import thunk from "redux-thunk";

import { IS_BROWSER, REDUX_STATE_KEY, IS_DEV } from "@config/consts";

import { useLocalisationReducer } from "@hooks/useLocalisation/redux";
import { useAuthReducer } from "@hooks/useAuth";

const middleware: Middleware[] = [thunk];
const win = IS_BROWSER ? (window as any) : null;

const initialState = IS_BROWSER ? win[REDUX_STATE_KEY] || {} : {};
const composeEnhancers =
  (IS_DEV && win && win.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const makeStore = () => {
  const store = createStore(
    combineReducers({
      ...useAuthReducer,
      ...useLocalisationReducer,
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  return store;
};

export default makeStore();
