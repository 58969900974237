import { ActionCreator, Store } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { IStore } from "@app/redux/reducers";

export type ThunkActionType = ActionCreator<
  ThunkAction<void, IStore | Store, any, any>
>;
export type ThunkActionDispatch = ThunkDispatch<IStore | Store, any, any>;

export enum LOADING_STATE {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export type ThunkActionOptions = {
  abortEnabled?: boolean;
};
