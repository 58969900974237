/**
 * @deprecated Please use localStorageFacade instead.
 */
export const isLocalStorageAvailable = (): boolean => {
  try {
    window.localStorage;
  } catch {
    return false;
  }

  return true;
};

export const isLocalStorageKeyAvailable = (key: string): boolean => {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(key) !== null;
  }
  return false;
};
