import { CLEANING_FREQUENCY } from "@typings/booking";
import { AVAILABLE_CURRENCIES } from "@typings/globals";

enum CHECKIN_STATUS {
  CHECK_IN_AVAILABLE = "CHECK_IN_AVAILABLE",
  CHECKED_IN = "CHECKED_IN",
  CHECKED_OUT = "CHECKED_OUT",
}

enum CHECKIN_VERIFICATION_RESPONSE {
  TOO_EARLY = "TOO_EARLY",
  TOO_LATE = "TOO_LATE",
  ON_TIME = "ON_TIME",
}

enum DECLINE_REASONS {
  APPOINTMENT = "APPOINTMENT",
  I_AM_SICK = "I_AM_SICK",
  TOO_FAR = "TOO_FAR",
  PROBLEMATIC_CLIENT = "PROBLEMATIC_CLIENT",
  SICK_CHILD = "SICK_CHILD",
  ALONE_CHILD = "ALONE_CHILD",
  OTHER = "OTHER",

  // Below properties exist in the endpoint documentation
  // but application doesn't use them for now.
  // HOLIDAYS = "HOLIDAYS",
  // UNASSIGNED = "UNASSIGNED",
}

enum PERIODICITY {
  ONCE = "ONCE",
  WEEKLY = "WEEKLY",
  EVERY_2_WEEKS = "EVERY_2_WEEKS",
  EVERY_4_WEEKS = "EVERY_4_WEEKS",
}

enum CLEANINGS_TIME_PERIODS {
  PAST = "PAST",
  THIS_WEEK = "THIS_WEEK",
  NEXT_WEEK = "NEXT_WEEK",
  FUTURE = "FUTURE",
}

enum JOB_ACTIONS {
  VIEW_DETAILS = "VIEW_DETAILS",
  VIEW_SCHEDULE = "VIEW_SCHEDULE",
  VIEW_CONTRACT = "VIEW_CONTRACT",
}

enum KEY_PICK_ENUM {
  SOMEONE_AT_HOME = "SOMEONE_AT_HOME",
  CONCIERGE = "CONCIERGE",
  HIDE_KEY = "HIDE_KEY",
  SOMEONE_WILL_BE_THERE = "SOMEONE_WILL_BE_THERE",
  KEY_WILL_BE_PROVIDED = "KEY_WILL_BE_PROVIDED",
}

enum AUTOMATION_PROCESS_TYPE {
  AGENT_REPLACED = "AGENT_REPLACED",
  CLIENT_NOTIFIED = "CLIENT_NOTIFIED",
  CLIENT_NOT_NOTIFIED = "CLIENT_NOT_NOTIFIED",
  LEGACY_HOLIDAY_AUTOMATION = "LEGACY_HOLIDAY_AUTOMATION",
  NONE = "NONE",
  RESCHEDULED = "RESCHEDULED",
}

enum CONTRACT_TYPE {
  B2C = "B2C",
  B2B = "B2B",
  EOT = "EOT",
}

interface JobAction {
  href: string | null;
  key: JOB_ACTIONS;
  translationKey: string;
}
interface CleaningJobDTO {
  uuid: string;
  frequency: string;
  startDate: string;
  endDate: string;
  changeRequestUuid: string | null;
  clientName: string;
  cleaningAddress: string;
  netSalary: number;
  duration: number;
  currency: string;
  checkinStatus: string | null;
  actions: JobAction[] | null;
  isReplacementCleaning?: boolean | null;
  isCannotMakeContactAvailable?: boolean;
}

interface CleaningJob {
  uuid: string;
  frequency: CLEANING_FREQUENCY;
  startDate: Date;
  endDate: Date;
  changeRequestUuid: string | null;
  clientName: string;
  cleaningAddress: string;
  netSalary: number;
  duration: number;
  currency: AVAILABLE_CURRENCIES;
  checkinStatus: CHECKIN_STATUS | null;
  actions: JobAction[] | null;
  isReplacementCleaning: boolean;
  canDeclineJob: boolean;
}

interface JobAffectedByChangeDTO {
  uuid: string;
  startDate: string;
  endDate: string;
  clientName: string;
  isVip: boolean;
  numberOfJobs: number;
  clientUuid: string;
  automationProcessType: AUTOMATION_PROCESS_TYPE;
  clientCrmProfile: string;
  contractType: CONTRACT_TYPE;
  periodicity: PERIODICITY;
}

interface JobAffectedByChange {
  uuid: string;
  startDate: Date;
  endDate: Date;
  clientName: string;
  numberOfJobs: number;
  isVip: boolean;
  clientUuid: string;
  automationProcessType: AUTOMATION_PROCESS_TYPE;
  clientCrmProfile: string;
  contractType: CONTRACT_TYPE;
  periodicity: PERIODICITY;
}

export {
  DECLINE_REASONS,
  CLEANINGS_TIME_PERIODS,
  CHECKIN_STATUS,
  CHECKIN_VERIFICATION_RESPONSE,
  JOB_ACTIONS,
  KEY_PICK_ENUM,
  CleaningJob,
  CleaningJobDTO,
  JobAffectedByChangeDTO,
  JobAction,
  JobAffectedByChange,
  PERIODICITY,
  AUTOMATION_PROCESS_TYPE,
  CONTRACT_TYPE,
};
