import { Map, Record } from "immutable";

export enum MODAL_STATE {
  HIDDEN,
  VISIBLE,
}

export enum MODALS {
  // booking
  BOOKING_CLEANING_SUPPLIES = "BOOKING_CLEANING_SUPPLIES",
  BOOKING_CALCULATE_TIME = "BOOKING_CALCULATE_TIME",
  BOOKING_CALCULATE_TIME_2 = "BOOKING_CALCULATE_TIME_2",
  BOOKING_WHAT_IS_INCLUDED = "BOOKING_WHAT_IS_INCLUDED",
  BOOKING_IRONING_SERVICE = "BOOKING_IRONING_SERVICE",
  BOOKING_IRONING_SERVICE_V2 = "BOOKING_IRONING_SERVICE_V2",
  BOOKING_INTERIOR_WINDOWS_SERVICE = "BOOKING_INTERIOR_WINDOWS_SERVICE",
  BOOKING_INTERIOR_WINDOWS_SERVICE_V2 = "BOOKING_INTERIOR_WINDOWS_SERVICE_V2",
  BOOKING_REDIRECT_TO_BATMAID = "BOOKING_REDIRECT_TO_BATMAID",
  BOOKING_SHOW_EMPLOYER_DUTIES = "BOOKING_SHOW_EMPLOYER_DUTIES",
  BOOKING_CONTACT_US_FOR_BOOKING = "BOOKING_CONTACT_US_FOR_BOOKING",
  BOOKING_CONTACT_US_FOR_BOOKING_SUCCESS = "BOOKING_CONTACT_US_FOR_BOOKING_SUCCESS",
  BOOKING_BATMAID_CLUB = "BOOKING_BATMAID_CLUB",
  BOOKING_HELP_QUESTION = "BOOKING_HELP_QUESTION",
  BOOKING_PETS = "BOOKING_PETS",

  // b2b
  B2B_WHAT_IS_INCLUDED = "B2B_WHAT_IS_INCLUDED",
  B2B_CALCULATE_TIME = "B2B_CALCULATE_TIME",
  B2B_GLASS_WALLS = "B2B_GLASS_WALLS",
  B2B_CONTACT_US = "B2B_CONTACT_US",

  // eot
  EOT_FULLY_BOOKED = "EOT_FULLY_BOOKED",

  // navbar
  NAVBAR_LOGIN = "NAVBAR_LOGIN",
  GIFT_CARD = "GIFT_CARD",

  // my employees
  MY_EMPLOYEES_ADD_BONUS = "MY_EMPLOYEES_ADD_BONUS",

  // landing page
  UNCOVERED_CODE_SUBSCRIPTION = "UNCOVERED_CODE_SUBSCRIPTION",

  // profile
  PROFILE_CONTRACT_DETAILS = "PROFILE_CONTRACT_DETAILS",
  PROFILE_CONTRACT_COMMITMENT = "PROFILE_CONTRACT_COMMITMENT",
  PROFILE_MANAGE_PAYMENT_METHODS = "PROFILE_MANAGE_PAYMENT_METHODS",
  PROFILE_ADD_VOUCHER = "PROFILE_ADD_VOUCHER",
  CONFIRM_DELETE_ADDRESS_MODAL = "CONFIRM_DELETE_ADDRESS_MODAL",

  // options
  OPTIONS_CLEANING_SUMMARY_RANGE = "OPTIONS_CLEANING_SUMMARY_RANGE",
  OPTIONS_CLEANING_SUMMARY_SUCCESS = "OPTIONS_CLEANING_SUMMARY_SUCCESS",
  OPTIONS_CLEANING_SUMMARY_FAILED = "OPTIONS_CLEANING_SUMMARY_FAILED",

  // agent availabilities
  AGENT_DATE_ACTION = "AGENT_DATE_ACTION",
  AGENT_VACATIONS = "AGENT_VACATIONS",
  AGENT_ADD_VACATIONS = "AGENT_ADD_VACATIONS",
  AGENT_JOBS_AFFECTED = "AGENT_JOBS_AFFECTED",
  AGENT_DRAG_SLOT_CONFIRMATION = "AGENT_DRAG_SLOT_CONFIRMATION",

  // agent schedule
  AGENT_JOB_DETAILS = "AGENT_JOB_DETAILS",
  AGENT_JOB_DECLINE_REASON = "AGENT_JOB_DECLINE_REASON",
  AGENT_JOB_CHANGE_REQUEST = "AGENT_JOB_CHANGE_REQUEST",

  // login modals
  ONBOARDING_LOGIN = "ONBOARDING_LOGIN",

  // rating
  RATING_SHARE_REFLINK = "RATING_SHARE_REFLINK",

  // surveys
  SURVEY_BOOKING_EXPERIENCE = "SURVEY_BOOKING_EXPERIENCE",

  // checklist
  CHECKLIST_EXPLANATION = "CHECKLIST_EXPLANATION",
  CHECKLIST_EXIT_CONFIRMATION = "CHECKLIST_CONFIRMATION",
}

export type AgentScheduleModals =
  | MODALS.AGENT_JOB_DETAILS
  | MODALS.AGENT_JOB_DECLINE_REASON
  | MODALS.AGENT_JOB_CHANGE_REQUEST;

export type AgentMyCleaningsModals =
  | MODALS.AGENT_JOB_DETAILS
  | MODALS.AGENT_JOB_CHANGE_REQUEST
  | MODALS.AGENT_JOB_DECLINE_REASON;

export type AgentNewCleaningsModals =
  | MODALS.AGENT_JOB_DETAILS
  | MODALS.AGENT_JOB_DECLINE_REASON;

export interface ModalOptions {
  [key: string]: any;
  id?: string;
}

export interface ModalRecord {
  state: MODAL_STATE;
  options: ModalOptions;
  autohide: boolean;
}

export interface ModalCaller {
  id: string;
  autohide?: boolean;
}

export type ModalReducer = Map<string, Record<ModalRecord>>;
