import { AppConfig } from "@typings/appConfig";
import { CREDIT_CARDS_PROVIDERS } from "@typings/payments";

import {
  CURRENCY_PER_COUNTRY,
  DEFAULT_CLEANING_DURATION_MATRIX,
  DEFAULT_ROOMS_RECOMMENDATION_NUMBER_MATRIX,
} from "@config/consts";
import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";
import { CLEANING_FREQUENCY } from "@typings/booking";

export const defaultConfig: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.CH],
  availableLanguages: [AVAILABLE_LANGS.EN],
  booking: {
    recommendationMatrix: DEFAULT_ROOMS_RECOMMENDATION_NUMBER_MATRIX,
    durationMatrix: DEFAULT_CLEANING_DURATION_MATRIX,
    defaultDuration: 3, // hours
    defaultBedrooms: 2,
    defaultBathrooms: 1,
    minBookingTime: 2,
    minBookingTimePerFrequency: {
      [CLEANING_FREQUENCY.EVERY_2_WEEKS]: undefined,
      [CLEANING_FREQUENCY.EVERY_4_WEEKS]: undefined,
      [CLEANING_FREQUENCY.MORE_OFTEN]: undefined,
      [CLEANING_FREQUENCY.ONCE]: undefined,
      [CLEANING_FREQUENCY.WEEKLY]: undefined,
    },
    minBookingTimeAdmin: 0.5,
    maxBookingTime: 6,
    commitments: {
      enabled: false,
    },
    contactForBooking: {
      sundayEnabled: false,
    },
    serviceVouchers: {
      enabled: false,
      durationMatrix: DEFAULT_CLEANING_DURATION_MATRIX,
      minBookingTime: 2,
      minBookingTimeAdmin: 0.5,
      step: 0.5,
    },
    extraBookingSummary: {
      caption: undefined,
    },
    migrosLinkEnabled: false,
    bookingsToValidate: {
      bankAccountEnabled: false,
      bankAccountText: "",
    },
    extras: {
      windows: {
        times: {
          standard: 6,
          large: 10,
        },
        defaults: {
          standard: 2,
          large: 1,
        },
      },
    },
    hasUrssaf: false,
  },
  b2b: {
    migrosLinkEnabled: false,
    disabledExtrases: [],
    disabledFrequencies: [],
    duration: {
      max: 15,
      min: 1.5,
      minAdmin: 0.5,
      step: 0.5,
    },
  },
  giftCards: {
    quantity: 1,
    amount: 50,
  },
  availableCreditCardsProviders: [
    CREDIT_CARDS_PROVIDERS.VISA,
    CREDIT_CARDS_PROVIDERS.MASTERCARD,
    CREDIT_CARDS_PROVIDERS.AMEX,
  ],
  availableServices: [],
  cleaningProductsMarketplaceUrl: undefined,
  refRewardAmount: 0,
  referralEnabled: true,
  welcomeRewardAmount: 0,
  marketingConsentEnabled: false,
  globalRatingScore: 4.6,
};
