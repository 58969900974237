import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

const action = new ReduxActionNameCreator("localisation");

export const SET_LANGUAGE = action.make("set language");
export const SET_COUNTRY = action.make("set country");

export const setLanguage = (payload: string) => ({
  type: `${SET_LANGUAGE}${ACTION_SUCCESS}`,
  payload,
});

export const setCountry = (payload: string) => ({
  type: `${SET_COUNTRY}${ACTION_SUCCESS}`,
  payload,
});
