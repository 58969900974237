/**
 * @author jakubbujakowski
 * @since 2020-2-19
 *
 * Middleware function for AsyncActionCreatorFactory
 *
 * This function is used to refresh the browser window,
 * when it detects that user is running an outdated version of our app.
 * Refresh will only happen after 15 min. of inactivity when browser tab switches from inactive to active
 */

import { AUTO_REFRESH_WINDOW_AFTER, IS_BROWSER } from "@config/consts";

import { FetchFacadeReturnType } from "@services/FetchFacade";
import { isVersionTag } from "@services/IsVersionTag";

let timeout: ReturnType<typeof setTimeout>;

const versionRefresherMiddleware = (
  input: FetchFacadeReturnType,
): FetchFacadeReturnType => {
  const { headers } = input;

  const remoteVersion = String(headers["x-frontend-current-version"]);
  const localVersion = String(process.env.VERSION);

  /**
   * In staging & dev x-frontend-current-version header can return git branch names instead of tags.
   * If that happens script execution should be aborted
   */
  if (!isVersionTag(localVersion) || !isVersionTag(remoteVersion)) {
    return input;
  }

  const parseTag = (tag: string) => Number(tag.split(".").join(""));

  const isVersionOutdated = (remote: string, local: string) =>
    parseTag(local) < parseTag(remote);

  const handleRefresh = () => {
    if (!document.hidden) {
      location.reload();
    }
  };

  const enqueueRefresh = () => {
    if (!IS_BROWSER) return;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      document.addEventListener("visibilitychange", handleRefresh);
    }, AUTO_REFRESH_WINDOW_AFTER);
  };

  if (isVersionOutdated(remoteVersion, localVersion)) {
    enqueueRefresh();
  }

  return input;
};

export { versionRefresherMiddleware };
