import { AnyAction } from "redux";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

const actionCreator = new ReduxActionNameCreator("login", true);

export const ALLOW_TEMPORARY_LOGIN: string = actionCreator.make(
  "allow temporary login",
);

export const DISALLOW_TEMPORARY_LOGIN: string = actionCreator.make(
  "disallow temporary login",
);

export const allowTemporaryLogin = (): AnyAction => ({
  type: ALLOW_TEMPORARY_LOGIN,
});

export const disallowTemporaryLogin = (): AnyAction => ({
  type: DISALLOW_TEMPORARY_LOGIN,
});
