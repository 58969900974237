/**
 * Helper functions
 */

import theme from "@ui/themes/default";

/**
 * Function that generates transition declarations.
 * @param {string} property
 * @param {string} duration
 * @param {string} easing
 */
export default function transition(
  property: string | string[],
  duration: string = theme.animations.duration,
  easing: string = theme.animations.easing,
): string {
  const properties: string[] = Array.isArray(property) ? property : [property];

  return properties
    .reduce(
      (acc: string[], item: string) => [
        ...acc,
        `${duration} ${item} ${easing}`,
      ],
      [],
    )
    .join(", ");
}
