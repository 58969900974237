/**
 * MARKED AS DEPRECATED
 * This is a temporary solution which will be used
 * until we migrate to using only fully prefixed translations
 */
export const getPrefixWithDomain = (domain: string) => {
  // if TRANSLATION_PREFIX is undefined it means its used in static pages (www)
  // currently all static pages are batmaid related
  const translationPrefix = process.env.TRANSLATION_PREFIX || "batmaid_pwa_";
  const regex = /batmaid_pwa_|batwork_pwa_|batsoft_pwa_/;

  return domain.match(regex) ? domain : `${translationPrefix}${domain}`;
};
