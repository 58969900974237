import { USER_TYPES } from "@typings/user";

export interface IOAuthState {
  accessToken: string | null;
  provider: OAUTH_PROVIDERS;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  userType?: USER_TYPES | null;
}

export enum OAUTH_PROVIDERS {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  WINCASA = "wincasa",
  APPLE = "apple",
  DEFAULT = "",
}
