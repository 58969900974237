import * as React from "react";

import { defaultConfig } from "@config/configDefaults";
import { at } from "@config/instances/at";
import { be } from "@config/instances/be";
import { ch } from "@config/instances/ch";
import { de } from "@config/instances/de";
import { fr } from "@config/instances/fr";
import { it } from "@config/instances/it";
import { lu } from "@config/instances/lu";
import { nl } from "@config/instances/nl";
import { pl } from "@config/instances/pl";
import { uk } from "@config/instances/uk";
import { us } from "@config/instances/us";

import { AppConfig, DeepRequired, Paths } from "@typings/appConfig";
import { AVAILABLE_COUNTRIES } from "@typings/globals";

const ConfigContext = React.createContext<AppConfig | null>(null);

interface Props {
  country: AVAILABLE_COUNTRIES;
  children: React.ReactNode;
}

const ConfigProvider = (props: Props): React.ReactElement => {
  const [config, setConfig] = React.useState(getConfig(props.country));

  React.useEffect(() => {
    setConfig(getConfig(props.country));
  }, [props.country]);

  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
};

/**
 * Hook to getting a value from the configuration (ConfigProvider)
 * **REMEMBER!**
 * Even if some values are optional they should be defined in the `defaultValue` object.
 * Otherwise an error will occur.
 */
function useConfig<T = any>(selector: Paths<DeepRequired<AppConfig>>): T {
  const context = React.useContext(ConfigContext);
  if (context === null) {
    throw new Error("useConfig must be used within ConfigProvider");
  }

  let result;

  try {
    result = getValueByKeyPath(context, selector as string[]);
  } catch {
    result = getValueByKeyPath(defaultConfig, selector as string[]);
  }

  return result as T;
}

/**
 * This function will get config value outside of React Context.
 * Please don't use this in React components, use `useConfig` instead.
 */
const getConfigValue = (country: AVAILABLE_COUNTRIES, selector: string[]) => {
  const countryConfig = getConfig(country);

  try {
    return getValueByKeyPath(countryConfig, selector);
  } catch {
    return getValueByKeyPath(defaultConfig, selector);
  }
};

const getValueByKeyPath = (object: Record<string, any>, keyPath: string[]) => {
  let current: any = object;

  for (let i = 0; i < keyPath.length; i++) {
    if (keyPath[i] in current) {
      current = current[keyPath[i]];
    } else {
      throw new Error(`Can't find path in object`);
    }
  }

  return current;
};

const getConfig = (country: AVAILABLE_COUNTRIES) => {
  switch (country) {
    case AVAILABLE_COUNTRIES.AT:
      return at;
    case AVAILABLE_COUNTRIES.BE:
      return be;
    case AVAILABLE_COUNTRIES.CH:
      return ch;
    case AVAILABLE_COUNTRIES.DE:
      return de;
    case AVAILABLE_COUNTRIES.FR:
      return fr;
    case AVAILABLE_COUNTRIES.IT:
      return it;
    case AVAILABLE_COUNTRIES.LU:
      return lu;
    case AVAILABLE_COUNTRIES.NL:
      return nl;
    case AVAILABLE_COUNTRIES.PL:
      return pl;
    case AVAILABLE_COUNTRIES.UK:
      return uk;
    case AVAILABLE_COUNTRIES.US:
      return us;
    default:
      console.error(
        `Can't find configuration for ${country}. Using CH fallback.`,
      );
      return ch;
  }
};

export { ConfigProvider, useConfig, getConfigValue };
