import * as React from "react";
import { createPortal } from "react-dom";

import { IS_BROWSER } from "@config/consts";

interface Props {
  children: React.ReactNode;
}

const Portal = (props: Props): React.ReactPortal | null => {
  const containerRef = React.useRef(
    IS_BROWSER ? document.createElement("div") : null,
  );

  React.useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.setAttribute("id", "portal-root");
      document.body.appendChild(container);
    }

    return () => {
      container && document.body.removeChild(container);
    };
  }, []);

  if (containerRef.current) {
    return createPortal(props.children, containerRef.current);
  }

  return null;
};

export { Portal };
