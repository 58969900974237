import { CURRENCY_PER_COUNTRY } from "@config/consts";

import { AppConfig } from "@typings/appConfig";
import {
  AVAILABLE_COUNTRIES,
  AVAILABLE_LANGS,
  SERVICE_TYPE,
} from "@typings/globals";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.US],
  availableLanguages: [AVAILABLE_LANGS.EN],
  booking: {
    commitments: {
      enabled: false,
    },
  },
  b2b: {},
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  availableServices: [SERVICE_TYPE.HOME_CLEANING],
};

export { config as us };
