import colorProcessor from "color";

/**
 * Function that converts hex to rgb(a)
 * @param {string} color
 * @param {number} opacity?
 */
export default function rgba(color: string, opacity?: number): string {
  const colorInstance: colorProcessor = colorProcessor(color);

  if (typeof opacity === "number") {
    return colorInstance.alpha(opacity).toString();
  }

  return colorInstance.toString();
}
