import { Record } from "immutable";

import {
  IUser,
  IUserImpersonator,
  USER_SERVICE_TYPE,
  UrssafProfile,
} from "@typings/user";

const ImpersonatorFactory = (input: IUserImpersonator | null) => {
  if (input === null) {
    return input;
  }

  return Record<IUserImpersonator>({
    userName: "",
  })(input);
};

const UrssafProfileFactory = (input: UrssafProfile | null | undefined) => {
  if (!input) {
    return null;
  }

  return {
    ...input,
    registeredAt: input.registeredAt ? new Date(input.registeredAt) : null,
  };
};

export const UserFactory = (input?: IUser): Record<IUser> =>
  Record<IUser>({
    email: "",
    exitUserUrl: "",
    firstName: "",
    impersonator: null,
    lastName: "",
    logoutUrl: "",
    roles: [],
    userName: "",
    preferredZipCode: "",
    feistelHash: null,
    uuid: "",
    hexHumanId: "",
    identifier: "",
    companyName: "",
    defaultCleaningType: null,
    userType: null,
    urssafProfile: null,
    serviceType: USER_SERVICE_TYPE.UNKNOWN,
    paymentMethodStatus: null,
    hasOverduePayments: false,
    isInvoiced: false,
  })({
    ...input,
    impersonator: ImpersonatorFactory(
      typeof input !== "undefined" ? input.impersonator : null,
    ),
    urssafProfile: UrssafProfileFactory(input?.urssafProfile),
  });
