/**
 * @author jakubmaslon
 * @since 2020-08-12
 */

import * as React from "react";
import { useScrollPosition as useScrollPositionLib } from "@n8tb1t/use-scroll-position";

export enum SCROLL_DIR {
  DEFAULT = "DEFAULT",
  UP = "UP",
  DOWN = "DOWN",
}

export type CurrPos = {
  x: number;
  y: number;
};

export const useScrollPosition = (): {
  scrollDir: SCROLL_DIR;
  currPos: CurrPos;
} => {
  const [scrollDir, setScrollDir] = React.useState<SCROLL_DIR>(
    SCROLL_DIR.DEFAULT,
  );
  const [currPos, setCurrPos] = React.useState<CurrPos>({ x: 0, y: 0 });

  React.useEffect(() => {
    if (window.scrollY > 0) {
      setScrollDir(SCROLL_DIR.DOWN);
    }
  }, []);

  useScrollPositionLib(({ prevPos, currPos }) => {
    // ignore rubber band scrolling effect
    const currentYPosition = currPos.y > 0 ? 0 : currPos.y;
    const prevYPosition = prevPos.y > 0 ? 0 : prevPos.y;
    const isScrollingUpInTheCurrentMoment = currentYPosition >= prevYPosition;

    setCurrPos({
      x: currPos.x,
      y: currentYPosition,
    });

    if (isScrollingUpInTheCurrentMoment && scrollDir !== SCROLL_DIR.UP) {
      setScrollDir(SCROLL_DIR.UP);
    }

    if (!isScrollingUpInTheCurrentMoment && scrollDir !== SCROLL_DIR.DOWN) {
      setScrollDir(SCROLL_DIR.DOWN);
    }
  });

  return { scrollDir, currPos };
};
