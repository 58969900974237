import {
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
  css,
} from "styled-components";

import rem from "@ui/helpers/rem";

import { Variant } from "./Button.types";

const defaultDisabledStyle = css`
  background: ${props => props.theme.palette.grey20};
  color: ${props => props.theme.palette.grey400};
`;

const variantBlue = css`
  background: ${props => props.theme.palette.blue500};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      background: ${props => props.theme.palette.blue600};
    }
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantGreen = css`
  background: ${props => props.theme.palette.green500};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      background: ${props => props.theme.palette.green600};
    }
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantGrey = css`
  background: ${props => props.theme.palette.grey400};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      background: ${props => props.theme.palette.grey600};
    }
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantRed = css`
  background: ${props => props.theme.palette.red500};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      background: ${props => props.theme.palette.red700};
    }
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantWhite = css`
  color: ${props => props.theme.palette.grey800};
  border: none;
  background: ${props => props.theme.palette.white};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.grey800};
    }
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantBorderBlack = css`
  color: ${props => props.theme.palette.grey800};
  border: ${rem(1)} solid ${props => props.theme.palette.grey800};
  background: ${props => props.theme.palette.white};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.grey800};
      border: ${rem(1)} solid ${props => props.theme.palette.grey800};
    }
  }

  &:active,
  &:focus,
  &:disabled {
    border: ${rem(1)} solid ${props => props.theme.palette.grey800};
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantBorderGrey = css`
  color: ${props => props.theme.palette.grey400};
  border: ${rem(1)} solid ${props => props.theme.palette.grey400};
  background: ${props => props.theme.palette.white};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.grey400};
      border: ${rem(1)} solid ${props => props.theme.palette.grey400};
      background: ${props => props.theme.palette.grey50};
    }
  }

  &:active,
  &:focus,
  &:disabled {
    border: ${rem(1)} solid ${props => props.theme.palette.grey400};
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantBorderBlue = css`
  color: ${props => props.theme.palette.blue500};
  border: ${rem(1)} solid ${props => props.theme.palette.blue500};
  background: transparent;

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.blue700};
      border: ${rem(1)} solid ${props => props.theme.palette.blue600};
      background: ${props => props.theme.palette.blue50};
    }
  }

  &:active,
  &:focus,
  &:disabled {
    border: ${rem(1)} solid ${props => props.theme.palette.blue500};
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantBorderRed = css`
  color: ${props => props.theme.palette.red500};
  border: ${rem(1)} solid ${props => props.theme.palette.red500};
  background: transparent;

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.red700};
      border: ${rem(1)} solid ${props => props.theme.palette.red500};
      background: ${props => props.theme.palette.red50};
    }
  }

  &:active,
  &:focus,
  &:disabled {
    border: ${rem(1)} solid ${props => props.theme.palette.red500};
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantBorderGreen = css`
  color: ${props => props.theme.palette.green500};
  border: ${rem(1)} solid ${props => props.theme.palette.green500};
  background: ${props => props.theme.palette.white};

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.green500};
      border: ${rem(1)} solid ${props => props.theme.palette.green500};
      background: ${props => props.theme.palette.green50};
    }
  }

  &:active,
  &:focus,
  &:disabled {
    border: ${rem(1)} solid ${props => props.theme.palette.green500};
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantBorderWhite = css`
  color: ${props => props.theme.palette.white};
  border: ${rem(1)} solid ${props => props.theme.palette.white};
  background: transparent;

  &:active,
  &:focus,
  &:disabled {
    border: ${rem(1)} solid ${props => props.theme.palette.white};
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variantTextBlue = css`
  color: ${props => props.theme.palette.blue500};
  background: transparent;

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.blue700};
    }
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;
const variantTextGrey = css`
  color: ${props => props.theme.palette.grey400};
  background: transparent;

  ${props => props.theme.breakpoints.desktop} {
    &:hover {
      color: ${props => props.theme.palette.grey600};
    }
  }

  &:disabled {
    ${defaultDisabledStyle}
  }
`;

const variants: Record<
  Variant,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  blue: variantBlue,
  green: variantGreen,
  grey: variantGrey,
  red: variantRed,
  white: variantWhite,
  borderBlack: variantBorderBlack,
  borderGrey: variantBorderGrey,
  borderBlue: variantBorderBlue,
  borderGreen: variantBorderGreen,
  borderWhite: variantBorderWhite,
  borderRed: variantBorderRed,
  textBlue: variantTextBlue,
  textGrey: variantTextGrey,
};

const getVariant = (variant: Variant) => variants[variant] ?? "";

export { getVariant };
