export enum LOGIN {
  MODAL_WRAPPER = "loginModal",
  FORM_WRAPPER = "loginModalWrapper",
  CTA = "loginModalCTA",
  ERROR = "loginModalError",
  REMEMBER_ME_CHECKBOX = "loginModalRememberCheckbox",
  FORGOT_PASSWORD = "loginModalForgotPassword",
  SEPARATOR = "loginModalSeparator",
  FACEBOOK_ICON = "loginModalFacebookIcon",
  GOOGLE_ICON = "loginModalGoogleIcon",
  APPLE_ICON = "loginModalAppleIcon",
  FORGOT_PASSWORD_INPUT = "loginForgotPasswordInput",
  RESET_PASSWORD_BUTTON = "loginResetPasswordButton",
  RESET_PASSWORD_NOTIFICATION = "loginResetPasswordNotification",
}
