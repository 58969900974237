import { IS_BROWSER } from "@config/consts";

const generatePageId = (): string => {
  if (!IS_BROWSER) return "";

  const pathWithoutQueries = window.location.hash.split("?")[0];

  const [_, ...rest] = pathWithoutQueries.split("/");
  return rest.join("-");
};

export { generatePageId };
