type InfoRecord = Record<"default" | "smallTablet" | "smallDesktop", number>;

interface Info {
  headerHeight: InfoRecord;
  innerHeight: InfoRecord;
  scrollOffset: number;
  cssVarHeaderHeight: string;
}

export const info: Info = {
  headerHeight: {
    default: 53,
    smallTablet: 65,
    smallDesktop: 73,
  },
  innerHeight: {
    default: 32,
    smallTablet: 36,
    smallDesktop: 44,
  },
  scrollOffset: 80,
  cssVarHeaderHeight: "--nav-bar-height",
} as const;
