import { LoginReducer, loginReducer } from "@hooks/useAuth/redux/login";
import { OAuthReducer, oauthReducer } from "@hooks/useAuth/redux/oauth";
import { UserReducer, userReducer } from "@hooks/useAuth/redux/user";

export const useAuthReducer = {
  user: userReducer,
  oauth: oauthReducer,
  login: loginReducer,
};

export type AuthState = {
  user: UserReducer;
  oauth: OAuthReducer;
  login: LoginReducer;
};
