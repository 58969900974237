import { keyframes } from "styled-components";

import { rem } from "@ui/helpers";

export const arrowAnimation = keyframes`
  0% {
    transform: none;
  }

  50% {
    transform: translateX(100%);
  }

  51% {
    transform: translateX(-100%);
  }

  100% {
    transform: none;
  }
`;

export const fadeIn = keyframes`
  from {
    transform: translateY(-${rem(16)});
    opacity: 0;
  }
  
  to {
    transform: none;
    opacity: 1;
  }
`;

export const fadeInFromRight = keyframes`
  from {
    transform: translateX(${rem(16)});
    opacity: 0;
  }
  
  to {
    transform: none;
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    transform: none;
    opacity: 1;
  }
  
  to {
    transform: translateY(-${rem(16)});
    opacity: 0;
  }
`;

export const changeBgColor = (
  initialColor: string,
  midColor: string,
  finalColor: string,
): string => {
  return `
    @keyframes changeBgColor {
      0% {
        background-color: ${initialColor};
      }

      50% {
        background-color: ${midColor}
      }
      
      100% {
        background-color: ${finalColor};
      }
    }
  `;
};

export const slideDownTransition =
  "max-height 0.3s cubic-bezier(1, 0, 1, 0), opacity 0.3s cubic-bezier(1, 0, 1, 0)";

export const slideUpTransition =
  "max-height 0.3s cubic-bezier(0, 1, 0, 1), opacity 0.3s cubic-bezier(0, 1, 0, 1)";

export const slideUp = keyframes`
    from {
      bottom: -100%;
    }

    to {
      bottom: 0;
    }
`;

export const bounce = keyframes`
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-25px); }
  100% { transform: translateY(0); }
`;
