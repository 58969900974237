import { AppConfig } from "@typings/appConfig";
import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { CLEANING_DURATION_MATRIX_NL } from "@config/consts";

import {
  AVAILABLE_COUNTRIES,
  AVAILABLE_LANGS,
  SERVICE_TYPE,
} from "@typings/globals";
import { FREQUENCY } from "@typings/b2b";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.NL],
  availableLanguages: [AVAILABLE_LANGS.EN, AVAILABLE_LANGS.NL],
  booking: {
    minBookingTime: 3,
    durationMatrix: CLEANING_DURATION_MATRIX_NL,
    commitments: {
      enabled: false,
    },
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
      ],
      rows: [
        [false, true, true],
        [true, true, true],
        [true, true, true],
        [false, true, true],
        [true, true, true],
      ],
    },
  },
  b2b: {
    contactDetails: {
      phone: "+31 (0)20 215 40 70",
      email: "client@batmaid.nl",
    },
    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,
  availableServices: [SERVICE_TYPE.HOME_CLEANING],
};

export { config as nl };
