import * as Sentry from "@sentry/browser";

import { ORIGIN } from "@typings/globals";

import { SENTRY_DSN, SENTRY_ENABLED_PWA, SENTRY_ENV } from "@config/consts";

// if this value is exceeded Sentry doesn't report the tag value at all
const SENTRY_TAG_VALUE_MAX_LENGTH = 199;

// @TODO: minified hydration errors, they should be fixed
const reactErrors = [
  /Minified React error #418/i,
  /Minified React error #419/i,
  /Minified React error #423/i,
  /Minified React error #425/i,
  /Hydration failed because the initial UI/i,
  /Text content does not match server-rendered HTML/i,
  /There was an error while hydrating/i,
];

const initSentry = (origin: ORIGIN) => {
  if (!SENTRY_ENABLED_PWA) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: process.env.VERSION,
    beforeSend(event) {
      if (
        !event.exception ||
        !event.exception.values ||
        !event.exception.values[0]
      ) {
        return event;
      }

      const value = event.exception.values[0];

      if (reactErrors.some(errRegex => value.value?.match(errRegex))) {
        return null;
      }

      if (value.stacktrace && value.stacktrace.frames) {
        value.stacktrace.frames.forEach((frame: Sentry.StackFrame) => {
          if (frame.filename) {
            // this prefix is needed for SentryJS to know
            // to look for sourcemaps on it's servers
            const canonicalFilename = frame.filename.split("/assets/")[1];
            if (canonicalFilename) {
              frame.filename = `app:///${canonicalFilename}`;
            }
          }
        });
      }

      return event;
    },
    ignoreErrors: [
      "getReadModeRender",
      "userAgent",
      "window.__firefox__.reader",
      ...reactErrors,
    ],
  });
  Sentry.configureScope(scope => {
    scope.setTag("origin", origin);
  });
};

export { initSentry, SENTRY_TAG_VALUE_MAX_LENGTH };
