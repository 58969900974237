import React from "react";

import { IS_BROWSER, IS_DEV } from "@config/consts";

import { getQueryValue } from "@services/QueryParamsParser";

import { useAuthManager, useAuth } from "@hooks/useAuth";

export const URLParamsHandler = (): null => {
  const { openLoginModal } = useAuthManager();
  const { isUserLoggedIn, isUserDataFetched } = useAuth();

  const [showLoginState, setShowLoginState] = React.useState(false);

  if (!IS_BROWSER && IS_DEV) {
    console.warn("URLParamsHandler has to be hidden behid IS_BROWSER flag");
  }

  React.useEffect(() => {
    if (!IS_BROWSER) return;
    if (showLoginState) return;
    if (!isUserDataFetched) return;
    if (getQueryValue(window.location.href, "showlogin") !== "true") return;

    setShowLoginState(true);

    if (isUserLoggedIn) return;

    openLoginModal();
  }, [showLoginState, isUserLoggedIn, isUserDataFetched]);

  return null;
};
