/**
 * @author tomekbuszewski
 * @since 2019-4-12
 */

import * as React from "react";

export type IconVariant = "line" | "solid";
export interface Icon {
  className?: string;
  color?: string;
  secondaryColor?: string;
  size?: number; // refactor to width
  height?: number;
  style?: { [k: string]: string };
  variant?: IconVariant;
  elementRef?: React.MutableRefObject<any>;
}

export const style = (size?: number, height?: number) => ({
  height: height || "auto",
  width: size || 16,
});
