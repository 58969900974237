/**
 * @author tomekbuszewski
 * @since 2019-3-12
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { MODAL } from "@config/testIds/modal";

import { SimpleObject } from "@typings/globals";

import { Close } from "@ui/Assets/Symbolicons/Close";
import { Button, TestWrapper } from "@ui/Atoms";
import { fadeIn, fadeOut, slideUp } from "@ui/animations";
import { rem, rgba } from "@ui/helpers";
import theme from "@ui/themes/default";
import { Backdrop } from "@ui/Molecules/Backdrop";

import { Size, Padding } from "./";

interface ModalHeaderProps {
  mobileGutter?: boolean;
}

interface Props {
  cancel?: (e?: any) => void;
  cancelText: string;
  isCancelBtnLoading?: boolean;
  centeredContent?: boolean;
  children?: React.ReactNode;
  className?: string;
  close: (e?: any) => void;
  closeButtonId?: string;
  isSubmitBtnDisabled?: boolean;
  isSubmitBtnLoading?: boolean;
  options?: SimpleObject;
  padding: Padding;
  size?: Size;
  submit?: (e?: any) => void;
  submitText: string;
  testId?: string;
  title?: string | React.ReactElement;
  visible: boolean;
  withContainedHeight?: boolean;
  withFooter?: boolean;
  withHeader?: boolean;
  withCancelBtn?: boolean;
  withCloseBtn?: boolean;
  withMobileHeaderGutter?: boolean;
  submitBtnColor?: "green" | "blue" | "red";
  isBottomVariant?: boolean;
  customHeader?: React.ReactNode;
  isMobileFullScreen?: boolean;
  hideWithCss?: boolean; // Use only for SEO
  bodyId?: string;
  preventOverflow?: boolean;
  withOldCloseIcon?: boolean; // @TODO: to be removed with icons migration
  withBorder?: boolean;
}

const Modal = (props: Props) => {
  return (
    <Backdrop
      hideWithCss={props.hideWithCss}
      visible={props.visible}
      onClick={props.close}
    >
      <TestWrapper testId={props.testId}>
        <ModalContent
          role="dialog"
          aria-modal="true"
          className={props.className}
          isBottomVariant={props.isBottomVariant}
        >
          {props.customHeader ? (
            props.customHeader
          ) : (
            <React.Fragment>
              {props.withHeader && (
                <ModalHeader mobileGutter={props.withMobileHeaderGutter}>
                  <span>{props.title && props.title}</span>
                </ModalHeader>
              )}
              {props.withCloseBtn && (
                <TestWrapper testId={MODAL.CLOSE_BTN}>
                  <ModalCloseButton
                    id={props.closeButtonId}
                    onClick={props.close}
                    floating={!props.withHeader}
                    withOldCloseIcon={props.withOldCloseIcon}
                  >
                    {props.withOldCloseIcon && (
                      <Close
                        variant="solid"
                        size={theme.icons.sizes.base_x3}
                        color={theme.palette.grey600}
                      />
                    )}
                    {!props.withOldCloseIcon && (
                      <Close
                        variant="line"
                        size={theme.icons.sizes.base_x4}
                        color={theme.palette.grey800}
                      />
                    )}
                  </ModalCloseButton>
                </TestWrapper>
              )}
            </React.Fragment>
          )}
          <ModalBody
            size={props.size}
            padding={props.padding}
            centeredContent={props.centeredContent}
            containedHeight={props.withContainedHeight}
            withFooter={props.withFooter}
            isMobileFullScreen={props.isMobileFullScreen}
            id={props.bodyId}
            preventOverflow={props.preventOverflow}
          >
            {props.children}
          </ModalBody>
          {props.withFooter && props.submit && (
            <ModalFooter isMobileFullScreen={props.isMobileFullScreen}>
              {props.submitText && (
                <TestWrapper testId={MODAL.FOOTER_CONFIRM_BTN}>
                  <Button
                    variant={props.submitBtnColor || "green"}
                    onClick={props.submit}
                    disabled={props.isSubmitBtnDisabled}
                    isLoading={props.isSubmitBtnLoading}
                  >
                    {props.submitText}
                  </Button>
                </TestWrapper>
              )}

              {props.withCancelBtn && props.cancelText && (
                <TestWrapper testId={MODAL.FOOTER_CANCEL_BTN}>
                  <Button
                    variant="textBlue"
                    onClick={props.cancel || props.close}
                    isLoading={props.isCancelBtnLoading}
                  >
                    {props.cancelText}
                  </Button>
                </TestWrapper>
              )}
            </ModalFooter>
          )}
        </ModalContent>
      </TestWrapper>
    </Backdrop>
  );
};

Modal.defaultProps = {
  withFooter: true,
  withHeader: true,
  withCancelBtn: true,
  withCloseBtn: true,
  withBorder: true,
};

const ModalView = styled(Modal)<Props>`
  animation: ${props => (props.visible ? fadeIn : fadeOut)}
    ${props => props.theme.animations.durationLong}
    ${props => props.theme.animations.easing} forwards;

  width: 100%;
  max-width: ${props => (props.isMobileFullScreen ? "100vw" : "98vw")};
  max-height: 98vh;

  ${props =>
    props.isMobileFullScreen &&
    `
    max-height: calc(100vh - 50px);
    max-height: 100svh;
  `}

  ${props =>
    props.withContainedHeight &&
    `
    max-height: 86vh;
  `}

  position: relative;
  ${props =>
    (props.size === "base" || props.size === "base_x2") &&
    css`
      ${props => props.theme.breakpoints.tablet} {
        top: -10%;
      }
    `};

  border-radius: ${props => props.theme.buttons.borderRadius};
  background: ${props => props.theme.palette.white};
  border: ${rem(1)} solid ${props => props.theme.palette.grey50};
  box-shadow: ${props =>
    `0 ${rem(4)} ${rem(8)} 0 ${rgba(props.theme.palette.grey700, 0.2)}`};

  ${props =>
    !props.withBorder &&
    css`
      border-width: 0;
    `}

  ${props => props.theme.breakpoints.tablet} {
    top: 0;
    max-width: ${props =>
      (props.size === "thin" && rem(400)) ||
      (props.size === "base" && rem(450)) ||
      (props.size === "base_x2" && rem(650)) ||
      (props.size === "large" && rem(900)) ||
      rem(600)};
  }

  ${props => props.theme.breakpoints.tablet} {
    max-height: 98vh;
  }

  ${props =>
    !props.isMobileFullScreen &&
    css`
      /* iOS devices */
      @supports (-webkit-touch-callout: none) {
        max-height: 85vh !important;
        overflow: scroll;
      }
    `}
`;

export { ModalView, ModalBody };

export const ModalHeader = styled.header<ModalHeaderProps>`
  position: relative;
  display: flex;

  padding: ${props => props.theme.margins.base_x4}
    ${props => props.theme.margins.half} ${props => props.theme.margins.base_x2}
    0;
  margin: 0 ${props => props.theme.margins.base_x4};
  margin-bottom: ${props =>
    props.mobileGutter ? props.theme.margins.base_x3 : 0};

  font-size: ${props => props.theme.fonts.sizes.h6};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  color: ${props => props.theme.palette.grey800};

  border-bottom: ${rem(1)} solid ${props => props.theme.palette.grey100};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props =>
      props.mobileGutter ? props.theme.margins.base_x3 : 0};
  }

  ${props => props.theme.breakpoints.desktop} {
    margin: 0 ${props => props.theme.margins.base_x5};
  }

  span {
    padding-right: ${props => props.theme.margins.base_x2};
  }
`;

interface ModalBodyProps {
  size?: Size;
  padding?: Padding;
  centeredContent?: boolean;
  containedHeight?: boolean;
  withFooter?: boolean;
  isMobileFullScreen?: boolean;
  preventOverflow?: boolean;
}

const ModalBody = styled.div<ModalBodyProps>`
  padding: ${props =>
    (props.padding === "normal" &&
      `${props.theme.margins.base_x2} ${props.theme.margins.base_x4}`) ||
    (props.padding === "none" && props.theme.margins.none) ||
    props.theme.margins.base_x4};
  overflow: ${props => (props.preventOverflow ? "hidden" : "auto")};
  max-height: ${props =>
    props.isMobileFullScreen
      ? "initial"
      : `calc(${props.containedHeight ? "75vh" : "100vh"} - ${rem(224)})`};
  text-align: ${props => (props.centeredContent ? "center" : "initial")};

  ${props => props.theme.orientation.landscape} {
    max-height: ${props =>
      props.isMobileFullScreen ? "initial" : `calc(75vh - ${rem(144)})`};
  }

  ${props =>
    !props.isMobileFullScreen &&
    css`
      /* iOS devices */
      @supports (-webkit-touch-callout: none) {
        max-height: calc(100vh - ${rem(320)});
      }
    `}

  ${props => props.theme.breakpoints.desktop} {
    min-height: ${props =>
      (props.size === "base" && rem(100)) ||
      (props.size === "base_x2" && rem(270))};
    max-height: calc(100vh - ${rem(260)});
    padding: ${props =>
      (props.padding === "normal" &&
        `${props.theme.margins.base_x2} ${props.theme.margins.base_x5}`) ||
      (props.padding === "none" && props.theme.margins.none) ||
      `${props.theme.margins.base_x2} ${props.theme.margins.base_x5}`};

    ${props =>
      !props.withFooter &&
      `
        padding-bottom: ${props.theme.margins.base_x5};
      `}
  }
`;

const ModalFooter = styled.footer<{ isMobileFullScreen?: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: ${props => props.theme.margins.base} 0
    ${props => props.theme.margins.base_x4};
  margin: 0 ${props => props.theme.margins.base_x4};
  background: ${props => props.theme.palette.white};
  position: relative;

  border-top: ${rem(1)} solid ${props => props.theme.palette.grey100};

  & > button {
    margin-left: ${props => props.theme.margins.base};

    &:first-of-type {
      margin-left: 0;
    }
  }

  ${props =>
    !props.isMobileFullScreen &&
    css`
      /* iOS devices */
      @supports (-webkit-touch-callout: none) {
        padding: ${props => props.theme.margins.base} 0
          ${props => props.theme.margins.base_x2};
      }

      ${props => props.theme.breakpoints.desktop} {
        margin: 0 ${props => props.theme.margins.base_x5};
      }
    `}
`;

export const ModalCloseButton = styled.button<{
  floating?: boolean;
  withOldCloseIcon?: boolean;
}>`
  margin-left: auto;
  cursor: pointer;
  position: absolute;
  right: ${props => props.theme.margins.base_x3};
  top: ${props =>
    props.withOldCloseIcon ? rem(34) : props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.desktop} {
    right: ${props => props.theme.margins.base_x4};
  }

  &:hover {
    svg {
      g {
        fill: ${props => props.theme.palette.grey700};
      }
    }
  }
`;

const ModalContent = styled.div<{ isBottomVariant?: boolean }>`
  ${props =>
    props.isBottomVariant &&
    css`
      position: fixed;
      animation: ${slideUp} ${props.theme.animations.durationLong} forwards
        ease-in-out 1;

      header {
        border: none;

        span {
          padding: 0;
          font-size: ${props.theme.fonts.sizes.subtitle};
        }
      }

      > button {
        svg use {
          fill: ${props => props.theme.palette.blue500};
        }
      }

      > div > ul {
        margin: 0;
        padding: 0;
        font-size: ${props => props.theme.fonts.sizes.body2};
        color: ${props => props.theme.palette.blue500};

        svg {
          vertical-align: initial;
          color: ${props => props.theme.palette.blue500};
        }

        ul {
          button {
            padding: 0 ${props => props.theme.margins.half};
          }
        }

        ${props => props.theme.breakpoints.desktop} {
          margin-bottom: 0;
        }
      }
    `}
`;

export const JobInfoCustomModalHeader = styled.header<{
  hasChangeRequest?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.margins.base_x4}
    ${props => props.theme.margins.base_x5}
    ${props => props.theme.margins.base_x2};
  background: ${props =>
    props.hasChangeRequest
      ? props.theme.palette.orange700
      : props.theme.palette.grey800};
  color: ${props => props.theme.palette.white};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  border-radius: ${props => props.theme.buttons.borderRadius}
    ${props => props.theme.buttons.borderRadius} 0 0;
`;
