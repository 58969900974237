import {
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  WHOAMI_ENDPOINT,
  USER_RECOVER_PASSWORD_ENDPOINT,
  USER_DISCONNECT_OAUTH_ENDPOINT,
  CLIENT_LOGIN_ENDPOINT,
  AGENT_LOGIN_ENDPOINT,
  MY_CLEANINGS_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/reduxThunkActions";
import { ILoginUser, USER_TYPES } from "@typings/user";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@app/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("user");

export const FETCH_USER_DATA = action.make("fetch useAuth data");
export const LOGIN_USER = action.make("login");
export const LOGOUT_USER = action.make("logout");
const RECOVER_PASSWORD = action.make("recover password");
const DISCONNECT_OAUTH = action.make("disconnect oauth");
const DELETE_ACCOUNT = action.make("delete account");

export const fetchUserData =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: WHOAMI_ENDPOINT,
      action: FETCH_USER_DATA,
    });

    return dispatch(action);
  };

const getEndpointLogin = (userType: USER_TYPES | null | undefined) => {
  switch (userType) {
    case USER_TYPES.AGENT:
      return AGENT_LOGIN_ENDPOINT;
    case USER_TYPES.CLIENT:
      return CLIENT_LOGIN_ENDPOINT;
    default:
      return LOGIN_ENDPOINT;
  }
};

export const loginUser =
  ({ username, password, rememberMe, userType }: ILoginUser) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      action: LOGIN_USER,
      method: ASYNC_ACTION_TYPES.POST,
      url: getEndpointLogin(userType),
      body: { username, password, rememberMe },
      retryConnection: false,
    });

    return dispatch(action);
  };

export const logoutUser =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      action: LOGOUT_USER,
      url: LOGOUT_ENDPOINT,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

export const recoverPassword =
  (email: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      action: RECOVER_PASSWORD,
      url: USER_RECOVER_PASSWORD_ENDPOINT,
      method: ASYNC_ACTION_TYPES.POST,
      body: { email },
    });

    return dispatch(action);
  };

export const disconnectOAuth =
  (password: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      action: DISCONNECT_OAUTH,
      url: USER_DISCONNECT_OAUTH_ENDPOINT,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        password,
      },
    });

    return dispatch(action);
  };

export const deleteAccount =
  (reasons: string[]) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: MY_CLEANINGS_ENDPOINT,
      body: {
        reasons,
      },
      action: DELETE_ACCOUNT,
      method: ASYNC_ACTION_TYPES.DELETE,
    });

    return dispatch(action);
  };
