import { IS_BROWSER, GOOGLE_MAPS_API_KEY } from "@config/consts";

const GOOGLE_MAP_SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${GOOGLE_MAPS_API_KEY}`;

export const loadGoogleMapsScript = () => {
  if (!IS_BROWSER) {
    return Promise.resolve();
  }

  const google = (window as any).google;

  if (typeof (google as any) !== "undefined") {
    if (google.maps && google.maps.api) return Promise.resolve();
  }

  const scriptElements = document.querySelectorAll(
    `script[src*="https://maps.googleapis.com/maps/api/js"]`,
  );

  if (scriptElements && scriptElements.length) {
    return new Promise(resolve => {
      // script already loaded, resolve
      if (typeof google !== "undefined") return resolve(undefined);

      // wait until script is loaded and resolve
      scriptElements[0].addEventListener("load", () => resolve(undefined));
    });
  }

  const el = document.createElement("script");
  el.src = GOOGLE_MAP_SCRIPT_URL;

  document.body.appendChild(el);

  return new Promise(resolve => {
    el.addEventListener("load", () => resolve(undefined));
  });
};
