import * as React from "react";
import ReactMarkdown from "react-markdown";
import { SpecialComponents } from "react-markdown/lib/ast-to-react";
import { NormalComponents } from "react-markdown/lib/complex-types";
import rehypeRaw from "rehype-raw";

interface Props {
  children: string;
  linkTarget?: string;
  disallowedTypes?: string[];
  renderers?: { [nodeType: string]: React.ElementType };
  components?: Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  >;
}

export const MarkdownSelfLinkRenderer = (props: {
  href: string;
  children: React.ReactElement;
}): React.ReactElement => (
  <a href={props.href} target="_self">
    {props.children}
  </a>
);

export const MarkdownRelNoreferrerLinkRenderer = (props: {
  href: string;
  children: React.ReactElement;
}): React.ReactElement => (
  <a href={props.href} target="_blank" rel="noreferrer">
    {props.children}
  </a>
);

export const Markdown = (props: Props): React.ReactElement => (
  <ReactMarkdown
    disallowedElements={props.disallowedTypes || ["p"]}
    linkTarget={props.linkTarget || "_blank"}
    unwrapDisallowed
    rehypePlugins={[rehypeRaw]}
    components={props.components}
  >
    {props.children}
  </ReactMarkdown>
);
