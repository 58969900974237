import { Config } from "./GenericPortal.types";

export const config: Config = {
  dialog: {
    id: "dialog-portal-root",
  },
  drawer: {
    id: "drawer-portal-root",
  },
};
