import React from "react";

import { breakpointsValues } from "@ui/themes/default";
import { TestWrapper } from "@ui/Atoms";

import { useResize } from "@hooks/useResize";

import * as Styled from "./Drawer.styled";
import { DrawerProps } from "./Drawer.types";

/**
 *
 * @param customHeader - use the DrawerHeader component to customize the header
 * @returns
 */
export const Drawer = ({
  children,
  testId,
  closeTestId,
  customHeader,
  placement = "right",
  variant = "mixed",
  noAnimation,
  onRequestClose,
}: DrawerProps) => {
  const { windowWidth } = useResize();
  const isMobile = windowWidth < breakpointsValues.tablet;

  return (
    <TestWrapper testId={testId}>
      <Styled.Root>
        <Styled.BackdropBox
          $noAnimation={noAnimation}
          onClick={onRequestClose}
        />
        <Styled.Body placement={placement} $noAnimation={noAnimation}>
          {!customHeader && (
            <TestWrapper testId={closeTestId}>
              <Styled.Header>
                <Styled.Button onClick={onRequestClose}>
                  {variant === "arrow" && <Styled.RevertedArrow />}
                  {variant === "cross" && <Styled.Cross />}
                  {variant === "mixed" && (
                    <React.Fragment>
                      {isMobile && <Styled.RevertedArrow />}
                      {!isMobile && <Styled.Cross />}
                    </React.Fragment>
                  )}
                </Styled.Button>
              </Styled.Header>
            </TestWrapper>
          )}

          {!!customHeader && customHeader}

          <Styled.Content>{children}</Styled.Content>
        </Styled.Body>
      </Styled.Root>
    </TestWrapper>
  );
};
