import { isSessionStorageAvailable } from "@services/IsSessionStorageAvailable";

interface LocalStorageFacadeReturnType {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

const sessionStorageFacade = (): LocalStorageFacadeReturnType => {
  const available = isSessionStorageAvailable();
  const emptyFn = () => null;

  return available
    ? {
        getItem: key => sessionStorage.getItem(key),
        setItem: (key, value) => sessionStorage.setItem(key, value),
        removeItem: key => sessionStorage.removeItem(key),
      }
    : {
        getItem: emptyFn,
        setItem: emptyFn,
        removeItem: emptyFn,
      };
};

export { sessionStorageFacade };
