import styled, { css } from "styled-components";

import { text } from "@ui/helpers";
import { breakpointsValues, breakpointsValuesV2 } from "@ui/themes/default";

import { sortByPriority } from "@services/SortByPriority";

import {
  TextProps,
  TextStyle,
  VarinantObjectV1,
  VarinantObjectV2,
} from "./Text.types";

/**
 * When you passing the`$style` prop as an object, you should use the `breakpointsVariant="v2"` prop to specify the current version of breakpoints.
 */
export const Text = styled.div<TextProps>`
  // Workaround for the issue with inheritance of the "all" property in styled-components
  ${props =>
    props.$allUnset &&
    css`
      all: unset;
    `}

  ${props =>
    props.$breakWord &&
    css`
      hyphens: auto;
      word-break: auto-phrase;
    `}

  letter-spacing: 0;
  margin: 0;

  ${({ $style }) => typeof $style === "string" && text($style)}

  /* v1 breakpoints */
  ${({ $style, $breakpointsVersion, theme }) =>
    typeof $style === "object" &&
    $breakpointsVersion === "v1" &&
    Object.keys($style)
      .sort(sortByPriority({ default: 0, ...breakpointsValues }))
      .map((breakpoint: keyof VarinantObjectV1) => {
        if (breakpoint === "default") {
          return text($style[breakpoint]);
        }

        return css`
          ${theme.breakpoints[breakpoint]} {
            ${text($style[breakpoint] as TextStyle)}
          }
        `;
      })}

/* v2 breakpoints */
  ${({ $style, $breakpointsVersion, theme }) =>
    typeof $style === "object" &&
    $breakpointsVersion !== "v1" &&
    Object.keys($style)
      .sort(sortByPriority({ default: 0, ...breakpointsValuesV2 }))
      .map((breakpoint: keyof VarinantObjectV2) => {
        if (breakpoint === "default") {
          return text($style[breakpoint]);
        }

        return css`
          ${theme.breakpointsV2[breakpoint]} {
            ${text($style[breakpoint] as TextStyle)}
          }
        `;
      })}

  ${({ $color, theme }) =>
    css`
      color: ${$color ? theme.palette[$color] : "currentColor"};
    `}
`;
