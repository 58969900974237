import { DEFAULT_LANGUAGE } from "@config/consts";

import { langFromTag } from "@services/LangFromTag";

export const linkResolver = (doc: any) => {
  const canonicalDocLang = langFromTag(doc.lang || "");
  const lang = canonicalDocLang || DEFAULT_LANGUAGE;
  const pathPrefix = `/${lang}`;

  // about us / terms of use / privacy policy etc.
  if (doc.type === "content_page") {
    const pageName = doc.data?.slug[0]?.text || "";
    return `${pathPrefix}/${pageName}`;
  }

  // city page or service page
  if (
    doc.type === "city_page" ||
    doc.type === "service_page" ||
    doc.type === "apply_page"
  ) {
    const pageName = doc.data?.slug[0]?.text || "";
    return `${pathPrefix}/${pageName}`;
  }

  if (doc.type === "generic_page") {
    const pageName = doc.data?.slug || "";
    return `${pathPrefix}/${pageName}`;
  }

  // landing page
  return `${pathPrefix}/`;
};
