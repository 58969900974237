import { createSelector } from "reselect";

import { AuthState } from "@hooks/useAuth";

const getIsTemporaryLoginAllowedFunction = (store: AuthState) =>
  store.login.get("allowTemporaryLogin");

export const getIsTemporaryLoginAllowed = createSelector(
  getIsTemporaryLoginAllowedFunction,
  f => f,
);
