import React from "react";

import { IS_BROWSER } from "@config/consts";

import { PortalType } from "./GenericPortal.types";
import { config } from "./GenericPortal.config";

const createContainer = (id: string, testId?: string): HTMLDivElement => {
  const $container = document.createElement("div");

  $container.setAttribute("id", id);
  $container.setAttribute("role", "presentation");

  if (testId) {
    $container.setAttribute("data-testid", testId);
  }

  return $container;
};

export const usePortalContainer = (type: PortalType, testId?: string) => {
  const $container = React.useRef<HTMLDivElement | null>(null);

  const { id } = config[type];

  const renderPortal = () => {
    if (!IS_BROWSER) return;

    document.body.appendChild(createContainer(id, testId));
  };

  React.useEffect(() => {
    if (!IS_BROWSER || $container.current) return;

    const $containerDOM = document.getElementById(id) as HTMLDivElement;

    // Handle case when portal container is already in DOM
    if ($containerDOM) {
      $container.current = $containerDOM;
      return;
    }

    // Handle case when portal container is not in DOM
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach((node: HTMLDivElement) => {
          if (typeof node === "object" && node?.id === id) {
            $container.current = node;
            observer.disconnect();
          }
        });
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: false,
    });
  }, []);

  return {
    $container,
    renderPortal,
  };
};
