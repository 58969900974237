import { IS_PROD } from "@config/consts";
import i18next from "@config/i18next";

import { AsyncActionCreatorFactory } from "@services/AsyncActionCreatorFactory";
import { versionRefresherMiddleware } from "@services/VersionRefresherMiddleware";

import { showGenericErrorToast } from "@app/containers/Toastify";

const onFetchErrorNotification = () => showGenericErrorToast();

const middleware =
  process.env.NODE_ENV === "production" ? [versionRefresherMiddleware] : [];

const asyncActionCreator = AsyncActionCreatorFactory({
  onFetchErrorNotification,
  invalidValueText: i18next.t("batmaid_pwa_generic.invalid_value"),
  middleware,
});

export { asyncActionCreator };
