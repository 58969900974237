import {
  LocalisationReducer,
  localisationReducer,
} from "@hooks/useLocalisation/redux/localisation";

export const useLocalisationReducer = {
  useLocalisation: localisationReducer,
};

export type LocalisationState = {
  useLocalisation: LocalisationReducer;
};
