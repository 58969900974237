enum ANCHOR_QURIES {
  SCROLL_TO = "scrollTo",
  SHOW = "show",
}

enum ANCHOR_IDS {
  // HOME CLEANING
  PREVIOUS_BATMAID = "previous-batmaid",

  // USER PROFILE DASHBOARD
  USER_PROFILE_USER_INFO = "user-info",
  USER_PROFILE_PAYMENT_METHODS = "payment-methods",
  USER_PROFILE_ADD_VOUCHER = "add-voucher",

  // USER OPTIONS DASHBOARD
  USER_OPTIONS_PETS = "pets",

  // Homepage
  OUR_SERVICES = "our-services",
  BOOK_CLEANING = "book-cleaning",
  REVIEWS = "reviews",
}

export { ANCHOR_IDS, ANCHOR_QURIES };
