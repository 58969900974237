import * as React from "react";

import { IS_BROWSER } from "@config/consts";

import { GenericPortal } from "@containers/GenericPortal";

import { useInitProvider } from "./DrawerManager.hooks";
import { ContextProps } from "./DrawerManager.types";

export const DrawerContext = React.createContext<ContextProps | null>(null);

const DrawerManagerProvider = (
  props: React.PropsWithChildren<any>,
): React.ReactElement => {
  const context = useInitProvider();
  const { renderPortal } = GenericPortal.usePortalContainer("drawer");

  React.useEffect(() => {
    if (!IS_BROWSER) return;

    renderPortal();
  }, []);

  return (
    <DrawerContext.Provider value={context}>
      {props.children}
    </DrawerContext.Provider>
  );
};

export { DrawerManagerProvider };
