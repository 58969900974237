import {
  IS_BROWSER,
  IS_DEV,
  LANGUAGE_LOCAL_STORAGE_KEY,
  POSSIBLE_LANGUAGE_OPTIONS,
} from "@config/consts";

import { AVAILABLE_LANGS } from "@typings/globals";

import { localStorageFacade } from "@services/LocalStorageFacade";

export const langFromPathname = (): AVAILABLE_LANGS => {
  if (!IS_BROWSER) {
    return AVAILABLE_LANGS.EN;
  }

  const localStorage = localStorageFacade();
  let lang = window.location.pathname.split("/")[1] as AVAILABLE_LANGS;

  if (IS_DEV) {
    lang = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) as AVAILABLE_LANGS;
  }

  const isLangSupported = POSSIBLE_LANGUAGE_OPTIONS.includes(lang);

  return isLangSupported ? lang : AVAILABLE_LANGS.EN;
};
