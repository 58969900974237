/**
 * Achtung!
 * This component has a sibling component in `@ui/Organisms/ModalContentLogin`
 * You might want to consider synchronising changes between those components.
 */

import { Form, Formik } from "formik";
import * as React from "react";
import styled, { useTheme } from "styled-components";

import { LOGIN } from "@config/testIds/login";

import { NOTIFICATION_TYPES } from "@typings/globals";
import { OAUTH_PROVIDERS } from "@typings/oauth";
import { ILoginUser } from "@typings/user";

import { Arrow } from "@ui/Assets/Symbolicons/Arrow";
import { Button, FadeInContainer, Margins, TestWrapper } from "@ui/Atoms";
import { Checkbox, FormError, Input } from "@ui/Atoms/Form";
import { rem } from "@ui/helpers";
import { Notification } from "@ui/Molecules";
import { FormikInputWithError } from "@ui/Molecules/FormikInputWithError";

import { GoogleLogin } from "@containers/GoogleLogin";
import { AppleLogin } from "@containers/AppleLogin";

import { useFeatureToggle } from "@hooks/useFeatureToggle";

import {
  FIELDS,
  getLoginSchema,
  sharedInputProps,
} from "./ModalContentLogin.helpers";

interface Props {
  loginUser: (credentials: ILoginUser) => void;
  loginUserByAccessToken: (
    provider: OAUTH_PROVIDERS,
    accessToken: string,
    profile: { firstName: string; lastName: string; email: string },
  ) => void;
  onRecoverPassword: (email: string) => Promise<any>;
  onLoginSuccess: () => void;
  onSetErrors: (error: string | null) => void;
  onCloseNotification: () => void;
  error: string | null;
  notification: string | null;
  isMobileFullScreen?: boolean;
  initialValues?: {
    [FIELDS.EMAIL]?: string;
  };
  translations: {
    retryLoginText: string;
    loginFailedText: string;
    wrongEmailFormatText: string;
    emailLabelText: string;
    passwordLabelText: string;
    resetPasswordButtonText: string;
    backToLoginButtonText: string;
    forgotPasswordLinkText: string;
    rememberMeCheckboxLabel: string;
    loginButtonText: string;
    loginSeparatorText: string;
    continueWithGoogleText: string;
    continueWithAppleText: string;
  };
}

const ModalContentLogin = (props: Props): React.ReactElement => {
  const [rememberMe, setRememberMe] = React.useState<boolean>(true);
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const theme = useTheme();

  const isAppleLoginActive = useFeatureToggle("pwa_login_by_apple");

  const handleSubmit = (e: ILoginUser) => {
    props.loginUser({ ...e, rememberMe });
  };

  const handlePasswordResetSubmit = async (e: {
    email: string;
  }): Promise<void> => {
    await props.onRecoverPassword(e.email);
  };

  const handleBackToLogin = () => {
    props.onSetErrors(null);
    props.onCloseNotification();
    setShowForgotPassword(!showForgotPassword);
  };

  /** Forgot Password view */
  if (showForgotPassword) {
    return (
      <FadeInContainer>
        <Formik
          onSubmit={handlePasswordResetSubmit}
          initialValues={{
            [FIELDS.EMAIL]: "",
            ...props.initialValues,
          }}
          validationSchema={getLoginSchema(
            props.translations.wrongEmailFormatText,
          )}
        >
          {formikProps => (
            <Form>
              {!!props.notification && (
                <Margins xs={[null, "base_x2", null, null]}>
                  <Notification
                    onClose={() => props.onCloseNotification()}
                    variant={NOTIFICATION_TYPES.SUCCESS}
                    autoHide={false}
                    testId={LOGIN.RESET_PASSWORD_NOTIFICATION}
                  >
                    {props.notification}
                  </Notification>
                </Margins>
              )}
              <Margins xs={[null, "base_x2", null, null]}>
                <div>
                  <FormikInputWithError
                    label={props.translations.emailLabelText}
                    name={FIELDS.EMAIL}
                    value={formikProps.values[FIELDS.EMAIL]}
                    handleChange={formikProps.handleChange}
                    testId={LOGIN.FORGOT_PASSWORD_INPUT}
                  />
                </div>
              </Margins>
              <Margins xs={[null, "base_x2", null, null]}>
                <div>
                  <TestWrapper testId={LOGIN.RESET_PASSWORD_BUTTON}>
                    <Button size="large" variant="blue">
                      {props.translations.resetPasswordButtonText}
                    </Button>
                  </TestWrapper>
                </div>
              </Margins>
              <Button
                size="large"
                variant="borderBlue"
                onClick={handleBackToLogin}
              >
                {props.translations.backToLoginButtonText}
              </Button>
            </Form>
          )}
        </Formik>
      </FadeInContainer>
    );
  }

  /** Login view */
  return (
    <FadeInContainer>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          [FIELDS.USERNAME]: "",
          [FIELDS.PASSWORD]: "",
          [FIELDS.REMEMBER_ME]: rememberMe,
        }}
      >
        {formikProps => (
          <TestWrapper testId={LOGIN.FORM_WRAPPER}>
            <Form>
              {!!props.error && (
                <Margins xs={[null, "base_x2", null, null]}>
                  <div>
                    <FormError isCentered testId={LOGIN.ERROR}>
                      {props.error}
                    </FormError>
                  </div>
                </Margins>
              )}
              {!!props.notification && (
                <Margins xs={[null, "base_x2", null, null]}>
                  <Notification
                    onClose={() => props.onCloseNotification()}
                    variant={NOTIFICATION_TYPES.SUCCESS}
                    autoHide={false}
                  >
                    {props.notification}
                  </Notification>
                </Margins>
              )}

              <Margins xs={[null, "base_x2", null, null]}>
                <Input
                  {...sharedInputProps}
                  name={FIELDS.USERNAME}
                  onChange={formikProps.handleChange}
                  value={formikProps.values[FIELDS.USERNAME]}
                  id={FIELDS.USERNAME}
                  label={props.translations.emailLabelText}
                />
              </Margins>
              <Input
                {...sharedInputProps}
                name={FIELDS.PASSWORD}
                onChange={formikProps.handleChange}
                value={formikProps.values[FIELDS.PASSWORD]}
                id={FIELDS.PASSWORD}
                type={"password"}
                label={props.translations.passwordLabelText}
              />
              <Margins xs={["base", "half", null, null]}>
                <LoginOptions>
                  {!props.isMobileFullScreen && (
                    <Checkbox
                      label={props.translations.rememberMeCheckboxLabel}
                      onChange={() => setRememberMe(!rememberMe)}
                      name={"rememberMe"}
                      checked={rememberMe}
                      testId={LOGIN.REMEMBER_ME_CHECKBOX}
                    />
                  )}
                </LoginOptions>
              </Margins>
              <ButtonsWrapper>
                <TestWrapper testId={LOGIN.CTA}>
                  <Button
                    size={"large"}
                    variant={"green"}
                    icon={<Arrow variant="line" color={theme.palette.white} />}
                    iconAnimation="arrowAnimation"
                  >
                    {props.translations.loginButtonText}
                  </Button>
                </TestWrapper>
                <TestWrapper testId={LOGIN.FORGOT_PASSWORD}>
                  <ForgotPasswordButton
                    size={"large"}
                    variant={"borderBlue"}
                    onClick={e => {
                      e.preventDefault();
                      setShowForgotPassword(!showForgotPassword);
                    }}
                  >
                    {props.translations.forgotPasswordLinkText}
                  </ForgotPasswordButton>
                </TestWrapper>
              </ButtonsWrapper>

              <Separator>
                <TestWrapper testId={LOGIN.SEPARATOR}>
                  <SeparatorText>
                    {props.translations.loginSeparatorText}
                  </SeparatorText>
                </TestWrapper>
              </Separator>

              <Margins xs={[null, "base_x2", null, null]}>
                <div>
                  <GoogleLogin
                    variant={"button"}
                    onSuccess={resp => {
                      props.loginUserByAccessToken(
                        OAUTH_PROVIDERS.GOOGLE,
                        resp.access_token,
                        {
                          firstName: "",
                          lastName: "",
                          email: "",
                        },
                      );
                    }}
                    onError={() =>
                      props.onSetErrors(props.translations.loginFailedText)
                    }
                    translations={{
                      buttonText: props.translations.continueWithGoogleText,
                    }}
                  />
                </div>
              </Margins>

              {isAppleLoginActive && (
                <Margins xs={[null, "base_x2", null, null]}>
                  <div>
                    <AppleLogin
                      variant={"button"}
                      onSuccess={resp => {
                        props.loginUserByAccessToken(
                          OAUTH_PROVIDERS.APPLE,
                          resp.authorization.id_token,
                          {
                            firstName: resp.user?.name.firstName || "",
                            lastName: resp.user?.name.lastName || "",
                            email: resp.user?.email || "",
                          },
                        );
                      }}
                      onError={() => {
                        props.onSetErrors(props.translations.loginFailedText);
                      }}
                      translations={{
                        buttonText: props.translations.continueWithAppleText,
                      }}
                    />
                  </div>
                </Margins>
              )}
            </Form>
          </TestWrapper>
        )}
      </Formik>
    </FadeInContainer>
  );
};

export { ModalContentLogin };

const LoginOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ForgotPasswordButton = styled(Button)`
  margin-top: ${props => props.theme.margins.base_x2};
`;

const Separator = styled.div`
  display: block;
  position: relative;
  margin: ${props => `${props.theme.margins.base_x3} 0`};
  text-align: center;
  color: ${props => props.theme.palette.grey400};
  font-size: ${props => props.theme.fonts.sizes.overline};

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: ${rem(1)} solid ${props => props.theme.palette.grey100};
    display: block;
    top: 50%;
    left: 0;
    transform: translateY(-${rem(0.5)});
  }
`;

const SeparatorText = styled.span`
  display: inline-block;
  position: relative;
  padding: 0 ${props => props.theme.margins.base_x2};
  background: ${props => props.theme.palette.white};
  z-index: 1;
`;

const SocialAccountLoginButtons = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin: 0 ${props => props.theme.margins.base};
  }
`;
