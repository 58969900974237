import {
  AVAILABLE_COUNTRIES,
  AVAILABLE_CURRENCIES,
  AVAILABLE_LANGS,
} from "@typings/globals";

export const IS_BROWSER: boolean = typeof window !== "undefined";
export const IS_DEV: boolean = process.env.NODE_ENV === "development";
export const IS_INTERNAL_ENV: boolean =
  process.env.IS_INTERNAL_ENV === "true" ||
  process.env.NODE_ENV === "development";
export const IS_PROD: boolean = process.env.NODE_ENV === "production";
export const IS_TEST: boolean = process.env.NODE_ENV === "test";
export const REDUX_STATE_KEY: string = "__INITIAL_STATE__";

export const CLEAR_TEST: boolean = process.env.CLEAR_TEST === "true";

export const TITLE_TEMPLATE_BATMAID: string = "%s | Batmaid";

export const DEFAULT_LANGUAGE: string = "en";

export const POSSIBLE_LANGUAGE_OPTIONS: string[] =
  Object.values(AVAILABLE_LANGS);

export const SCROLL_BOOKING_DURATION = "scroll-booking-duration";
export const SCROLL_ELEMENT_SLIDER = "scroll-element-slider";

export const PAYMENT_RESULT_TOAST_ID = "payment-result";

export const IOS_APP_LINK = "https://apps.apple.com/en/app/id1527257152";
export const ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.batmaid.batmaid&referrer=utm_source%3Dbatmaid-client%26utm_medium%3Dbanner";

// **************************
// *** API ***
// **************************
export const API_ENDPOINT: string = process.env.API_ENDPOINT!;
export const HR_API_ENDPOINT: string = process.env.HR_API_ENDPOINT!;

// **************************
// *** Hosts ***
// **************************
export const BATMAID_HOST: string = process.env.BATMAID_HOST!;
export const BATSOFT_HOST: string = process.env.BATSOFT_HOST!;
export const BATWORK_HOST: string = process.env.BATWORK_HOST!;

// **************************
// *** Numerals ***
// **************************
export const CALLS_RETRY: number = 5;
export const CALLS_RETRY_TIMEOUT: number = 6000;
export const DESTROY_NOTIFICATIONS_AFTER_LONG: number = 5000;
export const HIDE_NOTIFICATION_AFTER_XL_LONG: number = 10000;
export const HIDE_MODAL_AFTER: number = 2500;
export const DESTROY_MODAL_AFTER: number = 500;
export const DEFAULT_DEBOUNCE: number = 250;
export const LONG_DEBOUNCE: number = 500;
export const SHORT_DEBOUNCE: number = 100;
export const SCROLL_ELEMENT_OFFSET = -50;
export const SCROLL_ELEMENT_OFFSET_LARGE = -150;
export const HIDE_TOOLTIP_AFTER = 3000;

export const AUTO_REFRESH_WINDOW_AFTER = 900000; // equals to 15 min.

export const LOADABLE_COMPONENT_DELAY: number = 300;
export const PAYMENT_WAITING_TIMEOUT: number = 20;
export const PAYMENT_WAITING_TIMEOUT_DEV: number = 60;
export const ERROR_SCROLLING_TIMEOUT: number = 250;

export const EOT_SQUARE_METERS_MAX_LIMIT: number = 1000;
export const EOT_SQUARE_METERS_MIN_LIMIT: number = 10;
export const EOT_SQUARE_METERS_WITH_ALL_EXTRAS_SELECTED_LIMIT: number = 35;
export const EOT_SQUARE_METERS_ADVANCED_CLEANING_LIMIT: number = 150;
export const B2B_SQUARE_METERS_MAX_LIMIT: number = 1000;

/**
 * @deprecated - use `IS_OVERLAY_OPEN_CLASS_NAME` instead
 */
export const IS_MODAL_OPEN_CLASS_NAME = "is-modal-open";
export const IS_OVERLAY_OPEN_CLASS_NAME = "is-overlay-open";

export const SERVICE_VOUCHER_LENGTH = 12;

export const MAX_FILES_SIZE_MB = 50;
export const MAX_FILES_SIZE_BYTES = 52428800;
export const MAX_FILE_SIZE_BYTES = 31457280;

export const FETCH_PAYMENT_STATUS_INTERVAL = 1000;

// This is a safe value for a mobile height border to detect if keyboard or url bar was open/shown.
// Chrome URL bar has 56px height. Android keyboard around 150~200.
export const MOBILE_HEIGHT_BORDER: number = 100;

export const B2B_MIN_SURFACE_AREA = 15;

export const MINIMUM_HEIGHT_TO_SHOW_MOBILE_BOOKING_SUMMARY: number = 400;

export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_ENV = process.env.SENTRY_ENV;
export const SENTRY_ENABLED_PWA = process.env.SENTRY_ENABLED_PWA === "true";

export const IS_DATATRANS_ENV_PROD = process.env.DATATRANS_ENV === "production";

// **************************
// *** Social Media ***
// **************************
export const BATMAID_GOOGLE_LINK = "https://goo.gl/maps/P5rR9mh3HX7dT56x8";
export const BATMAID_TRUSTPILOT_LINK =
  "https://www.trustpilot.com/review/batmaid.ch";

export const BATMAID_FACEBOOK_LINKS = new Map<
  AVAILABLE_COUNTRIES | "DEFAULT",
  string
>([
  ["DEFAULT", "https://www.facebook.com/batmaid"],
  [
    AVAILABLE_COUNTRIES.DE,
    "https://www.facebook.com/Batmaidde-105650638415232",
  ],
]);

export const BATMAID_LINKEDIN_LINK =
  "https://www.linkedin.com/company/batmaid/";

export const BATMAID_INSTAGRAM_LINKS = new Map<
  AVAILABLE_COUNTRIES | "DEFAULT",
  string
>([
  ["DEFAULT", "https://instagram.com/batmaid/"],
  [AVAILABLE_COUNTRIES.DE, "https://instagram.com/batmaid_de/"],
]);

export const BATMAID_TWITTER_LINK = "https://twitter.com/BatmaidCom";
export const BATMAID_YOUTUBE_LINK =
  "https://www.youtube.com/channel/UCAwAC4lRJNolu0uc17pNbwg";

export const GENERIC_FACEBOOK_REFERRAL =
  "https://www.facebook.com/sharer/sharer.php?u={urlValue}";
export const GENERIC_TWITTER_REFERRAL =
  "https://twitter.com/intent/tweet?url={urlValue}";
export const GENERIC_LINKEDIN_REFERRAL =
  "https://www.linkedin.com/sharing/share-offsite/?url={urlValue}";

// **************************
// *** OAuth ***
// **************************
export const GOOGLE_LOGIN_CLIENT_ID = process.env.GOOGLE_LOGIN_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID;
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
export const APPLE_LOGIN_CLIENT_ID = process.env.APPLE_LOGIN_CLIENT_ID;

export const CORONAVIRUS_PANIC_MODE_ENABLED =
  process.env.CORONAVIRUS_PANIC_MODE_ENABLED === "true";

export const CORONAVIRUS_PANIC_MODE_LU_ENABLED = (
  country: AVAILABLE_COUNTRIES,
) =>
  country === AVAILABLE_COUNTRIES.LU &&
  process.env.CORONAVIRUS_PANIC_MODE_LU_ENABLED === "true";

export const CHRISTMAS_MODE = process.env.CHRISTMAS_MODE === "true";

export const VOUCHER_FROM_DEEPLINK_KEY = "VOUCHER_FROM_DEEPLINK";
export const VOUCHER_USED_KEY = "VOUCHER_USED_KEY";

export const CAPTCHA_SITE_KEY = process.env.CAPTCHA_SITE_KEY!;

export const B2B_ENABLED = process.env.B2B_ENABLED === "true";
export const B2B_SCHEDULE_A_CALL_ENABLED =
  process.env.B2B_SCHEDULE_A_CALL_ENABLED === "true";

export const CUSTOM_DIALING_CODES = [
  { key: "CH", code: "+41" },
  { key: "LU", code: "+352" },
  { key: "PT", code: "+351" },
  { key: "ES", code: "+34" },
  { key: "IT", code: "+39" },
  { key: "FR", code: "+33" },
  { key: "DE", code: "+49" },
  { key: "PL", code: "+48" },
  { key: "AT", code: "+43" },
  { key: "BE", code: "+32" },
  { key: "NL", code: "+31" },
  { key: "GB", code: "+44" }, // GB is used instead of UK intentionally
  { key: "US", code: "+1" },
];

export const ZIP_CODE_PATTERN_BY_COUNTRY = {
  [AVAILABLE_COUNTRIES.CH]: /\d{4}/,
  [AVAILABLE_COUNTRIES.LU]: /(L-|l-)?\d{4}/,
  [AVAILABLE_COUNTRIES.DE]: /.{5,}/,
  [AVAILABLE_COUNTRIES.FR]: /.{5,}/,
  [AVAILABLE_COUNTRIES.PL]: /\d{2}-\d{3}/,
  [AVAILABLE_COUNTRIES.IT]: /.{5,}/,
  [AVAILABLE_COUNTRIES.AT]: /\d{4}/,
  [AVAILABLE_COUNTRIES.BE]: /\d{4}/,
  [AVAILABLE_COUNTRIES.NL]: /\d{4}/,
  [AVAILABLE_COUNTRIES.UK]: /[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}/,
  [AVAILABLE_COUNTRIES.US]: /\d{5}/,
};

export const LANGS_BY_COUNTRY = {
  [AVAILABLE_COUNTRIES.CH]: [
    AVAILABLE_LANGS.EN,
    AVAILABLE_LANGS.DE,
    AVAILABLE_LANGS.FR,
    AVAILABLE_LANGS.IT,
  ],
  [AVAILABLE_COUNTRIES.LU]: [
    AVAILABLE_LANGS.DE,
    AVAILABLE_LANGS.EN,
    AVAILABLE_LANGS.FR,
  ],
  [AVAILABLE_COUNTRIES.FR]: [AVAILABLE_LANGS.FR, AVAILABLE_LANGS.EN],
  [AVAILABLE_COUNTRIES.DE]: [AVAILABLE_LANGS.DE, AVAILABLE_LANGS.EN],
  [AVAILABLE_COUNTRIES.PL]: [AVAILABLE_LANGS.PL, AVAILABLE_LANGS.EN],
  [AVAILABLE_COUNTRIES.IT]: [AVAILABLE_LANGS.IT, AVAILABLE_LANGS.EN],
  [AVAILABLE_COUNTRIES.AT]: [AVAILABLE_LANGS.DE, AVAILABLE_LANGS.EN],
  [AVAILABLE_COUNTRIES.BE]: [
    AVAILABLE_LANGS.NL,
    AVAILABLE_LANGS.EN,
    AVAILABLE_LANGS.FR,
  ],
  [AVAILABLE_COUNTRIES.NL]: [AVAILABLE_LANGS.NL, AVAILABLE_LANGS.EN],
  [AVAILABLE_COUNTRIES.UK]: [AVAILABLE_LANGS.EN],
  [AVAILABLE_COUNTRIES.US]: [AVAILABLE_LANGS.EN],
};

/**
 * Language names in native languages, e.g. Polski for PL, English for EN
 */
export const LANG_NAMES_BY_APP_LANG = {
  [AVAILABLE_LANGS.EN]: "English",
  [AVAILABLE_LANGS.FR]: "Français",
  [AVAILABLE_LANGS.DE]: "Deutsch",
  [AVAILABLE_LANGS.IT]: "Italiano",
  [AVAILABLE_LANGS.PL]: "Polski",
  [AVAILABLE_LANGS.NL]: "Dutch",
};

/**
 * Country name by language
 */
export const COUNTRY_NAME_BY_LANGUAGE = new Map<
  AVAILABLE_COUNTRIES,
  Record<string, string>
>([
  [
    AVAILABLE_COUNTRIES.CH,
    {
      [AVAILABLE_LANGS.EN]: "Switzerland",
      [AVAILABLE_LANGS.FR]: "Suisse",
      [AVAILABLE_LANGS.DE]: "Schweiz",
      [AVAILABLE_LANGS.IT]: "Swizzera",
    },
  ],
  [
    AVAILABLE_COUNTRIES.FR,
    {
      [AVAILABLE_LANGS.EN]: "France",
      [AVAILABLE_LANGS.FR]: "Français",
    },
  ],
  [
    AVAILABLE_COUNTRIES.DE,
    {
      [AVAILABLE_LANGS.EN]: "Germany",
      [AVAILABLE_LANGS.DE]: "Deutschland",
    },
  ],
  [
    AVAILABLE_COUNTRIES.IT,
    {
      [AVAILABLE_LANGS.EN]: "Italy",
      [AVAILABLE_LANGS.IT]: "Italia",
    },
  ],
  [
    AVAILABLE_COUNTRIES.NL,
    {
      [AVAILABLE_LANGS.EN]: "Netherlands",
      [AVAILABLE_LANGS.NL]: "Nederland",
    },
  ],
  [
    AVAILABLE_COUNTRIES.LU,
    {
      [AVAILABLE_LANGS.EN]: "Luxembourg",
      [AVAILABLE_LANGS.FR]: "Luxembourg",
      [AVAILABLE_LANGS.DE]: "Luxembourg",
    },
  ],
  [
    AVAILABLE_COUNTRIES.BE,
    {
      [AVAILABLE_LANGS.NL]: "België",
      [AVAILABLE_LANGS.FR]: "Belgique",
      [AVAILABLE_LANGS.EN]: "Belgium",
      [AVAILABLE_LANGS.DE]: "Belgien",
    },
  ],
  [
    AVAILABLE_COUNTRIES.PL,
    {
      [AVAILABLE_LANGS.EN]: "Poland",
      [AVAILABLE_LANGS.PL]: "Polska",
    },
  ],
]);

export const CURRENCY_PER_COUNTRY = {
  [AVAILABLE_COUNTRIES.CH]: AVAILABLE_CURRENCIES.CHF,
  [AVAILABLE_COUNTRIES.LU]: AVAILABLE_CURRENCIES.EUR,
  [AVAILABLE_COUNTRIES.FR]: AVAILABLE_CURRENCIES.EUR,
  [AVAILABLE_COUNTRIES.PL]: AVAILABLE_CURRENCIES.PLN,
  [AVAILABLE_COUNTRIES.IT]: AVAILABLE_CURRENCIES.EUR,
  [AVAILABLE_COUNTRIES.DE]: AVAILABLE_CURRENCIES.EUR,
  [AVAILABLE_COUNTRIES.AT]: AVAILABLE_CURRENCIES.EUR,
  [AVAILABLE_COUNTRIES.BE]: AVAILABLE_CURRENCIES.EUR,
  [AVAILABLE_COUNTRIES.NL]: AVAILABLE_CURRENCIES.EUR,
  [AVAILABLE_COUNTRIES.UK]: AVAILABLE_CURRENCIES.GBP,
  [AVAILABLE_COUNTRIES.US]: AVAILABLE_CURRENCIES.USD,
};

// Countries that uses `.-`
export const COUNTRIES_THAT_USE_PRICE_SIGN = [
  AVAILABLE_COUNTRIES.CH,
  AVAILABLE_COUNTRIES.LU,
];

export const EXCLUDED_COUNTRIES = [
  ...(process.env.BATMAID_PL_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.PL]
    : []),
  ...(process.env.BATMAID_DE_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.DE]
    : []),
  ...(process.env.BATMAID_IT_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.IT]
    : []),
  ...(process.env.BATMAID_AT_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.AT]
    : []),
  ...(process.env.BATMAID_BE_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.BE]
    : []),
  ...(process.env.BATMAID_NL_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.NL]
    : []),
  ...(process.env.BATMAID_UK_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.UK]
    : []),
  ...(process.env.BATMAID_US_ENABLED === "false"
    ? [AVAILABLE_COUNTRIES.US]
    : []),
];

export const COUNTRIES_WITH_INVOICES_TIP = [AVAILABLE_COUNTRIES.PL];

export const COUNTRY_LOCAL_STORAGE_KEY = "twa:country";
export const LANGUAGE_LOCAL_STORAGE_KEY = "twa:language";
export const OPENED_JOB_MODALS_STORAGE_KEY = "openedJobModals";
export const FEATURE_TOGGLES_LOCAL_STORAGE_KEY = "featureToggles";

export const HAS_USER_LOGGED_OUT = "hasUserLoggedOut";

export const COUNTRY_BY_INSEE_CODE = {
  FRANCE: "99100",
};

// **************************
// *** Google Tag Manager ***
// **************************
// GTM Events
export const GTM_EVENT_BATMAID_PAGE_VISIT = "batmaid-pagevisit";
export const GTM_EVENT_BATMAID_INPUT = "batmaid-input";
export const GTM_EVENT_BATMAID_PAYMENT = "batmaid-payment";
export const GTM_EVENT_BATMAID_LOGIN_STATE_CHANGED =
  "batmaid-login-state-changed";
export const GTM_EVENT_MY_CLEANINGS_RATE_A_JOB = "ga-my-cleanings-rate-a-job";

export const GTM_EVENT_COOKIES_DECLINED = "cookies-declined";

export const GTM_EVENT_BATMAID_TRACKING = "batmaid-tracking-event";

// GTM Variables
export const GTM_VAR_PAGE_BUY = "buy";
export const GTM_VAR_PAGE_BOOKING = "booking";
export const GTM_VAR_PAGE_CHECKOUT = "checkout";
export const GTM_VAR_PAGE_APPLICATION_FORM = "application-form";
export const GTM_VAR_PAGE_APPLICATION_FORM_THANK_YOU =
  "application-form-thank-you";
export const GTM_VAR_PAGE_THANK_YOU = "thank-you";

export const GTM_VAR_PAGE_CONTRACT_CANCELLATION_FINAL_CHOICE =
  "contract_cancellation_final_choice";

export const GTM_VAR_PAGE_TERMINATE_CONTRACT_FIRST_STEP =
  "terminate_contract_first_step";

export const GTM_VAR_PRODUCT_HOME_CLEANING = "homecleaning";
export const GTM_VAR_PRODUCT_END_OF_TENANCY = "endoftenancy";
export const GTM_VAR_PRODUCT_B2B_CLEANING = "b2bcleaning";
export const GTM_VAR_PRODUCT_GIFT_CARDS = "giftcards";

export const GTM_VAR_FIELD_EXECUTION_DATE_TIME = "executionDateTime";
export const GTM_VAR_FIELD_PHONE_NUMBER = "phoneNumber";

export const GTM_VAR_PAYMENT_STATUS_FAILED = "failed";

export const GTM_VAR_PURCHASE_VALUE = "purchaseValue";

export const GTM_VAR_USER_CUSTOMER = "customer";
export const GTM_VAR_USER_OPS = "ops_team";

export const GTM_VAR_EDIT_CLEANING = "edit_cleaning";
export const GTM_VAR_CONTACT_SUPPORT = "contact_support";
export const GTM_VAR_CANCEL_MY_CONTRACT = "cancel_my_contract";

// **************************************
// **** Fixed elements space keepers ****
// **************************************
export const BOOKING_SUMMARY_SPACE_KEEPER_HEIGHT = 120;
export const NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT = 72;
export const MOBILE_TOAST_OFFSET = 64;
export const BOTTOM_NAVIGATION_SPACE_KEEPER_HEIGHT = 59;
export const BOTTOM_SHEET_COLLAPSED_HEIGHT = 112;

export const VALIDATE_FIELD_LIMIT_NORMAL = 50;
export const VALIDATE_FIELD_LIMIT_LONG = 100;
export const VALIDATE_FIELD_LIMIT_MAX = 255;
export const VALIDATE_FILED_PASSWORD_MIN_LENGTH = 6;
export const VALIDATE_FILED_IBAN_LENGTH = 34;
export const VALIDATE_FIELD_TEXTAREA_LIMIT_LONG = 512;
export const VALIDATE_SDI_CODE_FIELD_LIMIT = 7;

// *******************************************************
// ************ Cleaning Duration Matrix  ****************
// ******* columns - bedroom | rows - bathroom ***********
// *******************************************************
export const DEFAULT_CLEANING_DURATION_MATRIX = [
  [2, 2, 3, 3, 4, 5, 5, 6, 6, 7, 7],
  [2, 2, 3, 3, 4, 5, 6, 7, 7, 7, 8],
  [2, 3, 3.5, 4, 4.5, 5, 6, 7, 7, 8, 8],
  [3, 3, 3.5, 4, 5, 5, 6, 7, 8, 8, 9],
  [3, 3, 4, 4.5, 5, 6, 7, 8, 8, 9, 9],
  [4, 4, 4.5, 4.5, 5.5, 6, 7, 8, 9, 9, 9],
  [4, 4, 4.5, 4.5, 5.5, 7, 7, 9, 9, 9, 10],
  [5, 5, 5, 5, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 5, 5, 6, 8, 8, 9, 10, 10, 10],
  [5, 5, 5, 6, 7, 8, 9, 10, 10, 10, 10],
  [5, 5, 5, 6, 7, 9, 9, 10, 10, 10, 10],
];

export const CLEANING_DURATION_MATRIX_PL = [
  [2, 2, 3, 3.5, 4, 5, 6, 7, 7, 8, 10],
  [2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 10],
  [3, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10],
  [3.5, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10, 10],
  [3.5, 4, 4.5, 5, 6, 6, 7, 8, 9, 10, 10],
  [4, 4.5, 5, 5, 6, 6, 7, 8, 9, 10, 10],
  [4.5, 4.5, 5, 5, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 5, 5.5, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 5, 6, 7, 8, 8, 9, 10, 10, 10],
  [5.5, 5.5, 5.5, 6, 7, 8, 9, 10, 10, 10, 10],
  [5.5, 5.5, 6, 7, 8, 9, 9, 10, 10, 10, 10],
];

// copy of default matrix, but min duration is 3 hours
export const CLEANING_DURATION_MATRIX_NL = [
  [3, 3, 3, 3, 4, 5, 5, 6, 6, 7, 7],
  [3, 3, 3, 3, 4, 5, 6, 7, 7, 7, 8],
  [3, 3, 3.5, 4, 4.5, 5, 6, 7, 7, 8, 8],
  [3, 3, 3.5, 4, 5, 5, 6, 7, 8, 8, 9],
  [3, 3, 4, 4.5, 5, 6, 7, 8, 8, 9, 9],
  [4, 4, 4.5, 4.5, 5.5, 6, 7, 8, 9, 9, 9],
  [4, 4, 4.5, 4.5, 5.5, 7, 7, 9, 9, 9, 10],
  [5, 5, 5, 5, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 5, 5, 6, 8, 8, 9, 10, 10, 10],
  [5, 5, 5, 6, 7, 8, 9, 10, 10, 10, 10],
  [5, 5, 5, 6, 7, 9, 9, 10, 10, 10, 10],
];

export const CLEANING_DURATION_MATRIX_BE = [
  [2, 2, 3, 3.5, 4, 5, 6, 7, 7, 8, 10],
  [2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 10],
  [3, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10],
  [3.5, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10, 10],
  [3.5, 4, 4.5, 5, 6, 6, 7, 8, 9, 10, 10],
  [4, 4.5, 5, 5, 6, 6, 7, 8, 9, 10, 10],
  [4.5, 4.5, 5, 5, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 5, 5.5, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 5, 6, 7, 8, 8, 9, 10, 10, 10],
  [5.5, 5.5, 5.5, 6, 7, 8, 9, 10, 10, 10, 10],
  [5.5, 5.5, 6, 7, 8, 9, 9, 10, 10, 10, 10],
];

// min 3 hours and no .5 - service vouchers
export const CLEANING_DURATION_MATRIX_BE_SERVICE_VOUCHERS = [
  [3, 3, 3, 3, 4, 5, 6, 7, 7, 8, 10],
  [3, 3, 3, 3, 4, 5, 6, 7, 8, 9, 10],
  [3, 3, 4, 4, 5, 5, 6, 7, 8, 9, 10],
  [3, 4, 4, 4, 5, 6, 7, 8, 9, 10, 10],
  [4, 4, 5, 5, 6, 6, 7, 8, 9, 10, 10],
  [4, 5, 5, 5, 6, 6, 7, 8, 9, 10, 10],
  [5, 5, 5, 5, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 5, 6, 6, 7, 8, 9, 10, 10, 10],
  [5, 5, 6, 6, 7, 8, 8, 9, 10, 10, 10],
  [5, 6, 6, 6, 7, 8, 9, 10, 10, 10, 10],
  [6, 6, 6, 7, 8, 9, 9, 10, 10, 10, 10],
];

// duration bathroom bedroom
export const DEFAULT_ROOMS_RECOMMENDATION_NUMBER_MATRIX = [
  [2, 1, 1],
  [2.5, 1, 2],
  [3, 2, 1],
  [3.5, 2, 2],
  [4, 3, 1],
  [4.5, 3, 2],
  [5, 4, 1],
  [5.5, 4, 2],
  [6, 5, 1],
];

export const IMAGE_UPLOAD_BYTES_LIMIT = 4000000;
export const IMAGE_MAX_RESOLUTION = 4096;

export const AGENT_CANNOT_MAKE_IT_LIST_KEY = "AGENT_CANNOT_MAKE_IT_LIST_KEY";

export const NEXT_DATE_TO_SHOW_BANNER_KEY = "NEXT_DATE_TO_SHOW_BANNER";
export const NEXT_DATE_TO_SHOW_CLEANING_CHECKLIST_ALERT_KEY =
  "NEXT_DATE_TO_SHOW__CLEANING_CHECKLIST_ALERT";

export const SESSION_UTM_PARAMETERS_KEY = "SESSION_UTM_PARAMETERS";

export const BODY_WHITE_BG_CLASS = "is-white-bg";
export const BODY_FOCUS_MODE_CLASS = "is-focus-mode";
