import * as React from "react";

import {
  BreakpointStateV1,
  BreakpointStateV2,
  OptsState,
} from "./useBreakpoint.types";
import { BreakpointContext } from "./useBreakpoint.context";

/**
 * @deprecated version `v1` is deprecated. Use version `v2` instead.
 */
export function useBreakpoint(version: "v1"): BreakpointStateV1 & OptsState;
export function useBreakpoint(version?: "v2"): BreakpointStateV2 & OptsState;
export function useBreakpoint(version: "v1" | "v2" = "v2") {
  const context = React.useContext(BreakpointContext);

  if (
    (version === "v1" && !context?.v1) ||
    (version !== "v1" && !context?.v2)
  ) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }

  const { v1, v2, isBreakpointResolved } = context;

  if (version === "v1") {
    return { ...v1, isBreakpointResolved };
  }

  return { ...v2, isBreakpointResolved };
}
