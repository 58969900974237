import { IBreakpoints } from "./BreakpointsInterface";

const breakpoints: IBreakpoints = {
  mobile: "0px",
  tablet: "768px",
  desktop: "1280px",
};

/**
 * Function that generates media queries based on provided value(s).
 * @param {Array<string|number> | string | number} input
 * @param {IBreakpoints} bp
 * @param {boolean} withMediaPrefix
 */
export default function mq(
  input: (string | number)[] | string = "0px",
  bp: IBreakpoints = breakpoints,
  withMediaPrefix = true,
) {
  if (Array.isArray(input) && input.length > 2) {
    throw new Error("Please provide max two (min and max) values!");
  }

  function px(input: number | string): string | undefined {
    if (typeof input === "number") {
      return `${input}px`;
    }

    if (typeof input === "undefined") {
      return undefined;
    }

    if (isNaN(parseFloat(input))) {
      throw new Error("Please provide proper value!");
    }

    return `${parseFloat(input)}px`;
  }

  const min: string = Array.isArray(input)
    ? bp[input[0]] || px(input[0]) || "0px"
    : px(input) || "0px";

  const max: string | undefined = Array.isArray(input)
    ? bp[input[1]] || px(input[1])
    : undefined;

  let query = "";

  if (withMediaPrefix) {
    query += "@media ";
  }

  query += `(min-width: ${min})`;

  if (max) {
    query += ` and (max-width: ${max})`;
  }

  return query;
}
