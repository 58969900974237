/**
 * @author michalstolarski
 * @since 2022-3-31
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { MODAL } from "@config/testIds/modal";

import { rgba } from "@ui/helpers";
import { fadeIn, fadeOut } from "@ui/animations";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { Portal } from "@containers/Portal";

import { useBackdropLockScroll } from "@hooks/useBackdropLockScroll";

interface Props {
  testId?: string;
  className?: string;
  children?: React.ReactNode;
  onClick: () => void;

  visible?: boolean;
  hideWithCss?: boolean; // Use only for SEO
}

const Backdrop = (props: Props): React.ReactElement => {
  useBackdropLockScroll(!props.hideWithCss);

  return (
    <Portal>
      <TestWrapper testId={props.testId}>
        <Wrapper className={props.className} hideWithCss={props.hideWithCss}>
          <TestWrapper testId={MODAL.BACKGROUND}>
            <Background onClick={props.onClick} visible={props.visible} />
          </TestWrapper>

          {props.children}
        </Wrapper>
      </TestWrapper>
    </Portal>
  );
};

export { Backdrop };

const Wrapper = styled.div<{ hideWithCss?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.hideWithCss ? "hidden" : "visible")};
  z-index: ${props => props.theme.zIndex.modal};
`;

const Background = styled.div<{ visible?: boolean }>`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => rgba(props.theme.palette.grey700, 0.35)};
  cursor: pointer;

  ${props =>
    props.visible !== undefined &&
    css`
      animation: ${props.visible ? fadeIn : fadeOut}
        ${props.theme.animations.duration} ${props.theme.animations.easing}
        forwards;
    `}
`;
