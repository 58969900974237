import React from "react";

import { IS_BROWSER, IS_OVERLAY_OPEN_CLASS_NAME } from "@config/consts";

import { DialogManager } from "@features/DialogManager";
import { DrawerManager } from "@features/DrawerManager";

export const OverlayLockScroll: React.FC = (): null => {
  const dialogManager = DialogManager.useManager();
  const drawerManager = DrawerManager.useManager();

  const numberOfActiveOverlays =
    dialogManager.getActiveDialogs().length ||
    drawerManager.getActiveDrawers().length;

  React.useEffect(() => {
    if (!IS_BROWSER) return;

    const $body = document.body;
    const classExists = $body.classList.contains(IS_OVERLAY_OPEN_CLASS_NAME);

    if (!!numberOfActiveOverlays && !classExists) {
      const offsetY = window.scrollY;
      const scrollbarWidth = window.innerWidth - $body.clientWidth;

      $body.classList.add(IS_OVERLAY_OPEN_CLASS_NAME);
      $body.style.top = `-${offsetY}px`;

      // Add padding to prevent content from shifting when scrollbar is hidden
      if (scrollbarWidth) {
        $body.style.paddingRight = `calc(${window
          .getComputedStyle($body, null)
          .getPropertyValue("padding-right")} + ${scrollbarWidth}px)`;
      }

      $body.scrollTo(0, offsetY);

      return;
    }

    if (!numberOfActiveOverlays && classExists) {
      const offsetY = Math.abs(parseInt($body.style.top || "0", 10)) || 0;

      $body.style.top = "";
      $body.style.paddingRight = "";
      $body.classList.remove(IS_OVERLAY_OPEN_CLASS_NAME);
      window.scrollTo(0, offsetY);
    }
  }, [numberOfActiveOverlays]);

  return null;
};
