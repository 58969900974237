import designTokens from "@batmaid/design-tokens";

import { ITheme, DesignSystemBreakpoints } from "@ui/themes/ThemeInterface";
import mq from "@ui/helpers/mq";
import rem from "@ui/helpers/rem";

export const breakpointsValues = {
  mobileSmall: 360,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  desktopLarge: 1200,
};

export const breakpointsValuesV2 = {
  largeMobile: 624,
  smallTablet: 828,
  tablet: 1080,
  smallDesktop: 1272,
  desktop: 1416,
  largeDesktop: 1920,
};

export const mediaQueries: Record<keyof typeof breakpointsValues, string> = {
  mobileSmall: `(max-width: ${breakpointsValues.mobileSmall}px)`,
  mobile: `(max-width: ${breakpointsValues.tablet - 1}px)`,
  tablet: `(min-width: ${breakpointsValues.tablet}px) and (max-width: ${
    breakpointsValues.desktop - 1
  }px)`,
  desktop: `(min-width: ${breakpointsValues.desktop}px) and (max-width: ${
    breakpointsValues.desktopLarge - 1
  }px)`,
  desktopLarge: `(min-width: ${breakpointsValues.desktopLarge}px)`,
};

export const mediaQueriesV2: Record<
  | keyof Pick<
      DesignSystemBreakpoints,
      | "largeMobile"
      | "smallTablet"
      | "tablet"
      | "smallDesktop"
      | "desktop"
      | "largeDesktop"
    >
  | "mobile",
  string
> = {
  mobile: mq([0, breakpointsValuesV2.largeMobile - 1], undefined, false),
  largeMobile: mq(
    [breakpointsValuesV2.largeMobile, breakpointsValuesV2.smallTablet - 1],
    undefined,
    false,
  ),
  smallTablet: mq(
    [breakpointsValuesV2.smallTablet, breakpointsValuesV2.tablet - 1],
    undefined,
    false,
  ),
  tablet: mq(
    [breakpointsValuesV2.tablet, breakpointsValuesV2.smallDesktop - 1],
    undefined,
    false,
  ),
  smallDesktop: mq(
    [breakpointsValuesV2.smallDesktop, breakpointsValuesV2.desktop - 1],
    undefined,
    false,
  ),
  desktop: mq(
    [breakpointsValuesV2.desktop, breakpointsValuesV2.largeDesktop - 1],
    undefined,
    false,
  ),
  largeDesktop: mq([breakpointsValuesV2.largeDesktop], undefined, false),
};

const theme: ITheme = {
  animations: {
    durationShort: "50ms",
    duration: "150ms",
    durationLong: "350ms",
    easing: "ease-in-out",
  },
  /**
   * @deprecated please use the breakpointsV2 instead
   */
  breakpoints: {
    belowMobileSmall: mq([0, String(breakpointsValues.mobileSmall - 1)]),
    mobile: mq(String(breakpointsValues.mobile)),
    upToTablet: mq([0, String(breakpointsValues.mobile)]),
    tablet: mq(String(breakpointsValues.tablet)),
    upToDesktop: mq([0, String(breakpointsValues.tablet)]),
    tabletToDesktop: mq([
      String(breakpointsValues.tablet),
      String(breakpointsValues.desktop),
    ]),
    desktop: mq(String(breakpointsValues.desktop)),
    upToDesktopLarge: mq([0, String(breakpointsValues.desktopLarge)]),
    desktopToDesktopLarge: mq([
      String(breakpointsValues.desktop),
      String(breakpointsValues.desktopLarge),
    ]),
    desktopLarge: mq(String(breakpointsValues.desktopLarge)),
  },
  breakpointsV2: {
    largeMobile: mq([breakpointsValuesV2.largeMobile]),
    smallTablet: mq([breakpointsValuesV2.smallTablet]),
    tablet: mq([breakpointsValuesV2.tablet]),
    smallDesktop: mq([breakpointsValuesV2.smallDesktop]),
    desktop: mq([breakpointsValuesV2.desktop]),
    largeDesktop: mq([breakpointsValuesV2.largeDesktop]),
  },
  orientation: {
    landscape: `@media (orientation: landscape)`,
    portrait: `@media (orientation: portrait)`,
  },
  buttons: {
    borderRadius: rem(4),
    height: rem(56),
    heightSmall: rem(33),
    lineHeight: rem(16),
    sizes: {
      pill: rem(40),
      small: rem(124),
      medium: rem(248),
      large: rem(668),
      table: "auto",
      fill: "100%",
    },
    paddings: {
      vertical: rem(16),
      horizontal: rem(16),
      xhorizontal: rem(20),
    },
  },
  border: {
    radius: {
      half: rem(4),
      base: rem(8),
      base_x1_5: rem(12),
      base_x2: rem(16),
    },
  },
  container: {
    mobile: rem(0),
    tablet: rem(720),
    desktopSmall: rem(672),
    desktop: rem(960),
    desktopLarge: rem(1168),

    calendar: rem(360),
  },
  gradients: {
    blue800_blue600:
      "linear-gradient(135deg,rgba(0, 104, 153, 1) 0%,rgba(0, 139, 190, 1) 100%)",
    lightblue_white:
      "linear-gradient(180deg, rgba(245,248,251,1) 0%, rgba(255,255,255,1) 30%);",
  },
  socialPalette: {
    facebook: "#3b5998",
    instagram: "#e1306c",
    twitter: "#1da1f2",
    googleplus: "#dd4b39",
    youtube: "#ff0000",
    google: "#c75340",
    wincasa: "#f28039",
  },
  palette: designTokens.colors.rebrand,
  spacing: designTokens.spacing,
  texts: designTokens.texts,
  fontWeights: designTokens.fontWeights,
  notifications: {
    blueBackground: "#DBF1F8",
    blueBorder: "#B8DAFF",
    blueText: "#2C5F98",

    redBackground: "#F8D7DA",
    redBorder: "#F5C6CB",
    redText: "#721C24",

    yellowBackground: "#FCF8E3",
    yellowBorder: "#FFEEBA",
    yellowText: "#856404",

    greenBackground: "#D4EDDA",
    greenBorder: "#D0E9C6",
    greenText: "#155724",
  },
  fonts: {
    default: "Fellix",
    base: 'Fellix, "Trebuchet MS", Helvetica, sans-serif',
    baseSize: "16px",
    weights: {
      light: 300,
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    sizes: {
      /** CURRENT */
      h1: rem(72),
      h2: rem(48),
      h3: rem(40),
      h4: rem(32),
      h5: rem(24),
      h6: rem(20),
      body: rem(16),
      body2: rem(14),
      subtitle: rem(16),
      subtitle2: rem(14),
      caption: rem(12),
      overline: rem(10),

      /** LEGACY */
      enlarged: rem(20), //@ToDo: should probably stay, but name needs consulting with UX team
    },
    lineHeights: {
      normal: rem(24),
      h1: rem(96),
      h2: rem(72),
      h3: rem(48),
      h4: rem(40),
      h5: rem(40),
      h6: rem(24),
      subtitle: rem(24),
      subtitle2: rem(24),
      caption: rem(16),
      overline: rem(16),
    },
    letterSpacings: {
      normal: `${rem(0.24)}`,
      h1: `-${rem(1.16)}`,
      h2: `-${rem(0.48)}`,
      h3: "normal",
      h4: rem(0.24),
      h5: "normal",
      h6: "normal",
      subtitle: rem(0.16),
      subtitle2: "normal",
      caption: rem(0.4),
      overline: rem(2),
    },
  },
  grid: {
    columns: 12,
  },
  margins: {
    none: "0",
    quarter: rem(2),
    half: rem(4),
    base: rem(8),
    base_x1_5: rem(12),
    base_x2: rem(16),
    base_x3: rem(24),
    base_x4: rem(32),
    base_x5: rem(40),
    base_x6: rem(48),
    base_x7: rem(56),
    base_x8: rem(64),
    base_x9: rem(72),
    base_x10: rem(80),
  },
  icons: {
    sizes: {
      base: 8,
      base_x1_5: 12,
      base_x2: 16,
      base_x3: 24,
      base_x4: 32,
      base_x5: 40,
      base_x6: 48,
      base_x7: 56,
      base_x8: 64,
    },
  },
  name: "Default",
  zIndex: {
    modal: 200,
    loadingIndicator: 200,
    bottomSheet: 10,
    bottomNavigation: 8,
    backdrop: 6,
    header: 5,
    corruptedSession: 2,
  },
};

export default theme;
