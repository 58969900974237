import React, { useEffect, useRef, useCallback } from "react";
import debounce from "lodash.debounce";

import { DEFAULT_DEBOUNCE, IS_BROWSER } from "@config/consts";

import { loadGoogleMapsScript } from "@services/GoogleMapsFacade";

interface Props {
  // available options can be found at: https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions
  options?: Record<string, any>;
  onPlaceSelected?: (place: google.maps.GeocoderResult) => void;
}

export const useGooglePlaces = (props: Props) => {
  const config = {
    fields: ["address_components", "place_id", "formatted_address"],
    componentRestrictions: {
      country: [],
    },
    ...props.options,
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const eventRef = useRef<any>(null);
  const autocompleteRef = useRef<any>(null);
  const observerHack = useRef<MutationObserver | null>(null);

  const handleLoadScript = useCallback(() => loadGoogleMapsScript(), []);

  useEffect(() => {
    if (autocompleteRef.current || !inputRef.current || !IS_BROWSER) {
      return;
    }

    const handleAutoComplete = () => {
      if (typeof google === "undefined") {
        return console.error(
          "Google API not found. Check if the script is in the DOM",
        );
      }

      if (!google.maps.places) {
        return console.error("Google maps places API must be loaded.");
      }

      autocompleteRef.current = new google.maps.places.Autocomplete(
        inputRef.current as HTMLInputElement,
        config,
      );

      eventRef.current = autocompleteRef.current.addListener(
        "place_changed",
        debounce(() => {
          if (props.onPlaceSelected && autocompleteRef?.current) {
            props.onPlaceSelected(autocompleteRef.current.getPlace());
          }
        }, DEFAULT_DEBOUNCE),
      );
    };

    (async () => {
      await handleLoadScript();
      handleAutoComplete();
    })();

    /**
     * This function is called by Google Maps API upon auth error & it's the only way to tap into such errors.
     * We are using it to prevent disabling the input that's connected to the Places API.
     * This is an extra safety mesure. As long as our Google Clouds config is correct, this function will never be called.
     */
    (window as any).gm_authFailure = () => {
      if (inputRef.current) {
        // reset input styling
        inputRef.current.disabled = false;
        inputRef.current.placeholder = "";
        inputRef.current.style.backgroundImage = "";

        // unbind Maps API event listeners
        google.maps.event.removeListener(eventRef.current);
        google.maps.event.clearInstanceListeners(inputRef.current);
        google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };

    return () => eventRef.current?.remove();
  }, []);

  // Browser autofill covering Google search results hack
  // https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/49161445#49161445
  useEffect(() => {
    if (
      IS_BROWSER &&
      window.MutationObserver &&
      inputRef?.current instanceof HTMLInputElement
    ) {
      observerHack.current = new MutationObserver(() => {
        observerHack?.current?.disconnect();

        if (inputRef.current) {
          inputRef.current.autocomplete = "off";
        }
      });
      observerHack.current.observe(inputRef.current, {
        attributes: true,
        attributeFilter: ["autocomplete"],
      });
    }
  }, []);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setFields(JSON.stringify(config.fields));
    }
  }, [JSON.stringify(config.fields)]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setComponentRestrictions(
        JSON.stringify(config.componentRestrictions),
      );
    }
  }, [JSON.stringify(config.componentRestrictions)]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setOptions(props.options);
    }
  }, [props.options]);

  return {
    ref: inputRef,
    autocompleteRef,
  };
};

export const getFormattedAddress = (
  place: google.maps.GeocoderResult,
): string => {
  if (!place.address_components) {
    return place.formatted_address;
  }

  const street = place.address_components.find(item =>
    item.types.includes("route"),
  );
  const streetNumber = place.address_components.find(item =>
    item.types.includes("street_number"),
  );

  if (street?.long_name && streetNumber?.long_name) {
    return `${street.long_name} ${streetNumber.long_name}`;
  }

  return place.formatted_address.split(",")[0];
};
