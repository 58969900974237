import * as React from "react";

import {
  BreakpointStateV1,
  BreakpointStateV2,
  OptsState,
} from "./useBreakpoint.types";

export const defaultValueV1: BreakpointStateV1 = {
  mobile: true,
  tablet: false,
  desktop: false,
  desktopLarge: false,
};

export const defaultValueV2: BreakpointStateV2 = {
  mobile: true,
  largeMobile: false,
  smallTablet: false,
  tablet: false,
  smallDesktop: false,
  desktop: false,
  largeDesktop: false,
};

export const defaultOpts: OptsState = {
  isBreakpointResolved: false,
};

export interface ContextProps extends OptsState {
  v1: BreakpointStateV1;
  v2: BreakpointStateV2;
}

export const BreakpointContext = React.createContext<ContextProps>({
  v1: { ...defaultValueV1 },
  v2: { ...defaultValueV2 },
  ...defaultOpts,
});
