import { AnyAction } from "redux";

import {
  OAUTH_LOGIN_BY_ACCESS_TOKEN_ENDPOINT,
  OAUTH_CONNECT_USER_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/reduxThunkActions";
import { OAUTH_PROVIDERS, IOAuthState } from "@typings/oauth";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

import { asyncActionCreator } from "@app/services/AsyncActionCreator";

const actionCreator = new ReduxActionNameCreator("oauth", true);

export const CONNECT_USER: string = actionCreator.make("connect useAuth");
export const LOGIN_BY_ACCESS_TOKEN: string = actionCreator.make(
  "login by access token",
);
export const SET_USER_DATA = actionCreator.make("set useAuth data");

export const loginUserByAccessToken =
  (provider: OAUTH_PROVIDERS, access_token: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: OAUTH_LOGIN_BY_ACCESS_TOKEN_ENDPOINT,
      action: LOGIN_BY_ACCESS_TOKEN,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        provider,
        access_token,
      },
    });

    return dispatch(action);
  };

export const connectUser =
  (provider: OAUTH_PROVIDERS, access_token: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: OAUTH_CONNECT_USER_ENDPOINT,
      action: CONNECT_USER,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        provider,
        access_token,
      },
    });

    return dispatch(action);
  };

export const setUserData = (payload: IOAuthState): AnyAction => ({
  type: SET_USER_DATA,
  payload,
});
