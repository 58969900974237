import { useLocation, matchPath } from "react-router";

const useMatchPath = () => {
  const { pathname } = useLocation();

  return (path: string) =>
    matchPath(pathname, {
      path,
    });
};

export { useMatchPath };
