import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useUnmountEffect } from "framer-motion";

import { Drawer } from "@ui/Molecules";

import { GenericPortal } from "@containers/GenericPortal";

import { useMatchPath } from "@hooks/useMatchPath";

import { DrawerManagerProvider } from "./DrawerManager.context";
import { useManager } from "./DrawerManager.hooks";
import { DrawerManagerComponent } from "./DrawerManager.types";

export const DrawerManager: DrawerManagerComponent = ({
  id,
  url,
  onClose,
  ...props
}) => {
  const matchPath = useMatchPath();
  const history = useHistory();

  const drawer = useManager();

  useEffect(() => {
    !!(url && matchPath(url)) && drawer.showDrawer(id);

    drawer.registerCloseHandler(id, () => {
      url &&
        matchPath(url) &&
        history.replace(history.location.pathname.replace(/\/[^/]+\/?$/, "/"));

      onClose?.();
    });
  }, [onClose, matchPath, history]);

  useUnmountEffect(() => {
    drawer.hideDrawer(id);
  });

  return (
    <GenericPortal.Portal type="drawer">
      {drawer.isDrawerActive(id) && (
        <Drawer onRequestClose={() => drawer.hideDrawer(id)} {...props} />
      )}
    </GenericPortal.Portal>
  );
};

DrawerManager.Provider = DrawerManagerProvider;
DrawerManager.useManager = useManager;
