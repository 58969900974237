import React from "react";
import Prismic from "@prismicio/client";
import ResolvedApi, { Ref } from "@prismicio/client/types/ResolvedApi";
import { Document } from "@prismicio/client/types/documents";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS, ORIGIN } from "@typings/globals";

import { langInPrismicFormat } from "@services/LangInPrismicFormat";

const apiEndpoint = process.env.PRISMIC_API_ENDPOINT || "";
const accessToken = process.env.PRISMIC_ACCESS_TOKEN;
const releaseId = process.env.PRISMIC_RELEASE_ID;

const PrismicProviderContext = React.createContext<ContextValue | null>(null);

type PrismicContentTypeUnion =
  | "apply_page"
  | "batmaid_club_modal"
  | "checkout_summary_features"
  | "city_page"
  | "compare_price_plans"
  | "compare_price_plans_details"
  | "content_page"
  | "dry_footer"
  | "eot_warranty"
  | "footer"
  | "generic_page"
  | "generic_service_page"
  | "global_content_page"
  | "global_landing_page"
  | "help_article"
  | "help_category"
  | "help_page"
  | "landing_page"
  | "necessary_cleaning_materials"
  | "service_page"
  | "testimonial"
  | "top_questions"
  | "whats_included_modal"
  | "checkout_summary"
  | "plan_benefits"
  | "testimonial_service_page";

interface GetPrismicEntryParams {
  contentType: PrismicContentTypeUnion;
  lang: AVAILABLE_LANGS;
  origin?: ORIGIN;
  country?: AVAILABLE_COUNTRIES;
  search?: string;
  customField?: string;
  customFieldValue?: string;
  tags?: string[];
}

export type GetPrismicEntry = (
  input: GetPrismicEntryParams,
) => Promise<Document[] | undefined>;

interface ContextValue {
  getPrismicEntry: GetPrismicEntry;
  isApiReady: boolean;
}

interface Props {
  children: React.ReactNode;
}

export const PrismicProvider = (props: Props): React.ReactElement => {
  const [api, setApi] = React.useState<null | ResolvedApi>(null);
  const [isApiReady, setIsApiReady] = React.useState<boolean>(false);

  const getPrismicEntry: GetPrismicEntry = async ({
    contentType,
    origin,
    lang,
    country,
    search,
    customField,
    customFieldValue,
    tags,
  }): Promise<Document[] | undefined> => {
    const prismicLang = langInPrismicFormat(lang);

    const apiQuery = [
      Prismic.Predicates.at("document.type", contentType),
      ...(origin
        ? [Prismic.Predicates.at(`my.${contentType}.origin`, origin)]
        : []),
      ...(country
        ? [Prismic.Predicates.at(`my.${contentType}.country`, country)]
        : []),
      ...(search ? [Prismic.Predicates.fulltext(`document`, search)] : []),
      ...(tags ? [Prismic.Predicates.at(`document.tags`, tags)] : []),
      ...(customField && customFieldValue
        ? [
            Prismic.Predicates.at(
              `my.${contentType}.${customField}`,
              customFieldValue,
            ),
          ]
        : []),
    ];

    if (api) {
      // If you are only getting the master ref despite having multiple releases in Prismic CMS
      // please check if PRISMIC_ACCESS_TOKEN has access to master+releases.
      // To check it go to https://batmaid.prismic.io/settings/apps/ -> API & Security -> Permanent access tokens
      const getRef = (refs: Ref[]) => {
        const result = refs.filter(item => item.id === releaseId);
        const masterRef = refs.filter(item => item.isMasterRef)[0].ref;

        return result.length ? result[0].ref : masterRef;
      };

      try {
        const response = await api?.query(apiQuery, {
          lang: prismicLang,
          ref: getRef(api.refs),
        });

        return response.results;
      } catch (err) {
        return Promise.resolve(undefined);
      }
    }

    return Promise.resolve(undefined);
  };

  React.useEffect(() => {
    (async () => {
      try {
        const resp = await Prismic.getApi(apiEndpoint, { accessToken });
        setApi(resp);
        setIsApiReady(true);
      } catch (error) {
        setApi(null);
        setIsApiReady(false);
      }
    })();
  }, []);

  return (
    <PrismicProviderContext.Provider value={{ getPrismicEntry, isApiReady }}>
      {props.children}
    </PrismicProviderContext.Provider>
  );
};

export const usePrismic = (): ContextValue => {
  const context = React.useContext(PrismicProviderContext);
  if (context === null) {
    throw new Error("usePrismic must be used within PrismicProviderContext");
  }

  return context;
};
