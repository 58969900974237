import { breakpointsValuesV2 } from "@ui/themes/default";

/**
 * It works perfectly with GenericResponsiveType
 * @returns sort funciton
 */
export function sortByPriority(
  priority: Record<string, number> = {
    default: 0,
    ...breakpointsValuesV2,
  },
) {
  return function (a: keyof typeof priority, b: keyof typeof priority) {
    return priority[a] - priority[b];
  };
}
