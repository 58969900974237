import React from "react";

import { DialogRoot } from "@ui/Molecules/Dialog";

import { GenericPortal } from "@containers/GenericPortal";

import { DialogManagerProps } from "./DialogManager.types";
import { useManager } from "./DialogManager.hooks";

export const DialogManager: React.FC<DialogManagerProps> = ({
  id,
  onRequestClose,
  ...props
}): React.ReactElement => {
  const { isDialogActive } = useManager();

  return (
    <GenericPortal.Portal type="dialog">
      {isDialogActive(id) && (
        <DialogRoot onRequestClose={onRequestClose.bind(null, id)} {...props} />
      )}
    </GenericPortal.Portal>
  );
};
