import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Arrow = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        viewBox="0 0 20 16"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          ...style(props.size, props.height),
          verticalAlign: "initial",
        }}
        className={props.className}
      >
        <path
          d="M11.0003 15l8-7-8-7M19.0003 8h-18"
          stroke={props.color || theme.palette.grey600}
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 24 18"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <path
        fillRule="nonzero"
        fill={props.color || theme.palette.grey600}
        d="M15.22.537c-1.188-1.145-3.219-.334-3.219
        1.285v4.179h-9c-1.657 0-3 1.343-3 3 0 .008.002.016.002.024.013
        1.646 1.349 2.976 2.998 2.976h9v4.178c0 1.619 2.031 2.431 3.219
        1.285l8.781-8.463L15.22.537z"
      />
    </svg>
  );
};
