/**
 * @author michalstolarski
 * @since 2022-10-02
 */

import * as React from "react";

export interface MyIconProps {
  className?: string;
  color?: string;
  fill?: string;
  width?: number;
  height?: number;
  style?: { [k: string]: string };
  elementRef?: React.MutableRefObject<any>;
}

export const style = (props: Partial<MyIconProps>) => ({
  height: props.height || "auto",
  width: props.width || 16,
  color: props.color || "currentColor",
  fill: props.fill,
});
