import { Record } from "immutable";
import { AnyAction } from "redux";

import {
  ALLOW_TEMPORARY_LOGIN,
  DISALLOW_TEMPORARY_LOGIN,
} from "@hooks/useAuth/redux/login/login.actions";
import {
  LoginReducer,
  LoginReducerState,
} from "@hooks/useAuth/redux/login/login.typings";

const initialState = Record<LoginReducerState>({
  allowTemporaryLogin: false,
});

const loginReducer = (
  store: LoginReducer = initialState(),
  action: AnyAction,
): LoginReducer => {
  const { type, payload } = action;

  switch (type) {
    case ALLOW_TEMPORARY_LOGIN: {
      return store.set("allowTemporaryLogin", true);
    }

    case DISALLOW_TEMPORARY_LOGIN: {
      return store.set("allowTemporaryLogin", false);
    }

    default: {
      return store;
    }
  }
};

export { loginReducer };
