export class ReduxActionNameCreator {
  public constructor(
    private readonly group: string,
    private readonly underline: boolean = true,
  ) {}

  public make(input: string): string {
    const preFormat = `@@${this.group}/${input}`;
    const text: string = this.underline
      ? preFormat.replace(new RegExp(/\s/g), "_")
      : preFormat;

    return text.toUpperCase();
  }
}
