import * as React from "react";

import { useModalProvider } from "./useModalManager";
import { ModalContext } from "./modalContext";

interface Props {
  children: React.ReactNode;
}

const ModalProvider = (props: Props): React.ReactElement => {
  const context = useModalProvider();

  return (
    <ModalContext.Provider value={context}>
      {props.children}
    </ModalContext.Provider>
  );
};

export { ModalProvider };
