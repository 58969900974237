import { Size, Variant, Direction } from "./Dialog.types";

export const sizes: Record<Variant, Record<Direction, Record<Size, string>>> = {
  image: {
    horizontal: {
      xs: "272px",
      sm: "372px",
      md: "469px",
      lg: "566px",
    },
    vertical: {
      xs: "343px",
      sm: "552px",
      md: "747px",
      lg: "938px",
    },
  },
  icon: {
    horizontal: {
      xs: "544px",
      sm: "744px",
      md: "938px",
      lg: "1132px",
    },
    vertical: {
      xs: "343px",
      sm: "552px",
      md: "747px",
      lg: "938px",
    },
  },
  blank: {
    horizontal: {
      xs: "544px",
      sm: "744px",
      md: "938px",
      lg: "1132px",
    },
    vertical: {
      xs: "343px",
      sm: "552px",
      md: "747px",
      lg: "938px",
    },
  },
};
