import React from "react";

import { useOverlayManager } from "@hooks/useOverlayManager";

import { ContextProps } from "./DialogManager.types";
import { DialogManagerContext } from "./DialogManager.context";

export const useInitProvider = (): ContextProps => {
  const [isDialogActive, showDialog, hideDialog, getOption, getActiveDialogs] =
    useOverlayManager();

  return {
    isDialogActive,
    showDialog,
    hideDialog,
    getOption,
    getActiveDialogs,
  };
};

export const useManager = (): ContextProps => {
  const context = React.useContext(DialogManagerContext);

  if (!context) {
    throw new Error("useManager must be used within a DialogManagerProvider");
  }

  return context;
};
