/**
 * @author tomekbuszewski
 * @since 2019-5-8
 */

import * as React from "react";
import { useTheme } from "styled-components";

import { style } from "@ui/Assets/Symbolicons/Symbolicons";

interface Icon {
  color?: string;
  className?: string;
  size?: number;
  height?: number;
  withoutCircle?: boolean;
  fillCircleColor?: string;
}

export const FacebookIcon = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    style={style(props.size, props.height)}
    className={props.className}
  >
    <path
      fill="#1877F2"
      d="M25 15.5a9.5 9.5 0 00-19 0c0 4.742 3.474 8.672 8.016 9.385v-6.639h-2.412V15.5h2.412v-2.093c0-2.38 1.418-3.696 3.588-3.696 1.039 0 2.127.185 2.127.185v2.338h-1.198c-1.18 0-1.549.733-1.549 1.485V15.5h2.635l-.421 2.746h-2.214v6.639C21.526 24.172 25 20.242 25 15.5z"
    ></path>
    <path
      fill="#fff"
      d="M19.198 18.246l.421-2.746h-2.635v-1.781c0-.752.368-1.485 1.549-1.485h1.197V9.896s-1.087-.185-2.126-.185c-2.17 0-3.588 1.315-3.588 3.696V15.5h-2.412v2.746h2.412v6.639a9.603 9.603 0 002.968 0v-6.639h2.214z"
    ></path>
  </svg>
);

export const FacebookLogo = (props: Icon): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    style={style(props.size, props.height)}
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.color || "black"}
      d="M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615V8z"
    />
  </svg>
);

export const Facebook = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.5 41.5"
      style={style(props.size, props.height)}
      className={props.className}
    >
      {!props.withoutCircle && (
        <rect
          width="40"
          height="40"
          x="0.75"
          y="0.75"
          rx="19.03"
          fill={props.fillCircleColor || "none"}
          stroke={props.color || theme.palette.grey600}
          strokeWidth="1.5"
        ></rect>
      )}
      <path
        d="M17.7 32.84h4.87V20.65H26l.36-4.08h-3.79v-2.33c0-1 .19-1.34 1.13-1.34h2.63V8.66H23c-3.62 0-5.26 1.6-5.26 4.65v3.26h-2.57v4.13h2.53v12.14z"
        fill={props.color || theme.palette.grey600}
      ></path>
    </svg>
  );
};

export const Twitter = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.5 41.5"
      style={style(props.size, props.height)}
      className={props.className}
    >
      {!props.withoutCircle && (
        <rect
          width="40"
          height="40"
          x="0.75"
          y="0.75"
          rx="19.03"
          fill={props.fillCircleColor || "none"}
          stroke={props.color || theme.palette.grey600}
          strokeWidth="1.5"
        ></rect>
      )}
      <path
        d="M10.09 27.45a12.44 12.44 0 0019.13-11 8.72 8.72 0 002.19-2.26 8.87 8.87 0 01-2.52.69 4.38 4.38 0 001.93-2.42A8.9 8.9 0 0128 13.47a4.38 4.38 0 00-7.45 4 12.38 12.38 0 01-9-4.57 4.37 4.37 0 001.35 5.84 4.28 4.28 0 01-2-.55 4.36 4.36 0 003.51 4.34 4.38 4.38 0 01-2 .08 4.36 4.36 0 004.09 3 8.79 8.79 0 01-6.41 1.84z"
        fill={props.color || theme.palette.grey600}
      ></path>
    </svg>
  );
};

export const X = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.5 41.5"
      style={style(props.size, props.height)}
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C31.046 0 40 8.954 40 20C40 30.9309 31.2316 39.8132 20.3445 39.9971L20 40C8.954 40 0 31.046 0 20C0 8.954 8.954 0 20 0ZM20 1.5C9.78243 1.5 1.5 9.78243 1.5 20C1.5 30.1053 9.6014 38.3177 19.6516 38.4971L19.9873 38.5001L20.3192 38.4973C30.4015 38.327 38.5 30.0987 38.5 20C38.5 9.78243 30.2176 1.5 20 1.5ZM18.1164 21.8325L11.3718 12H16.8981L21.1953 18.2639L26.6021 12H28.0603L21.8422 19.2082L28.8887 29.48H23.3624L18.7624 22.7741L12.9773 29.48H11.5147L18.1164 21.8325ZM16.4138 12.92H13.1184L23.8466 28.56H27.1421L16.4138 12.92Z"
        fill={props.color || theme.palette.grey600}
      />
    </svg>
  );
};

export const Instagram = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.5 41.5"
      style={style(props.size, props.height)}
      className={props.className}
    >
      <path
        d="M20.75 40.75a20 20 0 01-20-20 20 20 0 0120-20 20 20 0 0120 20 20 20 0 01-20 20z"
        fill="none"
        stroke={props.color || theme.palette.grey600}
        strokeWidth="1.5"
      ></path>
      <path
        d="M20.75 11.76c2.93 0 3.27 0 4.43.06a6.13 6.13 0 012 .38 3.63 3.63 0 012.08 2.08 6.13 6.13 0 01.38 2c.05 1.16.06 1.5.06 4.43s0 3.27-.06 4.43a6.13 6.13 0 01-.38 2 3.63 3.63 0 01-2.08 2.08 6.13 6.13 0 01-2 .38c-1.16.05-1.5.06-4.43.06s-3.27 0-4.43-.06a6.13 6.13 0 01-2-.38 3.63 3.63 0 01-2.08-2.08 6.13 6.13 0 01-.38-2c-.05-1.16-.06-1.5-.06-4.43s0-3.27.06-4.43a6.13 6.13 0 01.38-2 3.63 3.63 0 012.08-2.08 6.13 6.13 0 012-.38c1.16-.05 1.5-.06 4.43-.06m0-2c-3 0-3.35 0-4.52.07a7.93 7.93 0 00-2.66.51 5.52 5.52 0 00-3.21 3.21 7.93 7.93 0 00-.51 2.66c0 1.17-.07 1.54-.07 4.52s0 3.35.07 4.52a7.93 7.93 0 00.51 2.66 5.52 5.52 0 003.21 3.21 7.93 7.93 0 002.66.51c1.17.05 1.54.07 4.52.07s3.35 0 4.52-.07a7.93 7.93 0 002.66-.51 5.52 5.52 0 003.21-3.21 7.93 7.93 0 00.51-2.66c.05-1.17.07-1.54.07-4.52s0-3.35-.07-4.52a7.93 7.93 0 00-.51-2.66 5.52 5.52 0 00-3.21-3.21 7.93 7.93 0 00-2.66-.51c-1.17 0-1.54-.07-4.52-.07"
        fill={props.color || theme.palette.grey600}
      ></path>
      <path
        d="M20.75 15.12c-4.43 0-7.66 5.11-4.12 9.76 4.64 3.53 9.75.3 9.75-4.13a5.63 5.63 0 00-5.63-5.63m0 9.29c-2.88 0-5-3.32-2.68-6.34 3-2.29 6.34-.19 6.34 2.68a3.66 3.66 0 01-3.66 3.66"
        fill={props.color || theme.palette.grey600}
      ></path>
    </svg>
  );
};

export const YouTube = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.5 41.5"
      style={style(props.size, props.height)}
      className={props.className}
    >
      <path
        d="M20.75 40.75a20 20 0 01-20-20 20 20 0 0120-20 20 20 0 0120 20 20 20 0 01-20 20z"
        fill="none"
        stroke={props.color || theme.palette.grey600}
        strokeWidth="1.5"
      ></path>
      <path
        d="M36.09 13a4 4 0 00-2.83-2.84c-2.5-.68-12.51-.68-12.51-.68s-10 0-12.51.68A4 4 0 005.41 13a42.22 42.22 0 00-.67 7.76 42.22 42.22 0 00.67 7.76 4 4 0 002.83 2.84c2.5.68 12.51.68 12.51.68s10 0 12.51-.68a4 4 0 002.83-2.84 42.22 42.22 0 00.67-7.76 42.22 42.22 0 00-.67-7.76zM17.48 25.51V16l8.36 4.76z"
        fill={props.color || theme.palette.grey600}
      ></path>
    </svg>
  );
};

export const GoogleIcon = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    style={style(props.size, props.height)}
    className={props.className}
  >
    <path
      fill="#4285F4"
      fillRule="evenodd"
      d="M24.62 15.716c0-.674-.06-1.321-.173-1.943H15.5v3.675h5.113a4.37 4.37 0 01-1.896 2.867v2.384h3.07c1.797-1.654 2.833-4.09 2.833-6.983z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#34A853"
      fillRule="evenodd"
      d="M15.5 25c2.565 0 4.715-.85 6.287-2.302l-3.07-2.383c-.85.57-1.939.906-3.217.906-2.475 0-4.569-1.67-5.316-3.916H7.01v2.461A9.496 9.496 0 0015.5 25z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FBBC05"
      fillRule="evenodd"
      d="M10.184 17.305a5.71 5.71 0 01-.298-1.805c0-.626.108-1.235.298-1.805v-2.462H7.01A9.496 9.496 0 006 15.5c0 1.533.367 2.984 1.01 4.266l3.174-2.461z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#EA4335"
      fillRule="evenodd"
      d="M15.5 9.778c1.395 0 2.647.48 3.631 1.421l2.725-2.725C20.211 6.941 18.06 6 15.5 6a9.496 9.496 0 00-8.49 5.234l3.174 2.461c.747-2.245 2.841-3.917 5.316-3.917z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const Google = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
      style={style(props.size, props.height)}
    >
      <path
        fill={props.color || theme.palette.grey600}
        d="M896 786h725q12 67 12 128 0 217-91 387.5T1282.5 1568 896 1664q-157 0-299-60.5T352 1440t-163.5-245T128 896t60.5-299T352 352t245-163.5T896 128q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65T484 652.5 420 896t64 243.5T657.5 1316t238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78H896V786z"
      />
    </svg>
  );
};

export const AppleIcon = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    style={style(props.size, props.height)}
    className={props.className}
  >
    <path
      fill="#000"
      d="M15.723 10.385c.832 0 1.875-.58 2.496-1.353.562-.7.972-1.68.972-2.658 0-.132-.011-.265-.035-.374-.925.036-2.039.64-2.707 1.45-.527.615-1.008 1.582-1.008 2.572 0 .145.024.29.036.338.058.013.152.025.246.025zM12.793 25c1.137 0 1.64-.785 3.059-.785 1.441 0 1.757.76 3.023.76 1.242 0 2.074-1.183 2.86-2.343C22.612 21.305 22.976 20 23 19.94c-.082-.024-2.46-1.027-2.46-3.841 0-2.44 1.874-3.54 1.98-3.624-1.243-1.836-3.13-1.884-3.645-1.884-1.395 0-2.531.87-3.246.87-.774 0-1.793-.822-3-.822-2.297 0-4.629 1.957-4.629 5.653 0 2.295.867 4.723 1.934 6.293.914 1.329 1.71 2.416 2.859 2.416z"
    ></path>
  </svg>
);

export const Apple = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 860 1180"
      xmlns="http://www.w3.org/2000/svg"
      style={style(props.size, props.height)}
    >
      <path
        fill={props.color || theme.palette.grey600}
        d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"
      ></path>
    </svg>
  );
};

export const LinkedIn = (props: Icon): React.ReactElement => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.5 41.5"
      style={style(props.size, props.height)}
      className={props.className}
    >
      <rect
        width="40"
        height="40"
        x="0.75"
        y="0.75"
        rx="19.03"
        fill="none"
        stroke={props.color || theme.palette.grey600}
        strokeWidth="1.5"
      ></rect>
      <path
        d="M11.68 16.29H15.82V29.659999999999997H11.68z"
        fill={props.color || theme.palette.grey600}
      ></path>
      <path
        d="M13.73 14.54a2.47 2.47 0 10-2.45-2.47 2.46 2.46 0 002.45 2.47zM22.44 22.64c0-1.88.86-3 2.52-3s2.25 1.08 2.25 3v7h4.12V21.2c0-3.58-2-5.32-4.86-5.32a4.66 4.66 0 00-4 2.21v-1.8h-4v13.37h4z"
        fill={props.color || theme.palette.grey600}
      ></path>
    </svg>
  );
};
