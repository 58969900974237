import { Telemetry } from "@batmaid/telemetry";

import { IS_DEV } from "@config/consts";

import { isHeadlessMode } from "@services/HeadlessMode";
import { langFromPathname } from "@services/LangFromPathname";
import { getApiUrl } from "@services/ApiUrl/ApiUrl";
import { iOS } from "@services/iOS";

const endpointUrl = IS_DEV ? getApiUrl() + "/telemetry" : null;
const getPlatform = () => {
  const isApp = isHeadlessMode();

  if (isApp && iOS()) {
    return "ios";
  }

  if (isApp && !iOS()) {
    return "android";
  }

  return "web";
};

const BatmaidTelemetry = new Telemetry({
  language: langFromPathname(),
  endpointUrl,
  platform: getPlatform(),
  context: {
    version: process.env.VERSION,
    commitHash: process.env.COMMIT_HASH,
  },
});

export { BatmaidTelemetry };
