import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { AppConfig } from "@typings/appConfig";
import {
  AVAILABLE_COUNTRIES,
  AVAILABLE_LANGS,
  SERVICE_TYPE,
} from "@typings/globals";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.CH],
  availableLanguages: [
    AVAILABLE_LANGS.EN,
    AVAILABLE_LANGS.FR,
    AVAILABLE_LANGS.DE,
    AVAILABLE_LANGS.IT,
  ],
  booking: {
    minBookingTimePerFrequency: {
      ONCE: 3,
    },
    commitments: {
      enabled: true,
    },
    migrosLinkEnabled: true,
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
        "periodicity_every_4_weeks",
        "periodicity_more_often",
      ],
      rows: [
        [false, true, true, false, false],
        [true, true, true, true, true],
        [true, true, true, true, true],
        [false, true, true, true, true],
        [true, true, true, true, true],
      ],
    },
  },
  b2b: {
    migrosLinkEnabled: true,
    contactDetails: {
      phone: "+41 (0)21 624 54 15",
      email: "client@batmaid.com",
    },
  },
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,
  helpCenter: {
    reportDamageUrl: {
      [AVAILABLE_LANGS.EN]:
        "https://docs.google.com/forms/d/e/1FAIpQLScu-BadoPCNvKHMJHv9VVMfZkc2oFEMSIX-i6PD6t9LymKm0g/viewform",
      [AVAILABLE_LANGS.FR]:
        "https://docs.google.com/forms/d/e/1FAIpQLSePmnZsCk5d3AntPHFTTaFGLf4XTb3g2-ynU7AoNVmXwchVRg/viewform",
      [AVAILABLE_LANGS.DE]:
        "https://docs.google.com/forms/d/e/1FAIpQLSfDokoipLucQ9955gG0dvtYdqR1awX8XB3cPngTB3syC3sH6Q/viewform",
      [AVAILABLE_LANGS.IT]:
        "https://docs.google.com/forms/d/e/1FAIpQLSerflH1bO6dKmnLVKnqG3BRUpr-Q890f2c-vJbYbBvV7bA25g/viewform",
    },
  },
  availableServices: [
    SERVICE_TYPE.HOME_CLEANING,
    SERVICE_TYPE.END_OF_TENANCY,
    SERVICE_TYPE.B2B,
  ],
  cleaningProductsMarketplaceUrl: "https://batmaid.myshopify.com/",
};

export { config as ch };
