import { isLocalStorageAvailable } from "@services/IsLocalStorageAvailable";

interface LocalStorageFacadeReturnType {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

const localStorageFacade = (): LocalStorageFacadeReturnType => {
  const available = isLocalStorageAvailable();
  const emptyFn = () => null;

  return available
    ? {
        getItem: key => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: key => localStorage.removeItem(key),
      }
    : {
        getItem: emptyFn,
        setItem: emptyFn,
        removeItem: emptyFn,
      };
};

export { localStorageFacade };
