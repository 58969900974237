/**
 * @author tomekbuszewski
 * @since 2019-3-22
 */

import * as React from "react";
import styled from "styled-components";

import { fadeIn } from "@ui/animations";
import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
}

const FadeInContainer = styled.div<Props>`
  position: relative;
  z-index: 1;
  opacity: 0;
  animation: ${fadeIn} ${props => props.theme.animations.durationLong} forwards;
`;

export { FadeInContainer };
