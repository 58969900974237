import * as React from "react";

import { style, Icon } from "./Symbolicons";

export const Close = (props: Icon): React.ReactElement => {
  return props.variant === "line" ? (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={props.className}
    >
      <defs>
        <path
          d="M16,7.3333333 L16.1020454,7.34114379 C16.2372433,7.36197175 16.3672647,7.42445564 16.4714045,7.52859545 C16.7117272,7.76891809 16.7302135,8.14707213 16.5268636,8.40859961 L16.4714045,8.4714045 L12.9433333,12.0003333 L16.4714045,15.5285955 C16.7317541,15.788945 16.7317541,16.211055 16.4714045,16.4714045 C16.3645945,16.5782146 16.2305585,16.6412052 16.0916357,16.6603762 L15.9868765,16.6665383 C15.8469066,16.6637996 15.7076348,16.6172414 15.5914004,16.5268636 L15.5285955,16.4714045 L12.0003333,12.9433333 L9.4143333,15.5283333 L8.4714045,16.4714045 C8.36726469,16.5755444 8.2372432,16.6380282 8.10204538,16.6588562 L7.99999997,16.6666667 C7.8293851,16.6666667 7.65877022,16.6015793 7.52859545,16.4714045 C7.28827281,16.2310819 7.26978645,15.8529279 7.47313638,15.5914004 L7.52859545,15.5285955 L11.0573333,12.0003333 L7.52859545,8.4714045 C7.26824592,8.21105497 7.26824592,7.78894498 7.52859545,7.52859545 C7.76891809,7.28827281 8.14707213,7.26978645 8.40859961,7.47313638 L8.4714045,7.52859545 L12.0003333,11.0573333 L15.5285955,7.52859545 C15.6587703,7.39842069 15.8293851,7.3333333 16,7.3333333 Z"
          id="path-1-close-line"
        ></path>
      </defs>
      <g
        id="icon-24x24px[16x16px]---line&amp;solid"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atom/icon/controls/cancel/line">
          <mask id="mask-2-close-line" fill="white">
            <use xlinkHref="#path-1-close-line"></use>
          </mask>
          <use
            id="Combined-Shape"
            fill={props.color || "currentColor"}
            fillRule="nonzero"
            xlinkHref="#path-1-close-line"
          ></use>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={props.className}
    >
      <defs>
        <path
          d="M13.8853333,12 L16.9426667,8.94266665 C17.4633334,8.42199998 17.4633334,7.57799997 16.9426667,7.0573333 C16.4226667,6.53666663 15.5773334,6.53666663 15.0573334,7.0573333 L12,10.1146667 L8.94266665,7.0573333 C8.42266664,6.53666663 7.57733331,6.53666663 7.0573333,7.0573333 C6.53666663,7.57799997 6.53666663,8.42199998 7.0573333,8.94266665 L10.1146667,12 L7.0573333,15.0573334 C6.53666663,15.578 6.53666663,16.422 7.0573333,16.9426667 C7.3173333,17.2033334 7.65866664,17.3333334 7.99999998,17.3333334 C8.34133331,17.3333334 8.68266665,17.2033334 8.94266665,16.9426667 L12,13.8853333 L15.0573334,16.9426667 C15.3173334,17.2033334 15.6586667,17.3333334 16,17.3333334 C16.3413334,17.3333334 16.6826667,17.2033334 16.9426667,16.9426667 C17.4633334,16.422 17.4633334,15.578 16.9426667,15.0573334 L13.8853333,12 Z"
          id="path-1-close-solid"
        ></path>
      </defs>
      <g
        id="icon-24x24px[16x16px]---line&amp;solid"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atom/icon/controls/cancel/solid">
          <mask id="mask-2-close-solid" fill="white">
            <use xlinkHref="#path-1-close-solid"></use>
          </mask>
          <use
            id="Atom/icon/cancel"
            fill={props.color || "currentColor"}
            fillRule="nonzero"
            xlinkHref="#path-1-close-solid"
          ></use>
        </g>
      </g>
    </svg>
  );
};
