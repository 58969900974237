export const extractQueries = (
  url: string,
): { [k: string]: string } | undefined => {
  const [_, queries] = url.split("?");

  return queries
    ? queries
        .split("&")
        .reduce((acc: { [k: string]: string }, item: string) => {
          const [key, value] = item.split("=");
          acc[key] = value;
          return acc;
        }, {})
    : undefined;
};

export const getQueryValue = (
  url: string,
  paramKey: string,
): string | undefined => {
  const queries = extractQueries(url);
  return queries ? queries[paramKey] : undefined;
};

export const encodeSpaceForQuery = (str: string): string =>
  str.replace(/ /g, "+");
