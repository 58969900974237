import * as React from "react";
import { useTranslation } from "react-i18next";

import { IS_BROWSER } from "@config/consts";
import { TRANSLATIONS_ENDPOINT } from "@config/endpoints";

import { TranslationDomain, TranslationKey } from "@typings/translations";

import { getApiUrl } from "@services/ApiUrl/ApiUrl";
import { fetchFacade } from "@services/FetchFacade";
import { langFromPathname } from "@services/LangFromPathname";
import { isTranslationKey } from "@services/IsTranslationKey";
import { getPrefixWithDomain } from "@services/GetPrefixWithDomain";

/**
 * List of fetched domains expressed as unique keys, JSON-like
 */
let fetchedDomainKeys: string[] = [];

const useTranslate = (
  domain: TranslationDomain = "generic",
): TranslateFunction => {
  const { t, i18n } = useTranslation();

  const prefixedDomain = getPrefixWithDomain(domain);

  const fetchTranslations = async (newDomain?: string) => {
    if (!IS_BROWSER) {
      return;
    }

    const language = langFromPathname();

    const query = {
      domain: newDomain || prefixedDomain,
      language,
    };

    const stringifiedQuery = JSON.stringify(query);
    const prefix = getApiUrl().replace("/en/", `/${query.language}/`);

    if (!fetchedDomainKeys.includes(stringifiedQuery)) {
      fetchedDomainKeys.push(stringifiedQuery);

      try {
        const fetchedTranslations = await fetchFacade(
          `${prefix}${TRANSLATIONS_ENDPOINT}/${query.domain}`,
        );

        i18n.addResourceBundle(
          language,
          query.domain,
          fetchedTranslations.data,
        );
        i18n.changeLanguage(language);
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Translate function
   * @param input - translation key
   * @param substitutions - substitutions for the translation for example { name: "John" }
   * @param notReturnKey - if `true`, will return empty string not the key itself when translation is not found
   * @returns translated text or key itself or empty string
   */
  const translate: TranslateFunction = React.useCallback(
    (
      input: TranslationKey,
      substitutions?: { [k: string]: string | number },
      notReturnKey?: boolean,
    ) => {
      if (!isTranslationKey(input)) {
        return input;
      }

      const keys: string[] = input.split(".");

      let currentDomain: string = prefixedDomain;
      let key: string = input;

      if (keys.length === 2) {
        [currentDomain, key] = keys;
      }

      if (keys.length === 1) {
        key = input;
      }

      const currentPrefixedDomain = getPrefixWithDomain(currentDomain);

      if (prefixedDomain !== currentPrefixedDomain) {
        fetchTranslations(currentPrefixedDomain);
      }

      // @ts-ignore
      if (IS_BROWSER && window["debug_translations"]) {
        return `${currentPrefixedDomain}.${key}`.toLowerCase();
      }

      const translatedText = t(
        `${currentPrefixedDomain}.${key}`.toLowerCase(),
        substitutions,
      );

      if (notReturnKey && translatedText === key) {
        return "";
      }

      return translatedText;
    },
    [prefixedDomain],
  );

  React.useEffect(() => {
    fetchTranslations();
  }, []);

  return translate;
};

const useTranslateManager = () => {
  return {
    clearTranslationsCache: () => {
      fetchedDomainKeys = [];
    },
  };
};

export type TranslateFunction = (
  input: TranslationKey,
  substitutions?: { [k: string]: string | number },
  notReturnKey?: boolean,
) => string;

export { useTranslate, useTranslateManager };
