/**
 * @author jakubmaslon
 * @since 2020-09-15
 */

import * as React from "react";
import debounce from "lodash.debounce";

import {
  IS_BROWSER,
  MOBILE_HEIGHT_BORDER,
  DEFAULT_DEBOUNCE,
} from "@config/consts";

export enum WindowSizeState {
  DEFAULT = "default",
  DECREASED = "heightDecreased",
  INCREASED = "heightIncreased",
}

interface SizeHandlerProps {
  currentValue: number;
  newValue: number;
  setNewValue: (value: number) => void;
  setNewState: (value: WindowSizeState) => void;
  diffCompareNumber?: number;
}

function sizeHandler({
  currentValue,
  newValue,
  setNewState,
  setNewValue,
  diffCompareNumber,
}: SizeHandlerProps): void {
  setNewValue(newValue);

  if (!currentValue) return;

  if (
    typeof diffCompareNumber === "number" &&
    Math.abs(newValue - currentValue) <= diffCompareNumber
  ) {
    setNewState(WindowSizeState.DEFAULT);

    return;
  }

  if (newValue > currentValue) {
    setNewState(WindowSizeState.INCREASED);
  } else if (newValue < currentValue) {
    setNewState(WindowSizeState.DECREASED);
  } else {
    setNewState(WindowSizeState.DEFAULT);
  }
}

export const useResize = () => {
  const [windowHeight, setWindowHeight] = React.useState<number>(
    IS_BROWSER ? window.innerHeight : 0,
  );
  const [windowHeightState, setWindowHeightState] = React.useState(
    WindowSizeState.DEFAULT,
  );
  const [windowWidth, setWindowWidth] = React.useState<number>(
    IS_BROWSER ? window.innerWidth : 0,
  );
  const [windowWidthState, setWindowWidthState] = React.useState(
    WindowSizeState.DEFAULT,
  );

  const onResize = debounce(() => {
    // HEIGHT
    sizeHandler({
      currentValue: windowHeight,
      newValue: window.innerHeight,
      setNewValue: setWindowHeight,
      setNewState: setWindowHeightState,
      diffCompareNumber: MOBILE_HEIGHT_BORDER,
    });

    // WIDTH
    sizeHandler({
      currentValue: windowWidth,
      newValue: window.innerWidth,
      setNewValue: setWindowWidth,
      setNewState: setWindowWidthState,
    });
  }, DEFAULT_DEBOUNCE);

  React.useEffect(() => {
    if (IS_BROWSER) {
      window.addEventListener("resize", onResize);
    }
    return () => {
      if (IS_BROWSER) {
        window.removeEventListener("resize", onResize);
        onResize.cancel();
      }
    };
  }, []);

  return { windowHeightState, windowHeight, windowWidthState, windowWidth };
};
