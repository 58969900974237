import * as React from "react";

import * as HTTP_CODE from "@config/httpStatuses";

import { TranslationDomain } from "@typings/translations";
import { MODALS } from "@typings/modals";

import { useAuthDispatch } from "@hooks/useAuth/redux";
import { useModalManager } from "@hooks/modal";

import { LoginModalComponent } from "./components/Login";
import { fetchUserData, logoutUser } from "./redux/user/user.actions";

interface Props {
  children: React.ReactNode;
  translationScope?: TranslationDomain;
  application?: "client" | "agent" | null;
}

const AuthProvider = (props: Props): React.ReactElement => {
  const { isModalActive } = useModalManager();
  const dispatch = useAuthDispatch();

  React.useEffect(() => {
    (async () => {
      const { onError } = await dispatch(fetchUserData());

      onError(
        () => {
          dispatch(logoutUser());
        },
        { matchCode: HTTP_CODE.UNAUTHORIZED },
      );
    })();
  }, [dispatch]);

  return (
    <React.Fragment>
      {isModalActive(MODALS.NAVBAR_LOGIN) && (
        <LoginModalComponent
          application={props.application}
          translationScope={props.translationScope}
        />
      )}
      {props.children}
    </React.Fragment>
  );
};

export { AuthProvider };
