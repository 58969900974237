import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Check = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        viewBox="0 0 18 14"
        style={{ ...style(props.size, props.height), ...props.style }}
        className={props.className}
      >
        <path
          d="M1 9l6 4L17 1"
          stroke={props.color || theme.palette.grey600}
          strokeWidth={2}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 20 17"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <path
        d="M19.281.474a1.973 1.973 0 0 0-2.817.262L7.615 11.571 3.11 8.507a1.974 1.974 0 0 0-2.773.566 2.066 2.066 0 0 0 .555 2.83l7.495 5.099L19.538 3.349a2.07 2.07 0 0 0-.257-2.875z"
        fill={props.color || theme.palette.grey600}
        fillRule="nonzero"
      />
    </svg>
  );
};
