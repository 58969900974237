import { useContext, useRef } from "react";
import { Map } from "immutable";

import { useOverlayManager } from "@hooks/useOverlayManager";

import { DrawerContext } from "./DrawerManager.context";
import { ContextProps } from "./DrawerManager.types";

export const useManager = (): ContextProps => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error("useDrawerManager must be used within a DrawerProvider");
  }

  return context;
};

export const useInitProvider = (): ContextProps => {
  const [
    isDrawerActive,
    showDrawer,
    hideDrawerBase,
    getOption,
    getActiveDrawers,
  ] = useOverlayManager();
  const closeHandlers = useRef<Map<string, () => void>>(Map());

  const registerCloseHandler = (id: string, onClose: () => void) => {
    closeHandlers.current = closeHandlers.current.set(id, onClose);
  };

  const hideDrawer: ContextProps["hideDrawer"] = id => {
    hideDrawerBase(id);
    const closeHandler = closeHandlers.current.get(id);
    closeHandlers.current = closeHandlers.current.delete(id);
    closeHandler?.();
  };

  return {
    isDrawerActive,
    showDrawer,
    hideDrawer,
    getOption,
    registerCloseHandler,
    getActiveDrawers,
  };
};
