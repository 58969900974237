import { PAYMENT_METHOD_STATUS } from "./globals";

export enum USER_TYPES {
  ADMIN = "admin",
  AGENT = "agent",
  CLIENT = "client",
}

export enum USER_SERVICE_TYPE {
  B2C = "B2C",
  B2B = "B2B",
  UNKNOWN = "UNKNOWN",
  AMBIGUOUS = "AMBIGUOUS",
}

export interface IUser {
  email: string;
  exitUserUrl: string;
  firstName: string;
  impersonator: null | IUserImpersonator;
  lastName: string;
  logoutUrl: string;
  roles: USER_ROLES[] | [];
  userName: string;
  preferredZipCode: string;
  feistelHash: number | null;
  uuid: string;
  hexHumanId: string;
  identifier: string;
  companyName: string;
  defaultCleaningType: DEFAULT_CLEANING_TYPE | null;
  userType: USER_TYPES | null;
  urssafProfile?: UrssafProfile | null;
  serviceType: USER_SERVICE_TYPE;
  paymentMethodStatus: PAYMENT_METHOD_STATUS | null;
  hasOverduePayments: boolean;
  isInvoiced: boolean;
}

export enum URSSAF_REGISTRATION_STATUS {
  NOT_REGISTERED = "not registered",
  PENDING = "pending",
  REGISTERED = "registered",
}

export interface UrssafProfile {
  registeredAt: Date | null;
  status: URSSAF_REGISTRATION_STATUS;
}

export enum USER_ROLES {
  ROLE_CLIENT = "ROLE_CLIENT",
  ROLE_USER = "ROLE_USER",
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  ROLE_AGENT = "ROLE_AGENT",
  ROLE_PREVIOUS_ADMIN = "ROLE_PREVIOUS_ADMIN",
}

export enum DEFAULT_CLEANING_TYPE {
  B2C = "B2C",
  B2B = "B2B",
  EOT = "EOT",
}

export interface IUserImpersonator {
  userName: string;
}

export enum USER_LOGIN_STATE {
  LOGGED_OUT,
  LOGGED_IN,
  UNKNOWN,
}

export interface ILoginUser {
  username: string;
  password: string;
  rememberMe?: boolean;
  userType?: USER_TYPES | null;
}
