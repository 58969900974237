import React from "react";

import { IS_BROWSER } from "@config/consts";

import { GenericPortal } from "@containers/GenericPortal";

import { ProviderProps } from "./DialogManager.types";
import { DialogManagerContext } from "./DialogManager.context";
import { useInitProvider } from "./DialogManager.hooks";

export const DialogManagerProvider: React.FC<ProviderProps> = props => {
  const value = useInitProvider();
  const { renderPortal } = GenericPortal.usePortalContainer("dialog");

  React.useEffect(() => {
    if (!IS_BROWSER) return;

    renderPortal();
  }, []);

  return (
    <DialogManagerContext.Provider value={value}>
      {props.children}
    </DialogManagerContext.Provider>
  );
};
