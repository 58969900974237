/**
 * @author tomekbuszewski
 * @since 2019-7-2
 */

import * as React from "react";

import { CLEAR_TEST } from "@config/consts";

type TestProps = {
  testId?: string;
};

type Props = TestProps & {
  children: React.ReactElement;
};

/**
 * This component **modifies a children's props** by adding a `data-testid` prop. To use the `TestWrapper` component with custom components they **need to receive and use** the `data-testid` prop in this children component.
 */
const TestWrapper = (props: Props) => {
  if (CLEAR_TEST || typeof props.testId === "undefined") {
    return props.children;
  }

  if (React.Children.count(props.children) > 1) {
    throw new Error("Only one child is allowed within TestWrapper.");
  }

  return React.Children.only(
    React.cloneElement(props.children, { "data-testid": props.testId }),
  );
};

export { TestWrapper, TestProps };

TestWrapper.displayName = "TestWrapper";
