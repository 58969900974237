const isVersionTag = (input: string): boolean => {
  // courtesy of https://gist.github.com/jhorsman/62eeea161a13b80e39f5249281e17c39
  const pattern = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/;
  const tagRegex = new RegExp(pattern);
  const result = Boolean(tagRegex.exec(input));

  return result;
};

export { isVersionTag };
